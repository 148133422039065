import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

// Soft UI Dashboard React examples
import TimelineList from "components/Timeline/TimelineList";
import TimelineItem from "components/Timeline/TimelineItem";
import SuiTagInput from "components/SuiTagInput/SuiTagInput";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import axios from "axios";
import { makeHttpDownloadRequest } from "utils/httpServices";

export default function ViewAppointment() {
  const { id } = useParams();
  const [data, setData] = useState({});

  const [status, setStatus] = useState("not_visited");

  const tags = ["Teeth Whitening", "Filling", "Dental Bridge"];

  const fileUrl = "https://example.com/path-to-your-file";

  const getData = async () => {
    try {
      const resp = await makeHttpRequest("get", `viewAppointment/${id}`);
      console.log(resp?.data[0]);
      setStatus(resp?.data[0]?.is_visited);
      setData(resp?.data[0]);
    } catch (error) {
      // toast.error('Network error.');
    }
  };

  const formatDateTime = (utcDateString) => {
    if (!utcDateString) {
      return { formattedDate: "", formattedTime: "" };
    }

    const utcDate = new Date(utcDateString);

    // Add 5 hours and 30 minutes
    utcDate.setUTCHours(utcDate.getUTCHours());
    utcDate.setUTCMinutes(utcDate.getUTCMinutes());

    const day = utcDate.getUTCDate().toString().padStart(2, "0");
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = utcDate.getUTCFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = utcDate.toLocaleTimeString('en-US', { hour12: false });
    // console.log(formattedDate);

    return { formattedDate, formattedTime };
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const handleDownload = async () => {
    if (data) {
      if (!data?.receipt_uploaded_by_user) {
        toast.error("It does not contain any receipt.");
        return;
      }
      const url = data?.receipt_uploaded_by_user;

      try {
        const response = await makeHttpDownloadRequest(url);

        // console.log(response)
        const blob = new Blob([response]);

        // Create a virtual anchor element
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "User_receipt.png"; // Set the desired filename

        // Trigger a click on the anchor element
        document.body.appendChild(link);
        link.click();

        // Clean up the virtual anchor element
        document.body.removeChild(link);
      } catch (error) {
        // console.error('Error downloading file:', error);
        // toast.error('Network error.')
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <SoftBox
                mb={3}
                component="form"
                role="form"
                className="appointment_form"
              >
                <SoftTypography variant="h6">Patient Details</SoftTypography>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          First Name <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        value={data?.user_details?.first_name}
                        placeholder="First Name"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Last Name <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        value={data?.user_details?.last_name}
                        placeholder="Last Name"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Mobile Number <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="tel"
                        value={data?.user_details?.phone_number}
                        placeholder="Number"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Email ID <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="email"
                        value={data?.user_details?.email}
                        placeholder="email"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox
                component="form"
                role="form"
                className="appointment_form"
              >
                <SoftTypography variant="h6">
                  Appointment Details
                </SoftTypography>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12} lg={4} xl={4}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Date of Appointment{" "}
                          <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="date"
                        value={formatDateTime(data?.start_time).formattedDate}
                        disabled
                      />
                      {/* {console.log("data?.start_time", formatDateTime(data?.start_time).formattedDate)} */}
                    </SoftBox>
                  </Grid>
                  <Grid item md={4} xs={12} lg={4} xl={4}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          From <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="time"
                        value={formatDateTime(data?.start_time).formattedTime}
                        placeholder="Start Time"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={4} xs={12} lg={4} xl={4}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          To <span className="text-danger">*</span>
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="time"
                        value={formatDateTime(data?.end_time).formattedTime}
                        placeholder="End Time"
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Consulting Doctor
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        value={data?.dentist_details?.full_name}
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12} lg={6} xl={6}>
                    <SoftBox className="user_treatment">
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Treatment
                        </SoftTypography>
                      </SoftBox>
                      <SuiTagInput
                        tags={data?.questionires?.service?.map((val) => val)}
                        disabled
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item md={4} xs={12} lg={4} xl={4}>
                    <SoftBox
                      style={{ position: "relative" }}
                      className="user_treatment"
                    >
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Receipt Uploaded by User
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <iframe
                          title="File Preview"
                          src={
                            data?.receipt_uploaded_by_user
                              ? `${process.env.REACT_APP_IMAGE_URL}receipt/${data?.receipt_uploaded_by_user}`
                              : process.env.REACT_APP_DEFAULT_IMAGE_URL
                          }
                          width="100%"
                          height="200px"
                        />
                        {/* <SoftButton className="download-btn" variant="small" color="secondary" >Download</SoftButton>
                         */}
                        <SoftButton
                          className="download-btn"
                          variant="small"
                          color="secondary"
                          onClick={handleDownload}
                          // disabled={fileUrl === ''}
                        >
                          Download
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <SoftBox display="flex" justifyContent="end" pt={4}>
                      <FormControl>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Status
                        </SoftTypography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          // defaultValue={status==="visited"?"Active":"Deactive"}
                          size="small"
                          value={status === "visited" ? "Active" : "Deactive"}
                        >
                          <FormControlLabel
                            value="Active"
                            control={<Radio />}
                            label="Visited"
                            disabled
                          />
                          <FormControlLabel
                            value="Deactive"
                            control={<Radio />}
                            label="Not Visited"
                            disabled
                          />
                        </RadioGroup>
                      </FormControl>
                    </SoftBox>
                  </Grid>

                  <Grid item md={12} xs={12} lg={12}>
                    <SoftBox>
                      <SoftBox mb={1} ml={0.5}>
                        <TimelineList title="User Preference">
                          <TimelineItem
                            color="info"
                            number="1"
                            title="What is the main purpose of your visit?"
                            // dateTime="22 DEC 7:20 PM"
                            badges={[data?.questionires?.main_purpose]}
                          />
                          <TimelineItem
                            color="success"
                            number="2"
                            title="What specific procedure are you looking to have?"
                            // dateTime="21 DEC 11 PM"
                            badges={data?.questionires?.service?.map(
                              (val) => val
                            )}
                          />
                          <TimelineItem
                            number="3"
                            title="What I'm looking for in a dentist?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={data?.questionires?.looking_in_dentist?.map(
                              (val) => val
                            )}
                          />
                          <TimelineItem
                            number="4"
                            title="When was the last time you saw a dentist?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={[data?.questionires?.last_time]}
                          />
                          <TimelineItem
                            number="5"
                            title="What time works best for your visit?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={data?.questionires?.best_visit_time?.map(
                                (val) => val
                              )
                              // [data?.questionires?.best_visit_time]
                            }
                            // {[data?.questionires?.best_visit_time]}
                          />
                          {/* <TimelineItem
                                                        number="6"
                                                        title="What time for your visit works best for you"
                                                        dateTime="21 DEC 9:34 PM"
                                                        badges={["Morning 8am-12pm"]}

                                                    /> */}
                          <TimelineItem
                            number="7"
                            title="How soon would you like to receive care?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={[data?.questionires?.receive_care]}
                          />
                          <TimelineItem
                            number="8"
                            title="Do you have dental insurance?"
                            // dateTime="21 DEC 9:34 PM"
                            badges={[
                              data?.questionires?.have_insurance
                                ? "Yes i have Insurance"
                                : "No I don't have insurance",
                            ]}
                          />
                          {data?.questionires?.have_insurance && (
                            <TimelineItem
                              number="9"
                              title="Who is your dental insurance provider?"
                              // dateTime="21 DEC 9:34 PM"
                              badges={[data?.questionires?.insurance]}
                              lastItem
                            />
                          )}
                        </TimelineList>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={3}
            >
              <SoftButton
                style={{ marginRight: "10px" }}
                component={Link}
                to="/appointmentlist"
                variant="outlined"
                color="info"
              >
                Back
              </SoftButton>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
