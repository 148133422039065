

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import Table from "examples/Tables/Table";
import UserTableData from "./data/UserTableData";
import { Card } from "@mui/material";
import ProfilesList from "examples/Lists/ProfilesList";
import profilesListData from "./data/profilesListData";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import { Link } from 'react-router-dom'
import Pagination from "../pagination/index";
import { Button } from "react-bootstrap";


function Dashboard() {

  const { size } = typography;
  const { columns } = UserTableData;
  const [patientData, setPatientData] = useState([]);
  const [cardData, setCardData] = useState({});
  const [month, setMonth] = useState([]);
  const [value, setValues] = useState([]);
  const [profilesListData, setProfilesListData] = useState([])
  const [percentage1, setPercentage] = useState(0);
  const [chartData, setChartData] = useState([])
  function Author({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const reportsBarChartData = {
    chart: {
      labels: month,
      datasets: { label: "Users", data: value },
    },
    items: [
      {
        icon: { color: "primary", component: "library_books" },
        label: "users",
        progress: { content: "36K", percentage: 60 },
      },
      {
        icon: { color: "info", component: "touch_app" },
        label: "clicks",
        progress: { content: "2M", percentage: 90 },
      },
      {
        icon: { color: "warning", component: "payment" },
        label: "sales",
        progress: { content: "$435", percentage: 30 },
      },
      {
        icon: { color: "error", component: "extension" },
        label: "items",
        progress: { content: "43", percentage: 50 },
      },
    ],
  };

  // const profilesListData = [
  //   {
  //     // image: kal,
  //     name: "Elite Dental care",
  //     description: "EliteDental@Mailinator.com ",
  //     action: {
  //       type: "internal",
  //       route: "/pages/profile/profile-overview",
  //       color: "info",
  //       label: "view",
  //     }}]

  const { chart, items } = reportsBarChartData;

  const formatDateTime = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const localDateString = utcDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
    const localTimeString = utcDate.toLocaleTimeString('en-US', { timeZone: 'UTC' });

    return { formattedDate: localDateString, formattedTime: localTimeString };
  };


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (value) => {
    setCurrentPage(value);
    console.log("Current Page:", value);
  };

  const getData = async () => {
    try {

      const offset = currentPage * 10 - 10;

      const param = {
        sort: -1,
        offset : offset,
        limit :10
      }
      const resp = await makeHttpRequest('get', 'appointmentList', null, param);
      console.log(resp)

      setPatientData(resp?.data.map((val) => {
        return {
          patient: <Author image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.user_details?.profile_image}`} name={val?.user_details?.full_name} email={val?.user_details?.email} />,
          "Consulting Doctor": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {val?.dentist_details?.full_name}
            </SoftTypography>
          ),

          Clinic: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {val?.clinic_details?.full_name}
            </SoftTypography>
          ),

          Branch: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {val?.branch_details?.full_name}
            </SoftTypography>
          ),
          status: (
            <SoftBadge variant="gradient" badgeContent={ val?.is_visited === "visited" ? "Visited" : val?.is_visited === "not visited"
            ? "Not Visited"
            : "Pending"} 
            color={
                  val?.is_visited === "visited"
                    ? "success"
                    : val?.is_visited === "not visited"
                    ? "error"
                    : "warning"
                } 
            size="xs" container />
          ),
          "billing status" : (
            <SoftBadge
              variant="gradient"
              badgeContent={
                val?.not_to_be_charged ? "Not charged" :
                 val?.invoice?.status === "payed"
                  ? "Payed"
                  : val?.invoice?.status === "pending"
                  ? "Unpayed"
                  : "Pending"
                  
              }
              color={
                val?.not_to_be_charged ? "warning" :
                val?.invoice?.status === "payed"
                  ? "success"
                  : val?.invoice?.status === "pending"
                  ? "error"
                  : "warning"
              }
              size="xs"
              container
            />
          ),
          Date: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {formatDateTime(val?.start_time).formattedDate}
            </SoftTypography>
          ),

          Time: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {formatDateTime(val?.start_time).formattedTime}
            </SoftTypography>
          ),
          action: (
            <SoftBox>


              <SoftTypography
                component={Link}
                to={`/viewAppointment/${val?._id}`}
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                marginRight="10px"
              >
                View
              </SoftTypography>

              {/* <SoftTypography
                  component={Link}
                  to={`/editAppointment/${val?._id}`}
                  href="#"
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                >
                  Edit
                </SoftTypography> */}

            </SoftBox>

          ),
        }
      }));
      const count1 = Math.ceil(resp?.count/10)
      setTotalPage(count1)

      const resp1 = await makeHttpRequest('get', 'dashBoardCardData');
      console.log(resp1)
      setCardData(resp1?.data)
     

      const monthsArrayAbbreviated = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const resp2 = await makeHttpRequest('get', 'activeUsers');
      console.log('resp2?.data', resp2)
      setPercentage(resp2?.percentage)
      // console.log(resp2?.percentage)

      const activeUserData = resp2?.data?.reverse()?.map((val) => {

        return {
          month: monthsArrayAbbreviated[val?.month - 1],
          data: val?.unique_logins
        }
      })
      console.log(activeUserData)

      const newMonths = [];
      const newValues = [];

      for (let index = 0; index < activeUserData.length; index++) {
        newMonths.push(activeUserData[index]?.month);
        newValues.push(activeUserData[index]?.data);
      }

      // Append new data to existng state arrays
      setMonth([...newMonths]);
      setValues([...newValues]);

      const resp3 = await makeHttpRequest('get', 'enquiryListing');
      console.log(resp3)
      setProfilesListData(resp3?.data?.map((val) => {
        return {
          name: val?.practic_name,
          description: val?.email,
          phone : val?.number,
          action : null
          // action: {
          //   type: "internal",
          //   route: "/enquiry-Management",
          //   color: "info",
          //   label: "view",
          // }
        }}))

        const resp4 = await makeHttpRequest('get', 'chartData');
        console.log(resp4)

        let chartData1 = (resp4?.data?.map((val)=>{
          return{
            month:monthsArrayAbbreviated[val?.month - 1],
            visited_count : val?.visited_count,
            total_count : val?.total_count
          }
        }))

       setChartData(chartData1)
    } catch (error) {
      // toast.error('Network error.');
    }
  }

  // console.log(chartData)

  useEffect(() => {
    getData();
  }, [currentPage])  



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: " Total revenue generated" }}
                count={`$${cardData?.total_revenue}`}
                percentage={{ color: "success" }}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Total number of bookings completed" }}
                count={cardData?.total_appointmnet}
                percentage={{ color: "success" }}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <MiniStatisticsCard
                title={{ text: "Total number of registered clinics" }}
                count={`+${cardData?.total_clinic}`}
                percentage={{ color: "error" }}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>

          </Grid>
        </SoftBox>


        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="active users"
                description={
                  <>
                    {/* (<strong>{Math.sign(parseInt(cardData?.percentage)) === -1?cardData?.percentage : `+${cardData?.percentage}`}%</strong>) than last week */}
                    (<strong>{percentage1 < 0 ? percentage1 : `+${percentage1}`}%</strong>) than last week

                  </>
                }
                chart={chart}

              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Appointment Overview"
                // description={
                //   <SoftBox display="flex" alignItems="center">
                //     <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                //       <Icon className="font-bold">arrow_upward</Icon>
                //     </SoftBox>
                //     <SoftTypography variant="button" color="text" fontWeight="medium">
                //       4% more{" "}
                //       <SoftTypography variant="button" color="text" fontWeight="regular">
                //         in 2021
                //       </SoftTypography>
                //     </SoftTypography>
                //   </SoftBox>
                // }
                height="20.25rem"
                chart={{
                  labels: chartData?.map((val)=>val?.month),
                  datasets: [
                    {
                      label: "Total Count",
                      color: "info",
                      data: chartData?.map((val)=>val?.total_count),
                    },
                    {
                      label: "visited Count",
                      color: "dark",
                      data: chartData?.map((val)=>val?.visited_count),
                    },
                  ],
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Card>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SoftTypography variant="h6">Recent bookings scheduled</SoftTypography>
                {/* <SoftTypography variant="h6">View All</SoftTypography> */}
              </SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table columns={columns} rows={patientData} />
          <Pagination count={totalPage} onChange={handlePageChange} />

              </SoftBox>
            </Card>

            {/* <Projects /> */}
          </Grid>
          <Grid item xs={12} xl={4}>
            <ProfilesList title="Recent Enquiries" profiles={profilesListData}  />
          
          </Grid>
        </Grid>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
