import React from 'react';
import PropTypes from 'prop-types';

const SoftTextArea = ({ value, onChange, placeholder, rows, cols,name,id,disabled=false }) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      name={name}
      id={id}
      disabled={disabled}
   
    />
  );
};

SoftTextArea.propTypes = {
  value: PropTypes.string,
  name : PropTypes.string,
  id:  PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
};

export default SoftTextArea;
