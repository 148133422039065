import SoftBox from 'components/SoftBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import { Card, Grid } from '@mui/material'
import SoftTypography from 'components/SoftTypography'
import SoftInput from 'components/SoftInput'
import Footer from 'examples/Footer'
import user01 from "../../assets/images/team-1.jpg"
import { FaCloudUploadAlt } from "react-icons/fa";
import SoftButton from 'components/SoftButton'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { makeHttpRequest } from 'utils/httpServices'
import { useEffect } from 'react'

export default function ViewUserManagement() {
    const {id} = useParams()
    const getData = async () => {
        try {
            const resp = await makeHttpRequest('get', `getSingleUser/${id}`);
            console.log(resp?.data?.status);
            const inputString = resp?.data?.status;
            const capitalizedString = inputString.charAt(0).toUpperCase() + inputString.slice(1);
            console.log(resp)


            formik.setValues({
                first_name: resp?.data?.first_name || '',
                last_name: resp?.data?.last_name || '',
                phone_number: resp?.data?.phone_number || '',
                email: resp?.data?.email || '',
                status: capitalizedString || '',
                profile_image: resp?.data?.profile_image
            })

        } catch (error) {
            toast.error('Network Error.')
        }
    }
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            status: '',
            profile_image: ''
        }
    })
    useEffect(() => {
        getData();
    }, [id]);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card  >
                        <SoftBox p={3}>
                            <SoftBox mb={3} className="upload-image">
                                <SoftBox className="image-container" >
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}userProfile/${formik?.values?.profile_image}`} alt="" />
                                </SoftBox>
                            </SoftBox>
                            <SoftBox mb={3} component="form" role="form" className="appointment_form" >
                                <SoftTypography variant="h6">User Details</SoftTypography>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12} lg={6} xl={6}>
                                        <SoftBox >
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    First Name <span className='text-danger'>*</span>
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput type="text"  placeholder="First Name" value={formik?.values?.first_name}/>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item md={6} xs={12} lg={6} xl={6}>
                                        <SoftBox >
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Last Name <span className='text-danger'>*</span>
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput type="text" value={formik?.values?.last_name} placeholder="Last Name" />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item md={6} xs={12} lg={6} xl={6}>
                                        <SoftBox>
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Mobile Number <span className='text-danger'>*</span>
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput type="text" value={formik?.values?.phone_number} placeholder="Number" />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item md={6} xs={12} lg={6} xl={6}>
                                        <SoftBox >
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Email ID <span className='text-danger'>*</span>
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput type="email" value={formik?.values?.email} placeholder="email" />
                                        </SoftBox>
                                    </Grid>
                                    {/* <Grid item md={6} xs={12} lg={6} xl={6}>
                                        <SoftBox >
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Joining Date <span className='text-danger'>*</span>
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput type="Date" value="DD/MM/YY" placeholder="DD/MM/YY" />
                                        </SoftBox>
                                    </Grid> */}
                                    <Grid item xs={12} md={3}>
                                        <SoftBox display="flex" justifyContent="end" pt={4} >
                                            <FormControl>
                                                <SoftTypography  component="label" variant="caption" fontWeight="bold">
                                                    Status 
                                                </SoftTypography>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    value={formik?.values?.status}
                                                    size="small"
                                                >
                                                    <FormControlLabel  value="Active" control={<Radio />} label="Active" />
                                                    <FormControlLabel value="Deactive" control={<Radio />} label="Deactive" />
                                                </RadioGroup>
                                            </FormControl>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>

                        <SoftBox display="flex" justifyContent="center" alignItems="center" pb={3}>
                            <SoftButton variant="outlined" component={Link} to="/userManagement"  color="info">Back</SoftButton>
                        </SoftBox>

                    </Card>
                </SoftBox>
            </SoftBox>
            {/* <Footer /> */}
        </DashboardLayout>
    )
}
