import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Select,
  Switch,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";
import SoftTypography from "components/SoftTypography";
import SuiSelect from "components/SuiSelect/SuiSelect";
import React, { useCallback, useRef, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
// import Services from "components/Services/Services";
import user01 from "../../assets/images/team-1.jpg";
import clinilogo from "../../assets/images/cliniclogo.jpg";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import { makeHttpGoogleMapRequest } from "utils/httpServices";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import Services from "../Services/Services";
import InsuranceList from "../Insurances/Insurances";
import { generateHoursOptions } from "utils/common";

export default function DialogBranches({
  open2,
  handleClose2,
  id,
  setBranchData,
  getBranchList,
}) {
  // --DropZone--

  const [validLocation, setValidLocation] = useState(false);
  const [modernRating, setModernRating] = useState("high");
  const [flexibilityRating, setFlexibilityRating] = useState("high");
  const libraries = ["places"];
  const searchBoxRefStreet = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [clinicLogoPath, setCliniclogoPath] = useState(null);
  const [getClinicImagePath, setGetClinicImagePath] = useState(null);
  const [SelectedClinicImagePath, setSelectedClinicImagePath] = useState(null);
  const [clinicUrlImg, setClinicUrlImg] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [geoLocation, setGeoLoaction] = useState({ lng: 0, lat: 0 });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });
  const [getServicesData, setGetServicesData] = useState([]);
  const [getInsurancesData, setGetInsurancesData] = useState([]);

  // Callback for when files are dropped
  const onDrop = useCallback(
    (acceptedFiles) => {
      const updatedFiles = selectedFiles.concat(
        acceptedFiles.slice(0, 6 - selectedFiles.length)
      );
      setSelectedFiles(updatedFiles);
    },
    [selectedFiles]
  );

  const removeImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  // react-dropzone hook configuration
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
    maxFiles: 6,
  });

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // ---steps---/

  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep == 1) {
      formik.validateForm(formik.values).then((errors) => {
        // Set touched for all fields
 

        formik.setTouched({
          practice_name: true,
          full_name: true,
          phone_number: true,
          email: true,
          bio: true,
          address: true,
          profile_img: true,
          logo_img: true,
        });
        console.log(Object.keys(errors).length, "length");
        if (Object.keys(errors).length === 0) {
          setCurrentStep(currentStep + 1);
        }
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handleSubmit = async (values) => {
    // console.log('kello')
    // console.log(values)

    const body = { ...values };
    //  const t = body;
    //  console.log(t)
    //  delete body.working_hours;
    const body1 = JSON.parse(JSON.stringify(body));

    // console.log()

    try {
      for (let i = 0; i < formik.values.working_hours.length; i++) {
        if (!formik.values.working_hours[i].day) {
          console.log(formik.values.working_hours[i].day);
          toast.info("All fields are mandatory.");
          return;
        }
        if (!formik.values.working_hours[i].start_time) {
          toast.info("All fields are mandatory.");
          return;
        }
        if (!formik.values.working_hours[i].end_time) {
          toast.info("All fields are mandatory.");
          return;
        }
        if (formik.values.working_hours[i].enabled == null) {
          toast.info("All fields are mandatory.");
          return;
        }
      }

      console.log(clinicLogoPath);
      let logo = clinicLogoPath;
      if (
        !formik.values.practice_name ||
        !formik.values.full_name ||
        !formik.values.phone_number ||
        !formik.values.email ||
        !formik.values.bio ||
        !formik.values.address ||
        !logo ||
        !SelectedClinicImagePath ||
        selectedFiles.length === 0
      ) {
        toast.info("All fields are mandatory.");
        return;
      }

      // console.log(formik.values.working_hours)
      // console.log(body1.working_hours)

      // console.log(body1.working_hours.length)
      for (let i = 0; i < body1.working_hours.length; i++) {
        const day = body1.working_hours[i].day;
        const startTime = body1.working_hours[i].start_time;
        const endTime = body1.working_hours[i].end_time;
        const enabled = body1.working_hours[i].enabled;

        if (!day || !startTime || !endTime || enabled === null) {
          toast.error("All fields are mandatory.");
          // handleClose();
          return;
        }

        const [starhour, startminute] = startTime.split(":");
        const [endHour, endMinute] = endTime.split(":");
        // console.log('jhsdgfagfjkasf',starhour, endHour)
        // console.log('jhdgfhkdgf')

        let time = 0;
        starhour === "00" ? (time = starhour) : (time = starhour);
        // console.log(starhour)

        if (time > endHour) {
          if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
            toast.error(
              "Invalid working hours. Start time must be before end time."
            );
            return;
          } else if (
            time >= 13 &&
            time <= 24 &&
            endHour >= 13 &&
            endHour <= 24
          ) {
            toast.error(
              "Invalid working hours. Start time must be before end time."
            );
            return;
          }
        } else if (time === endHour && startminute >= endMinute) {
          toast.error(
            "Invalid working hours. Start time must be before end time."
          );
          return;
        }

        const startInputTime = new Date();
        const endInputTime = new Date();

        startInputTime.setHours(starhour, 0, 0, 0); // Set the hours and minutes
        endInputTime.setHours(endHour, 0, 0, 0); // Set the hours and minutes

        // const timeZoneOffset = startInputTime.getTimezoneOffset();

        // // Adjust the start time to UTC by subtracting the offset
        // const startUtcTime = new Date(startInputTime.getTime() - timeZoneOffset * 60000);
        // const endUtcTime = new Date(endInputTime.getTime() - timeZoneOffset * 60000);

        body1.working_hours[i].start_time = startInputTime.toISOString();
        body1.working_hours[i].end_time = endInputTime.toISOString();
      }
      if (clinicLogoPath) {
        body.clinic_image = clinicLogoPath;
        body1.clinic_image = clinicLogoPath;
      }
      if (SelectedClinicImagePath) {
        body.profile_image = SelectedClinicImagePath;
        body1.profile_image = SelectedClinicImagePath;
      } else {
        body1.profile_image = getClinicImagePath;
      }

      body1.insurance_they_accept = insurancess;
      body1.services = services;

      // console.log(selectedFiles)
      // if (selectedFiles.length === 0) {
      //     toast.error('Please add branch images.');
      //     return;
      // }
      //   const encodedAddress = encodeURIComponent(body.address);
      //   const Resp2 = await makeHttpGoogleMapRequest(encodedAddress);
      //   // console.log('Resp1------------->', Resp1);
      //   if (Resp2?.status == "ZERO_RESULTS") {
      //     toast.error("Please add a valid address.");
      //     return;
      //   }
      //   const longitude = Resp2?.results[0].geometry.location.lng;
      //   const latitude = Resp2?.results[0].geometry.location.lat;
      if (validLocation === false) {
        toast.error("Please add right address.");
        return;
      }
      const location = {
        type: "Point",
        coordinates: [geoLocation?.lng, geoLocation?.lat],
      };

      body1.location = location;
      const formData = new FormData();
      formData.append("path", "clinicDocument");
      selectedFiles.forEach((file, index) => {
        formData.append(`media`, file);
      });

      const Resp = await makeHttpRequest(
        "post",
        "uploadAdminMultipleMedia",
        formData
      );
      // console.log(Resp?.path);

      if (Resp) {
        body.clinic_documents = Resp?.path;
        body1.clinic_documents = Resp?.path;
      }

      body1.clinic_id = id;
      body1.modern=modernRating;
      body1.flexibility=flexibilityRating
      const resp = await makeHttpRequest("post", "addBranch", body1);
      // console.log(resp)
      toast.success("Branch added successfully");
      handleClose2();
      formik.setValues({
        practice_name: "",
        full_name: "",
        phone_number: "",
        email: "",
        bio: "",
        address: "",
        working_hours: [
          {
            day: "",
            start_time: "",
            end_time: "",
            enabled: true,
          },
        ],
      });
      setServices([]);
      setSelectedFiles([]);
      setClinicUrlImg(null);
      setGetClinicImagePath(null);
      setSelectedClinicImagePath(null);
      const Resp1 = await makeHttpRequest("get", `getBranchListing`, null, {
        clinic_id: id,
      });
      setBranchData(Resp1?.data);
      getBranchList();
      setCurrentStep(1);

      // navigate('/clinicManagement')
    } catch (error) {
      // console.log(error)
      // toast.error(error?.response?.data?.msg)
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    practice_name: Yup.string().required("Practice Name is required"),
    full_name: Yup.string().required("Name is required"),
    phone_number: Yup.number()
      .required("Phone Number is required")
      .typeError("Phone Number must be a valid number")
      .positive("Phone Number must be a positive number")
      .integer("Phone Number must be an integer"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    address: Yup.string().required("Address is required"),
    bio: Yup.string().required("Bio is required"),
    logo_img: Yup.string().required("Logo image is required"),
    profile_img: Yup.string().required("Profile image is required"),
  });

  const formik = useFormik({
    initialValues: {
      practice_name: "",
      full_name: "",
      phone_number: "",
      email: "",
      bio: "",
      address: "",
      profile_img: "",
      logo_img: "",
      working_hours: [
        {
          day: "",
          start_time: "",
          end_time: "",
          enabled: true,
        },
      ],
    },
    validationSchema: validationSchema,

    onSubmit: handleSubmit,
  });

  const onMapLoadStreet = (map) => {
    const searchBox = new window.google.maps.places.SearchBox(
      searchBoxRefStreet.current
    );

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places?.length === 0) {
        // No valid places found
        // console.log("Invalid address entered");
        return;
      }

      const loc = places[0].formatted_address;
      console.log(places);

      // Assuming that having at least one place means a valid address
      // You may add more validation based on your requirements
      if (isValidPlace(places[0])) {
        formik.setFieldValue("address", loc);
        setGeoLoaction({
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng(),
        });
        setValidLocation(true);
      } else {
        console.log("Invalid address entered");
        toast.error("Please enter valid address.");
        setValidLocation(false);
      }
    });
  };

  const isValidPlace = (place) => {
    // Example validation: Check if the place has a valid formatted address

    return (
      place && place.formatted_address && place.formatted_address.trim() !== ""
    );
  };

  const hanldeAddWorkField = () => {
    formik.setFieldValue("working_hours", [
      ...formik.values.working_hours,
      {
        day: "",
        start_time: "",
        end_time: "",
        enabled: true,
      },
    ]);
  };

  const hanldemediaUpload = async (file, media) => {
    console.log(file);
    const formData = new FormData();
    formData.append("path", "userProfile");
    formData.append("media", file);

    try {
      if (media === "image") {
        const resp = await makeHttpRequest(
          "post",
          "uploadAdminMedia",
          formData
        );
        setSelectedClinicImagePath(resp?.path);
        formik.setFieldValue("profile_img", resp?.path);
      }
      if (media === "logo") {
        const reader = new FileReader();

        reader.onload = (event) => {
          setClinicUrlImg(event.target.result);
          // console.log(event.target.result)
        };
        reader.readAsDataURL(file);
        const resp = await makeHttpRequest(
          "post",
          "uploadAdminMedia",
          formData
        );
        setCliniclogoPath(resp?.path);

        formik.setFieldValue("logo_img", resp?.path);

        // setGetClinicImagePath(resp?.path)
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(clinicLogoPath)

  const handleDeleteWorkField = (index) => {
    console.log("checkuingdsasa")
    const newWorkingHours = [...formik.values.working_hours];
    newWorkingHours.splice(index, 1);
    formik.setFieldValue("working_hours", newWorkingHours);
  };

  const [services, setServices] = React.useState([]);
  const [serData, setSerData] = useState("");
  const [val, setVal] = React.useState("");
  // const [canVal, setCanVal] = useState("");
  // const [otherVal, setOtherVal] = useState("");
  const [insurancess, setInsurancess] = useState([]);
  const [insData, setInsData] = useState("");

  const getServices = (services1) => {
    setServices(services1);
  };

  const getInsurance = (insurances) => {
    setInsurancess(insurances);
  };

  const insuranceHandler = (val, type) => {
    if (type === "canadian") {
      setInsData(val);
      // setCanVal(val);
    } else {
      setInsData(val);
      // setOtherVal(val);
    }
  };

  const serviceHandler = (val) => {
    // if (serData?.includes(val)) {
    //   return;
    // }
    console.log(val);
    setSerData(val);
    setVal(val);
  };
  return (
    <div>
      <Dialog
        open={open2}
        onClose={() => handleClose2()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            minWidth: "600px",
            minHeight: "550px",
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{"Add Location"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="provider_container">
              {currentStep === 1 && (
                <SoftBox className="first_Step">
                  <SoftBox mb={3} className=" upload-image">
                    <SoftBox className="image-container ">
                      {clinicLogoPath ? (
                        <img src={clinicUrlImg} width={100} height={100} />
                      ) : getClinicImagePath ? (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}userProfile/${getClinicImagePath}`}
                          width={100}
                          height={100}
                        />
                      ) : (
                        <img
                          src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png"
                          width={100}
                          height={100}
                          alt=""
                        />
                      )}
                    </SoftBox>
                    <label htmlFor="uploadd23-image">
                      <FaCloudUploadAlt />
                    </label>
                    <input
                      type="file"
                      id="uploadd23-image"
                      onChange={(event) =>
                        hanldemediaUpload(event.target.files[0], "logo")
                      }
                    />
                  </SoftBox>
                  {formik.touched.logo_img && formik.errors.logo_img ? (
                    <div className="form-error1">{formik.errors.logo_img}</div>
                  ) : null}
                  <SoftBox>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Enter The Practice Name{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="First Name"
                            {...formik.getFieldProps("practice_name")}
                          />
                          {formik.touched.practice_name &&
                          formik.errors.practice_name ? (
                            <div className="form-error">
                              {formik.errors.practice_name}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Owner Name{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder=" Owner Name"
                            name="full_name"
                            {...formik.getFieldProps("full_name")}
                          />
                          {formik.touched.full_name &&
                          formik.errors.full_name ? (
                            <div className="form-error">
                              {formik.errors.full_name}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Email ID <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Email"
                            name="email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="form-error">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Mobile Number
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="number"
                            name="phone_number"
                            {...formik.getFieldProps("phone_number")}
                          />
                          {formik.touched.phone_number &&
                          formik.errors.phone_number ? (
                            <div className="form-error">
                              {formik.errors.phone_number}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Upload Clinic Background Image{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          {/* <SoftInput type="file" onChange={(event)=>setCliniclogo(event.target.files[0])}  placeholder="upload" /> */}
                          <SoftInput
                            type="file"
                            onChange={(event) =>
                              hanldemediaUpload(event.target.files[0], "image")
                            }
                            placeholder="upload"
                          />
                        </SoftBox>
                        {formik.touched.profile_image &&
                        formik.errors.profile_image ? (
                          <div className="form-error">
                            {formik.errors.profile_image}
                          </div>
                        ) : null}
                      </Grid>
                      {/* <Grid item xs={12}>
                                                <SoftBox >
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Upload Clinic Image  <span className='text-danger'>*</span>
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput type="file" placeholder="upload" onChange={(event) => hanldemediaUpload(event.target.files[0], 'image')} />

                                                </SoftBox>
                                            </Grid> */}

                      <Grid item md={12} xs={12} lg={12} xl={12}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Add Address <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          {/* <SoftTextArea placeholder={"Enter Your Full Address"}
                                                        rows={4}
                                                        cols={4}
                                                        {...formik.getFieldProps("address")} />
                                                    {
                                                        formik.touched.address && formik.errors.address ? <div className="form-error">{formik.errors.address}</div> : null
                                                    } */}
                          <input
                            className="form-control ad-adrs-input"
                            placeholder="Your Address"
                            name="school_address"
                            type="textarea"
                            // onChange={(e) => { handleChange(e) }}
                            onFocus={handlePopupOpen}
                            onClick={handlePopupOpen}
                            ref={searchBoxRefStreet}
                          />

                          {showPopup && (
                            <div className="map-popup">
                              <GoogleMap onLoad={onMapLoadStreet}></GoogleMap>
                            </div>
                          )}
                          {formik.touched.address && formik.errors.address ? (
                            <div className="form-error">
                              {formik.errors.address}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={12} xs={6} lg={12} xl={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {" "}
                          {/* Adjust the xs value as needed */}
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Modern <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <SuiSelect
                              className="filter selctServc mb-4"
                              placeholder="Select Services"
                              options={[
                                { value: "high", label: "High" },
                                { value: "medium", label: "Medium" },
                                { value: "low", label: "Low" },
                              ]}
                              value={modernRating}
                              onChange={(event) =>
                                setModernRating(event)
                              }
                            />
                          </SoftBox>
                        </Grid>
                        <Grid item xs={6}>
                          {" "}
                          {/* Adjust the xs value as needed */}
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Flexibility{" "}
                                <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <SuiSelect
                              className="filter selctServc mb-4"
                              placeholder="Select Services"
                              options={[
                                { value: "high", label: "High" },
                                { value: "medium", label: "Medium" },
                                { value: "low", label: "Low" },
                              ]}
                              value={flexibilityRating}
                              onChange={(event) =>
                                setFlexibilityRating(event)
                              }
                            />
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </Grid>
                      <Grid item md={12} xs={12} lg={12} xl={12}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Add Bio <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftTextArea
                            placeholder={"Enter Your Bio"}
                            rows={4}
                            cols={4}
                            name="bio"
                            {...formik.getFieldProps("bio")}
                          />
                          {formik.touched.bio && formik.errors.bio ? (
                            <div className="form-error">
                              {formik.errors.bio}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                </SoftBox>
              )}

              {currentStep === 2 && (
                <SoftBox className="second_Step">
                  <SoftBox
                    mt={3}
                    component="form"
                    role="form"
                    className="working_hours"
                  >
                    <SoftBox
                      display="flex"
                      mb={2}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">
                        Add Working Hours Details
                      </SoftTypography>
                      <SoftButton
                        variant="contained"
                        size="small"
                        color="info"
                        onClick={hanldeAddWorkField}
                      >
                        Add New
                      </SoftButton>
                    </SoftBox>

                    {formik.values.working_hours?.map((obj, index) => (
                      <Grid container spacing={2}>
                        <Grid item md={3} xs={12} lg={3} xl={3}>
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Select Day{" "}
                                <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <SuiSelect
                              className="filter"
                              placeholder="Select Day"
                              options={[
                                { value: "Monday", label: "Monday" },
                                { value: "Tuesday", label: "Tuesday" },
                                { value: "Wednesday", label: "Wednesday" },
                                { value: "Thursday", label: "Thursday" },
                                { value: "Friday", label: "Friday" },
                                { value: "Saturday", label: "Saturday" },
                                { value: "Sunday", label: "Sunday" },
                                // ... other providers
                              ]}
                              defaultValue="Monday"
                              value={formik.values.working_hours[index]?.day}
                              onChange={(selectedValue) => {
                                // Update the specific day value in the array
                                if (
                                  !formik?.values?.working_hours?.some(
                                    (wh) => wh?.day === selectedValue
                                  )
                                ) {
                                  const newWorkingHours = [
                                    ...formik.values.working_hours,
                                  ];
                                  newWorkingHours[index] = {
                                    ...newWorkingHours[index],
                                    day: selectedValue,
                                  };
                                  formik.setFieldValue(
                                    "working_hours",
                                    newWorkingHours
                                  );
                                } else {
                                  toast.error("Already exist.");
                                }
                              }}
                            />
                          </SoftBox>
                        </Grid>
                        <Grid item md={3} xs={12} lg={3} xl={3}>
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                               From <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <Select
                              {...formik.getFieldProps(
                                  `working_hours[${index}].start_time`
                                )}
                            
                            >
                             {generateHoursOptions()}
                           </Select>
                            {/* <SoftInput
                              type="time"
                              value="Brack"
                              placeholder="Last Name"
                              // {...formik.getFieldProps(
                              //   `working_hours[${index}].start_time`
                              // )}
                            /> */}
                          </SoftBox>
                        </Grid>
                        <Grid item md={3} xs={12} lg={3} xl={3}>
                          <SoftBox>
                            <SoftBox mb={1}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                To <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <Select
                              {...formik.getFieldProps(
                                  `working_hours[${index}].end_time`
                                )}
                            
                            >
                             {generateHoursOptions(formik.values.working_hours[index].start_time)}
                           </Select>
                            {/* <SoftInput
                              type="time"
                              value="+91812454412"
                              placeholder="Number"
                              {...formik.getFieldProps(
                                `working_hours[${index}].end_time`
                              )}
                            /> */}
                          </SoftBox>
                        </Grid>
                        <Grid item md={2} xs={12} lg={2} xl={2}>
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Enable <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <Switch
                              id={index}
                              checked={
                                formik.values.working_hours[index]?.enabled ||
                                false
                              }
                              onChange={(e) => {
                                const newWorkingHours = [
                                  ...formik.values.working_hours,
                                ];
                                newWorkingHours[index] = {
                                  ...newWorkingHours[index],
                                  enabled: e.target.checked,
                                };
                                formik.setFieldValue(
                                  "working_hours",
                                  newWorkingHours
                                );
                              }}
                            />
                          </SoftBox>
                        </Grid>
                        {index != 0 ? (
                          <Grid item md={1} xs={12} lg={1} xl={1}>
                            <SoftBox className="icon-cntner">
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Clear
                                </SoftTypography>
                              </SoftBox>
                              <IoMdCloseCircle
                                onClick={() => handleDeleteWorkField(index)}
                              />
                            </SoftBox>
                          </Grid>
                        ) : null}
                      </Grid>
                    ))}
                    {/* <Grid container spacing={2}>
                                        <Grid item md={3} xs={12} lg={3} xl={3}>
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Select Day <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SuiSelect className="filter"
                                                    placeholder="Select Day"
                                                    options={[
                                                        { value: "Monday", label: "Monday" },
                                                        { value: "Tuesday", label: "Tuesday" },
                                                        { value: "Wednesday", label: "Wednesday" },
                                                        { value: "Thrusday", label: "Thrusday" },
                                                        { value: "Friday", label: "Friday" },
                                                        { value: "Saturday", label: "Saturday" },
                                                        { value: "Sunday", label: "Sunday" },
                                                        // ... other providers
                                                    ]}
                                                    defaultValue="Monday"
                                                    onChange={(selectedValue) => {
                                                        console.log("Selected Monday:", selectedValue);
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={3} xs={12} lg={3} xl={3}>
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        From <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="time" value="Brack" placeholder="Last Name" />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={3} xs={12} lg={3} xl={3}>
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        To <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="time" value="+91812454412" placeholder="Number" />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={2} xs={12} lg={2} xl={2}>
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Enable <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Switch
                                                    checked={checked}
                                                    onChange={handleChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={1} xs={12} lg={1} xl={1}>
                                            <SoftBox className="icon-cntner">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Clear
                                                    </SoftTypography>
                                                </SoftBox>
                                                <IoMdCloseCircle />
                                            </SoftBox>
                                        </Grid>

                                    </Grid> */}
                  </SoftBox>
                </SoftBox>
              )}

              {currentStep === 3 && (
                <SoftBox mb="3" className="clinic_ser_&_insur">
                  <SoftBox className="add_services_sec">
                    <SoftTypography variant="h6" mb={2}>
                      Add Services
                    </SoftTypography>
                    <Services
                      setServicesToParent={getServices}
                      serData={serData}
                      getServicesData={getServicesData}
                      isDisabledInput={true}
                    />
                    <div
                      className="selectWrpSm"
                      style={{ width: "100%", margin: "1rem 0" }}
                    >
                      <SuiSelect
                        className="filter selctServc mb-4"
                        placeholder="Select Services"
                        options={[
                          { value: "Fillings", label: "Fillings" },
                          { value: "Crowns", label: "Crowns" },
                          { value: "Root Canal", label: "Root Canal" },
                          {
                            value: "Wisdom teeth Removal",
                            label: "Wisdom teeth Removal",
                          },
                          {
                            value: "Teeth Whitening",
                            label: "Teeth Whitening",
                          },
                          {
                            value: "Dental Bridge",
                            label: "Dental Bridge",
                          },
                          {
                            value: "Tooth Extraction",
                            label: "Tooth Extraction",
                          },
                          {
                            value: "Checkup & Cleanings",
                            label: "Checkup & Cleanings",
                          },
                          // {
                          //   value: "Dental Bonding",
                          //   label: "Dental Bonding",
                          // },
                          {
                            value: "Braces",
                            label: "Braces",
                          },
                        ]}
                        value={val || "Fillings"}
                        onChange={serviceHandler}
                      />
                    </div>
                  </SoftBox>
                  <SoftBox className="add_insurances">
                    <SoftTypography variant="h6" mb={2}>
                      Add Insurance
                    </SoftTypography>
                    <InsuranceList
                      setServicesToParent={getInsurance}
                      serData={insData}
                      getInsurancesData={getInsurancesData}
                    />
                    <SoftBox display="flex" alignItems="center" gap={1}>
                      <div
                        className="selectWrpSm"
                        style={{ width: "100%", margin: "1rem 0" }}
                      >
                        <SuiSelect
                          className="filter selctServc mb-4"
                          placeholder="Select Services"
                          options={[
                            {
                              value: "Canadian Insurances",
                              label: "Canadian Insurances",
                              disabled: true,
                            },

                            {
                              value: "Manulife Financial",
                              label: "Manulife Financial",
                            },
                            {
                              value: "Sunlife Financial",
                              label: "Sunlife Financial",
                            },
                            {
                              value: "Canada Life",
                              label: "Canada Life",
                            },
                            {
                              value: "Blue Cross",
                              label: "Blue Cross",
                            },
                            {
                              value: "Desjardins",
                              label: "Desjardins",
                            },
                            {
                              value: "Green Shield",
                              label: "Green Shield",
                            },
                             {
                              value: "CDCP",
                              label: "CDCP",
                            },
                          ]}
                          // value={canVal || "Canada Life"}
                          defaultValue="Canadian Insurances"
                          onChange={(selected) =>
                            insuranceHandler(selected, "canadian")
                          }
                        />
                      </div>
                      <div
                        className="selectWrpSm 123"
                        style={{ width: "100%", margin: "1rem 0" }}
                      >
                        <SuiSelect
                          className="filter selctServc mb-4 "
                          placeholder="Select Services"
                          options={[
                            {
                              value: "Others",
                              label: "Others",
                              disabled: true,
                            },

                            { value: "Anthem", label: "Anthem" },
                            { value: "Humana", label: "Humana" },
                            {
                              value: "Ameritas",
                              label: "Ameritas",
                            },
                            {
                              value: "Aetna",
                              label: "Aetna",
                            },
                            {
                              value: "Cigna",
                              label: "Cigna",
                            },
                            {
                              value: "Delta Dental",
                              label: "Delta Dental",
                            },
                            {
                              value: "Guardian",
                              label: "Guardian",
                            },
                            {
                              value: "Medicaid",
                              label: "Medicaid",
                            },
                            {
                              value: "Metlife",
                              label: "Metlife",
                            },
                            {
                              value: "United Health care",
                              label: "United Health care",
                            },
                          ]}
                          // value={otherVal}
                          defaultValue="Others"
                          onChange={(selected) =>
                            insuranceHandler(selected, "other")
                          }
                        />
                      </div>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )}

              {currentStep === 4 && (
                <SoftBox className="third_Step">
                  <SoftBox
                    mt={3}
                    component="form"
                    role="form"
                    className="working_hours"
                  >
                    <SoftBox
                      display="flex"
                      mb={2}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">Add Photos </SoftTypography>
                    </SoftBox>
                  </SoftBox>

                  <Grid item md={12} xs={12} lg={12} xl={12}>
                    <div className="imgUploaderContainer mt-5">
                      <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        <p>
                          Drag & drop images here, or click to select up to 6
                        </p>
                      </div>
                    </div>
                    {selectedFiles.length > 0 && (
                      <div className="image-preview">
                        <div className="image-list">
                          {selectedFiles.map((file, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`Preview ${index + 1}`}
                                style={{ maxWidth: "100%", maxHeight: "150px" }}
                              />
                              <button
                                className="close"
                                type="button"
                                onClick={() => removeImage(index)}
                              >
                                x
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Grid>
                </SoftBox>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <SoftButton variant='outlined' onClick={() => handleClose1()}>Cancel</SoftButton>
                    <SoftButton variant='contained' onClick={() => handleClose1()} autoFocus>
                        Done
                    </SoftButton> */}

            <SoftBox
              display="flex"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {currentStep === 1 && (
                <SoftButton
                  variant="outlined"
                  style={{ marginRight: "10px" }}
                  size="small"
                  color="info"
                  onClick={handleNext}
                >
                  Next
                </SoftButton>
              )}
              {currentStep === 2 && (
                <SoftBox>
                  <SoftButton
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    size="small"
                    color="info"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>

                  <SoftButton
                    variant="outlined"
                    size="small"
                    color="info"
                    onClick={handleNext}
                  >
                    Next
                  </SoftButton>
                </SoftBox>
              )}
              {currentStep === 3 && (
                <SoftBox>
                  <SoftButton
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    size="small"
                    color="info"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>

                  <SoftButton
                    variant="outlined"
                    size="small"
                    color="info"
                    onClick={handleNext}
                  >
                    Next
                  </SoftButton>
                </SoftBox>
              )}
              {/* {currentStep === 4 && (
                <SoftBox>
                  <SoftButton
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    size="small"
                    color="info"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>

                  <SoftButton
                    variant="outlined"
                    size="small"
                    color="info"
                    onClick={handleNext}
                  >
                    Next
                  </SoftButton>
                </SoftBox>
              )} */}

              {currentStep === 4 && (
                <SoftBox>
                  <SoftButton
                    variant="contained"
                    size="small"
                    style={{ marginRight: "10px" }}
                    color="info"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>

                  <SoftButton
                    variant="outlined"
                    size="small"
                    type="submit"
                    color="info"
                    // onClick={() => handleSubmit()}
                  >
                    Finish
                  </SoftButton>
                </SoftBox>
              )}
            </SoftBox>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
