import { Card, Grid, MenuItem, Select, Switch } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SuiSelect from "components/SuiSelect/SuiSelect";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { IoMdCloseCircle } from "react-icons/io";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";
import { useDropzone } from "react-dropzone";

import Doctor01 from "../../assets/images/team-1.jpg";
import Doctor02 from "../../assets/images/team-2.jpg";
import Doctor03 from "../../assets/images/team-3.jpg";
import DialogProviders from "components/DialogBox/DialogProviders";
import DefaultProjectCard2 from "./ProjectCards/DefaultProjectCard2";
import DefaultProjectCard3 from "./ProjectCards/DefaultProjectCard3";
// import SoftButton from "components/SoftButton";

// --Brand Images---

import branch01 from "assets/images/clinicImgs.jpg";
import branch02 from "assets/images/clininc.jpg";
import branch03 from "assets/images/clinic03.jpg";
import DialogBranches from "components/DialogBox/DialogBranches";

import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import { makeHttpGoogleMapRequest } from "utils/httpServices";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";

import Services from "components/Services/Services";
import InsuranceList from "components/Insurances/Insurances";
import { generateHoursOptions } from "utils/common";

export default function EditClinicManagement() {
  const { id } = useParams();
  const libraries = ["places"];
  const searchBoxRefStreet = useRef(null);
  const [clinicLogoPath, setCliniclogoPath] = useState(null);
  const [clinicImage, setClinicImage] = useState(null);
  const [modernRating, setModernRating] = useState(null);
  const [flexibilityRating, setFlexibilityRating] = useState(null);
  const [getClinicLogoPath, setGetetClinicLogoPath] = useState(null);
  const [getClinicImage, setGetClinicImage] = useState(null);

  const [documentImage, setDocumentImage] = useState([]);
  const [providerData, setProviderData] = useState([]);

  const [branchData, setBranchData] = useState([]);

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const [validLocation, setValidLocation] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [geoLocation, setGeoLoaction] = useState({ lng: 0, lat: 0 });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [getServicesData, setGetServicesData] = useState([]);
  console.log("🚀 ~ EditClinicManagement ~ getServicesData:", getServicesData)
  const [getInsurancesData, setGetInsurancesData] = useState([]);


  const [isWorkingHourChanged, setisWorkingHourChanged] = useState(false)

  // Callback for when files are dropped
  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log(acceptedFiles);
      const updatedFiles = documentImage.concat(
        acceptedFiles.slice(0, 6 - documentImage.length)
      );
      console.log(updatedFiles);
      console.log(documentImage);

      const formData = new FormData();
      formData.append("path", "clinicDocument");
      updatedFiles.forEach((file, index) => {
        formData.append(`media`, file);
      });

      const Resp = await makeHttpRequest(
        "post",
        "uploadAdminMultipleMedia",
        formData
      );
      console.log(Resp?.path);

      const totalImage = [...selectedFiles, ...Resp?.path];
      setSelectedFiles(totalImage);
    },
    [documentImage, setDocumentImage, selectedFiles, setSelectedFiles]
  );

  const removeImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  // react-dropzone hook configuration
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
    maxFiles: 6,
  });

  // ---steps---/

  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  // --Edit-Provider-Modal--/

  const [open1, setOpen1] = React.useState(false);
  console.log("🚀 ~ EditClinicManagement ~ open1:", open1)

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    console.log("handle close working in ")
    setOpen1(false);
  };

  // --Edit branch Modal--

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleSubmit = async (values) => {
    try {
      const body = { ...values };

      const body1 = JSON.parse(JSON.stringify(body));

      // if (
      //   !formik.values.practice_name ||
      //   !formik.values.full_name ||
      //   !formik.values.phone_number ||
      //   !formik.values.email ||
      //   !formik.values.bio ||
      //   !formik.values.address ||
      //   !clinicLogoPath ||
      //   !clinicImage ||
      //   selectedFiles.length === 0 ||
      //   services.length === 0 ||
      //   insurancess.length === 0
      // ) {
      //   // handleClose();
      //   toast.info("All fields are mandatory.");
      //   return;
      // }

      for (let i = 0; i < body1.working_hours.length; i++) {
        const day = body1.working_hours[i].day;
        const startTime = body1.working_hours[i].start_time;
        const endTime = body1.working_hours[i].end_time;
        const enabled = body1.working_hours[i].enabled;

        if (!day || !startTime || !endTime || enabled === null) {
          toast.error("All fields are mandatory.");
          return;
        }

        const [starhour, startminute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        if (starhour === endHour && startminute >= endMinute) {
          toast.error(
            "Invalid working hours. Start time must be before end time."
          );
          return;
        } else if (
          starhour > endHour ||
          (starhour === endHour && startminute > endMinute)
        ) {
          toast.error(
            "Invalid working hours. Start time must be before end time."
          );
          return;
        }

        const startInputTime = new Date();
        const endInputTime = new Date();

        startInputTime.setUTCHours(0, 0, 0, 0);
        endInputTime.setUTCHours(0, 0, 0, 0);

        startInputTime.setUTCHours(starhour, startminute, 0, 0);
        endInputTime.setUTCHours(endHour, endMinute, 0, 0);

        const startUtcTime = new Date(
          startInputTime.getTime() + startInputTime.getTimezoneOffset() * 60000
        );
        const endUtcTime = new Date(
          endInputTime.getTime() + endInputTime.getTimezoneOffset() * 60000
        );

        body1.working_hours[i].start_time = startUtcTime.toISOString();
        body1.working_hours[i].end_time = endUtcTime.toISOString();
      }

      if (clinicLogoPath) {
        body.clinic_image = clinicLogoPath;
        body1.clinic_image = clinicLogoPath;
      }
      if (clinicImage) {
        body.profile_image = clinicImage;
        body1.profile_image = clinicImage;
      }

      console.log(selectedFiles);

      body1.clinic_documents = selectedFiles;
      body1.insurance_they_accept = insurancess;
      body1.services = services;

      //   const encodedAddress = encodeURIComponent(body.address);
      //   const Resp1 = await makeHttpGoogleMapRequest(encodedAddress);
      //   console.log("Resp1------------->", Resp1);
      //   if (Resp1?.status == "ZERO_RESULTS") {
      //     toast.error("Please add a valid address.");
      //     return;
      //   }
      //   const longitude = Resp1?.results[0].geometry.location.lng;
      //   const latitude = Resp1?.results[0].geometry.location.lat;
      //   const location = {
      //     type: "Point",
      //     coordinates: [longitude, latitude],
      //   };

      const place = {
        formatted_address: body1?.address,
      };
      if (isValidPlace(place) === false) {
        toast.error("Please add right address.");
        return;
      }
      const location = {
        type: "Point",
        coordinates: [geoLocation?.lng, geoLocation?.lat],
      };

      body1.location = location;
      body1.modern = modernRating;
      body1.flexibility = flexibilityRating;
      body1.isHoursChanged = isWorkingHourChanged;


      //   body1.location = location;

      const resp = await makeHttpRequest("patch", `editClinic/${id}`, body1);
      console.log(resp);
      toast.success(resp?.message);
      setisWorkingHourChanged(false)
    } catch (error) {
      // toast.error('Network error.')
      // console.log(error)
    }
  };

  const validationSchema = Yup.object().shape({
    practice_name: Yup.string().required("Practice Name is required"),
    full_name: Yup.string().required("Name is required"),
    // full_name: Yup.string().required("Name is required"),
    phone_number: Yup.number()
      .required("Phone Number is required")
      .typeError("Phone Number must be a valid number")
      .positive("Phone Number must be a positive number")
      .integer("Phone Number must be an integer"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    address: Yup.string().required("Address is required"),
    bio: Yup.string().required("Bio is required"),
  });

  const formik = useFormik({
    initialValues: {
      practice_name: "",
      full_name: "",
      phone_number: "",
      email: "",
      bio: "",
      address: "",
      working_hours: [
        {
          day: "",
          start_time: "",
          end_time: "",
          enabled: true,
        },
      ],
    },
    validationSchema: validationSchema,

    onSubmit: handleSubmit,
  });

  const hanldemediaUpload = async (file, media) => {
    const formData = new FormData();
    formData.append("path", "userProfile");
    formData.append("media", file);

    try {
      const resp = await makeHttpRequest("post", "uploadAdminMedia", formData);
      console.log("resp", resp);
      if (media === "logo") {
        setCliniclogoPath(resp?.path);
      }
      if (media === "image") {
        setClinicImage(resp?.path);
      }
    } catch (error) {
      // console.log('error');
      // toast.error('Network error.')
    }
  };

  const getData = async () => {
    try {
      const resp = await makeHttpRequest("get", `getSingleBranch/${id}`);
      console.log(resp);
      const test = resp?.data?.working_hours?.map((el) => {
        // console.log("original" , el?.start_time)
        // console.log("modi" , new Date(el?.start_time).toLocaleString().split(', ')[1])
        return {
          ...el,
          start_time: new Date(el?.start_time)?.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit',hour12: false}),

          // start_time: new Date(el?.start_time)?.toLocaleString().split(", ")[1],
          //     {
          //     hour12: false,
          //     hour: '2-digit',
          //     minute: '2-digit',
          //     second: '2-digit',
          //     timeZone: 'UTC',
          // }),
          // end_time: new Date(el?.end_time)?.toLocaleString().split(", ")[1],
          end_time: new Date(el?.end_time)?.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit',hour12: false}),
          //     hour12: false,
          //     hour: '2-digit',
          //     minute: '2-digit',
          //     second: '2-digit',
          //     timeZone: 'UTC',
          // })
        };
      });
      formik.setValues({
        practice_name: resp?.data?.practice_name || "",
        full_name: resp?.data?.full_name || "",
        phone_number: resp?.data?.phone_number || "",
        email: resp?.data?.email || "",
        bio: resp?.data?.bio || "",
        address: resp?.data?.address || "",
        working_hours: test || [],
      });

      setGetServicesData(resp?.data?.services);
      setGetInsurancesData(resp?.data?.insurance_they_accept);

      const inputElement = searchBoxRefStreet.current;
      inputElement.value = resp?.data?.address || "";

      setGeoLoaction({
        lng: resp?.data?.location?.coordinates[0],
        lat: resp?.data?.location?.coordinates[1],
      });
      // if(resp?.data?.address){
      setValidLocation(true);
      // }
      setModernRating(resp?.data?.modern);
      setFlexibilityRating(resp?.data.flexibility);
      setGetClinicImage(resp?.data?.profile_image);
      setGetetClinicLogoPath(resp?.data?.clinic_image);
      setSelectedFiles(resp?.data?.clinic_documents || selectedFiles);
      console.log(resp);

      const param = {
        branch_id: id,
        sort: 1,
      };
      const Resp = await makeHttpRequest(
        "get",
        "getDentistListing",
        null,
        param
      );
      setProviderData(Resp?.data);
      console.log(Resp);

      const param1 = {
        clinic_id: id,
      };
      const Resp1 = await makeHttpRequest(
        "get",
        "getBranchListing",
        null,
        param1
      );
      console.log(Resp1);
      const branches = Resp1?.data
      const subBranches = branches?.filter(branch => branch?._id  !== id)
      setBranchData(subBranches);
    } catch (error) {
      // toast.error('Network error.');
      console.log(error);
    }
  };

  const onMapLoadStreet = (map) => {
    const searchBox = new window.google.maps.places.SearchBox(
      searchBoxRefStreet.current
    );

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places?.length === 0) {
        // No valid places found
        // console.log("Invalid address entered");
        return;
      }

      const loc = places[0].formatted_address;
      console.log(places);

      // Assuming that having at least one place means a valid address
      // You may add more validation based on your requirements
      if (isValidPlace(places[0])) {
        formik.setFieldValue("address", loc);
        setGeoLoaction({
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng(),
        });
        setValidLocation(true);
      } else {
        console.log("Invalid address entered");
        toast.error("Please enter valid address.");
        setValidLocation(false);
      }
    });
  };

  const isValidPlace = (place) => {
    // Example validation: Check if the place has a valid formatted address

    return (
      place && place.formatted_address && place.formatted_address.trim() !== ""
    );
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    if (id !== null) {
      getData();
    }
  }, [id]);

  const [services, setServices] = React.useState([]);
  const [serData, setSerData] = useState("");
  const [val, setVal] = React.useState("");
  // const [canVal, setCanVal] = useState("");
  // const [otherVal, setOtherVal] = useState("");
  const [insurancess, setInsurancess] = useState([]);
  const [insData, setInsData] = useState("");

  const getServices = (services1) => {
    setServices(services1);
    // setGetServicesData
    setGetServicesData(services1);
  };

  const getInsurance = (insurances) => {
    setInsurancess(insurances);
  };

  const insuranceHandler = (val, type) => {
    if (type === "canadian") {
      setInsData(val);
      // setCanVal(val);
    } else {
      setInsData(val);
      // setOtherVal(val);
    }
  };

  const serviceHandler = (val) => {
    // if (serData?.includes(val)) {
    //   return;
    // }
    console.log(val);
    setSerData(val);
    setVal(val);
  };

  const handleDeleteWorkField = (index) => {
    setisWorkingHourChanged(true);
    const newWorkingHours = [...formik.values.working_hours];
    newWorkingHours.splice(index, 1);
    formik.setFieldValue("working_hours", newWorkingHours);
  };

  const hanldeAddWorkField = () => {
     setisWorkingHourChanged(true);
    formik.setFieldValue("working_hours", [
      ...formik.values.working_hours,
      {
        day: "",
        start_time: "",
        end_time: "",
        enabled: true,
      },
    ]);
  };

  // function generateHoursOptions(selectedStartTime) {
  //   const options = [];
  //   for (let i = 0; i < 24; i++) {
  //     const hour = i.toString().padStart(2, '0'); 
  //     const time = `${hour}:00`; 
  //     const isDisabled = selectedStartTime && time <= selectedStartTime;
  //     options.push(
  //       <MenuItem key={i} value={time} disabled={isDisabled}>
  //         {time}
  //       </MenuItem>
  //     );
  //   }
  //   return options;
  // }





  return (
    <DashboardLayout>
      <DashboardNavbar />
      <form onSubmit={formik.handleSubmit}>
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <Card>
              <SoftBox p={3}>
                {/* ----Clinic-Details--- */}

                <SoftBox
                  mb={3}
                  component="form"
                  role="form"
                  className="appointment_form"
                >
                  <SoftTypography variant="h6">Clinic Details</SoftTypography>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Enter The Practice Name{" "}
                            <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="text"
                          placeholder="First Name"
                          {...formik.getFieldProps("practice_name")}
                        />
                        {formik.touched.practice_name &&
                        formik.errors.practice_name ? (
                          <div className="form-error">
                            {formik.errors.practice_name}
                          </div>
                        ) : null}
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Enter Your Name{" "}
                            <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="text"
                          {...formik.getFieldProps("full_name")}
                        />
                        {formik.touched.full_name && formik.errors.full_name ? (
                          <div className="form-error">
                            {formik.errors.full_name}
                          </div>
                        ) : null}
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Mobile Number <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="tel"
                          {...formik.getFieldProps("phone_number")}
                        />
                        {formik.touched.phone_number &&
                        formik.errors.phone_number ? (
                          <div className="form-error">
                            {formik.errors.phone_number}
                          </div>
                        ) : null}
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Email ID <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="email"
                          placeholder="email"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="form-error">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Upload Clinic Background Image{" "}
                            <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        {/* <SoftInput type="file" onChange={(event)=>setCliniclogo(event.target.files[0])}  placeholder="upload" /> */}
                        <SoftInput
                          type="file"
                          onChange={(event) =>
                            hanldemediaUpload(event.target.files[0], "image")
                          }
                          placeholder={
                            getClinicLogoPath ? getClinicImage : "Upload"
                          }
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Upload Clinic Logo{" "}
                            <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="file"
                          onChange={(event) =>
                            hanldemediaUpload(event.target.files[0], "logo")
                          }
                          placeholder={
                            getClinicImage ? getClinicImage : "Upload"
                          }
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Add Bio
                          </SoftTypography>
                        </SoftBox>
                        <SoftTextArea
                          placeholder={"Enter Your Bio"}
                          rows={4}
                          cols={4}
                          {...formik.getFieldProps("bio")}
                        />
                        {formik.touched.bio && formik.errors.bio ? (
                          <div className="form-error">{formik.errors.bio}</div>
                        ) : null}
                      </SoftBox>
                    </Grid>

                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Add Address <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        {/* <SoftTextArea placeholder={"Enter Your Full Address"}
                                                    rows={4}
                                                    cols={4} {...formik.getFieldProps("address")}
                                                />
                                                {formik.touched.address &&
                                                    formik.errors.address ? (
                                                    <div className="form-error">
                                                        {formik.errors.address}
                                                    </div>
                                                ) : null} */}
                        <input
                          className="form-control ad-adrs-input"
                          placeholder="Your Address"
                          name="school_address"
                          type="textarea"
                          // onChange={(e) => { handleChange(e) }}
                          onFocus={handlePopupOpen}
                          onClick={handlePopupOpen}
                          ref={searchBoxRefStreet}
                        />

                        {showPopup && (
                          <div className="map-popup">
                            <GoogleMap onLoad={onMapLoadStreet}></GoogleMap>
                          </div>
                        )}
                        {formik.touched.address && formik.errors.address ? (
                          <div className="form-error">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </SoftBox>
                    </Grid>
                    <Grid item md={12} xs={12} lg={12} xl={12}>
                      <div className="imgUploaderContainer mt-5">
                        <div {...getRootProps()} className="dropzone">
                          <input {...getInputProps()} />
                          <p>
                            Drag & drop images here, or click to select up to 6
                          </p>
                        </div>
                      </div>
                      {selectedFiles.length > 0 && (
                        <div className="image-preview">
                          <div className="image-list">
                            {selectedFiles.map((file, index) => (
                              <div key={index} className="image-item">
                                {/* <img
                                                                        src={URL.createObjectURL(file)}
                                                                        alt={`Preview ${index + 1}`}
                                                                        style={{ maxWidth: '100%', maxHeight: '150px' }}
                                                                    /> */}
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}clinicDocument/${file}`}
                                  alt={`Preview ${index + 1}`}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "150px",
                                  }}
                                />
                                <button
                                  className="close"
                                  type="button"
                                  onClick={() => removeImage(index)}
                                >
                                  x
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <SoftBox mb="3" className="clinic_ser_&_insur">
                    <Grid container spacing={4}>
                      <Grid item md={6} lg={6} xl={6}>
                        <SoftTypography variant="h6" mb={2}>
                          Add Services
                        </SoftTypography>
                        <SoftBox className="add_services_sec">
                          <Services
                            setServicesToParent={getServices}
                            serData={serData}
                            getServicesData={getServicesData}
                            isDisabledInput= {true}
                          />
                          <div
                            className="selectWrpSm"
                            style={{ width: "100%", margin: "1rem 0" }}
                          >
                            <SuiSelect
                              className="filter selctServc mb-4"
                              placeholder="Select Services"
                              options={[
                                { value: "Fillings", label: "Fillings" },
                                { value: "Crowns", label: "Crowns" },
                                { value: "Root Canal", label: "Root Canal" },
                                // {
                                //   value: "Wisdom teeth Removal",
                                //   label: "Wisdom teeth Removal",
                                // },
                                {
                                  value: "Teeth Whitening",
                                  label: "Teeth Whitening",
                                },
                                {
                                  value: "Dental Bridge",
                                  label: "Dental Bridge",
                                },
                                // {
                                //   value: "Tooth Extraction",
                                //   label: "Tooth Extraction",
                                // },
                                {
                                  value: "Checkup & Cleanings",
                                  label: "Checkup & Cleanings",
                                },
                                // {
                                //   value: "Dental Bonding",
                                //   label: "Dental Bonding",
                                // },
                                {
                                 value: "Braces",
                                 label: "Braces",
                                 },
                                {
                                  value: "Wisdom tooth Extraction",
                                  label: "Wisdom tooth Extraction",
                                },
                                {
                                  value: "Veneers",
                                  label: "Veneers",
                                },
                                {
                                  value: "Invisalign",
                                  label: "Invisalign",
                                },
                                {
                                  value: "Dental Implants",
                                  label: "Dental Implants",
                                },
                                {
                                  value: "Composite Bonding",
                                  label: "Composite Bonding",
                                },
                                {
                                  value: "Mouth Guards",
                                  label: "Mouth Guards",
                                },
                                {
                                  value: "Dentures",
                                  label: "Dentures",
                                },
                              ]}
                              value={val || "Fillings"}
                              onChange={serviceHandler}
                            />
                          </div>
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} lg={6} xl={6}>
                        <SoftTypography variant="h6" mb={2}>
                          Add Insurance
                        </SoftTypography>
                        <SoftBox className="add_insurances">
                          <InsuranceList
                            setServicesToParent={getInsurance}
                            serData={insData}
                            getInsurancesData={getInsurancesData}
                          />
                          <SoftBox display="flex" alignItems="center" gap={1}>
                            <div
                              className="selectWrpSm"
                              style={{ width: "100%", margin: "1rem 0" }}
                            >
                              <SuiSelect
                                className="filter selctServc mb-4"
                                placeholder="Select Services"
                                options={[
                                  {
                                    value: "Canadian Insurances",
                                    label: "Canadian Insurances",
                                    disabled: true,
                                  },

                                  {
                                    value: "Manulife Financial",
                                    label: "Manulife Financial",
                                  },
                                  {
                                    value: "Sunlife Financial",
                                    label: "Sunlife Financial",
                                  },
                                  {
                                    value: "Canada Life",
                                    label: "Canada Life",
                                  },
                                  {
                                    value: "Blue Cross",
                                    label: "Blue Cross",
                                  },
                                  {
                                    value: "Desjardins",
                                    label: "Desjardins",
                                  },
                                  {
                                    value: "Green Shield",
                                    label: "Green Shield",
                                  },
                                  {
                                   value: "CDCP",
                                  label: "CDCP",
                                },
                                ]}
                                // value={canVal || "Canada Life"}
                                defaultValue="Canadian Insurances"
                                onChange={(selected) =>
                                  insuranceHandler(selected, "canadian")
                                }
                              />
                            </div>
                            <div
                              className="selectWrpSm 123"
                              style={{ width: "100%", margin: "1rem 0" }}
                            >
                              <SuiSelect
                                className="filter selctServc mb-4 "
                                placeholder="Select Services"
                                options={[
                                  {
                                    value: "Others",
                                    label: "Others",
                                    disabled: true,
                                  },

                                  { value: "Anthem", label: "Anthem" },
                                  { value: "Humana", label: "Humana" },
                                  {
                                    value: "Ameritas",
                                    label: "Ameritas",
                                  },
                                  {
                                    value: "Aetna",
                                    label: "Aetna",
                                  },
                                  {
                                    value: "Cigna",
                                    label: "Cigna",
                                  },
                                  {
                                    value: "Delta Dental",
                                    label: "Delta Dental",
                                  },
                                  {
                                    value: "Guardian",
                                    label: "Guardian",
                                  },
                                  {
                                    value: "Medicaid",
                                    label: "Medicaid",
                                  },
                                  {
                                    value: "Metlife",
                                    label: "Metlife",
                                  },
                                  {
                                    value: "United Health care",
                                    label: "United Health care",
                                  },
                                ]}
                                // value={otherVal}
                                defaultValue="Others"
                                onChange={(selected) =>
                                  insuranceHandler(selected, "other")
                                }
                              />
                            </div>
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <Grid item md={6} xs={12} lg={6} xl={6} style={{ flex: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {" "}
                        {/* Adjust the xs value as needed */}
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Modern <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SuiSelect
                            className="filter selctServc mb-4"
                            placeholder="Select Services"
                            options={[
                              { value: "high", label: "High" },
                              { value: "medium", label: "Medium" },
                              { value: "low", label: "Low" },
                            ]}
                            value={modernRating}
                            onChange={(event) => setModernRating(event)}
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        {/* Adjust the xs value as needed */}
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Flexibility <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SuiSelect
                            className="filter selctServc mb-4"
                            placeholder="Select Services"
                            options={[
                              { value: "high", label: "High" },
                              { value: "medium", label: "Medium" },
                              { value: "low", label: "Low" },
                            ]}
                            value={flexibilityRating}
                            onChange={(event) => setFlexibilityRating(event)}
                          />
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </Grid>
                  <SoftBox
                    mt={3}
                    component="form"
                    role="form"
                    className="working_hours"
                  >
                    <SoftBox
                      display="flex"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">
                        Add Working Hours Details
                      </SoftTypography>
                      <SoftButton
                        variant="contained"
                        size="small"
                        onClick={hanldeAddWorkField}
                        color="info"
                      >
                        Add New
                      </SoftButton>
                    </SoftBox>
                    {formik.values.working_hours?.map((obj, index) => (
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} lg={4} xl={4}>
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Select Day{" "}
                                <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <SuiSelect
                              className="filter"
                              placeholder="Select Day"
                              options={[
                                { value: "Monday", label: "Monday" },
                                { value: "Tuesday", label: "Tuesday" },
                                { value: "Wednesday", label: "Wednesday" },
                                { value: "Thursday", label: "Thursday" },
                                { value: "Friday", label: "Friday" },
                                { value: "Saturday", label: "Saturday" },
                                { value: "Sunday", label: "Sunday" },
                                // ... other providers
                              ]}
                              defaultValue="Monday"
                              value={formik.values.working_hours[index]?.day}
                              onChange={(selectedValue) => {
                                // Update the specific day value in the array
                                if (
                                  !formik?.values?.working_hours?.some(
                                    (wh) => wh?.day === selectedValue
                                  )
                                ) {
                                  const newWorkingHours = [
                                    ...formik.values.working_hours,
                                  ];
                                  newWorkingHours[index] = {
                                    ...newWorkingHours[index],
                                    day: selectedValue,
                                  };
                                  formik.setFieldValue(
                                    "working_hours",
                                    newWorkingHours
                                  );
                                } else {
                                  toast.error("Already exist.");
                                }
                              }}
                            />
                          </SoftBox>
                        </Grid>
                        <Grid item md={2} xs={12} lg={2} xl={2}>
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                From <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <Select
                              {...formik.getFieldProps(
                                `working_hours[${index}].start_time`
                              )}
                                  onChange={(event) => { 
                                setisWorkingHourChanged(true);
                             formik.setFieldValue(`working_hours[${index}].start_time`, event.target.value);
                                }}
                            
                            >
                             {generateHoursOptions()}
                           </Select>
                            {/* <SoftInput
                              type="time"
                              // name="start_time" // Make sure to include the name attribute
                              {...formik.getFieldProps(
                                `working_hours[${index}].start_time`
                              )}
                              format="HH:mm"
                            /> */}
                          
                          </SoftBox>
                        </Grid>
                        <Grid item md={2} xs={12} lg={2} xl={2}>
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                To <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <Select
                              {...formik.getFieldProps(
                                `working_hours[${index}].end_time`
                              )}
                             onChange={(event) => { 
                                setisWorkingHourChanged(true);
                             formik.setFieldValue(`working_hours[${index}].end_time`, event.target.value);
                                }}
                            >
                             {generateHoursOptions(formik.values.working_hours[index].start_time)}
                           </Select>
                            {/* <SoftInput
                              type="time"
                              {...formik.getFieldProps(
                                `working_hours[${index}].end_time`
                              )}
                              format="HH:mm"
                            /> */}
                          </SoftBox>
                        </Grid>
                        <Grid item md={1} xs={12} lg={1} xl={1}>
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Enable <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <Switch
                              id={index}
                              checked={
                                formik.values.working_hours[index]?.enabled ||
                                false
                              }
                              onChange={(e) => {
                                const newWorkingHours = [
                                  ...formik.values.working_hours,
                                ];
                                newWorkingHours[index] = {
                                  ...newWorkingHours[index],
                                  enabled: e.target.checked,
                                };
                                formik.setFieldValue(
                                  "working_hours",
                                  newWorkingHours
                                );
                              }}
                            />
                          </SoftBox>
                        </Grid>
                        {index != 0 ? (
                          <Grid item md={2} xs={12} lg={2} xl={2}>
                            <SoftBox className="icon-cntner">
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Clear
                                </SoftTypography>
                              </SoftBox>
                              <IoMdCloseCircle
                                onClick={() => handleDeleteWorkField(index)}
                              />
                            </SoftBox>
                          </Grid>
                        ) : null}
                      </Grid>
                    ))}
                    {/* <Grid container spacing={2}>
                                        <Grid item md={4} xs={12} lg={4} >
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Select Day <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SuiSelect className="filter"
                                                    placeholder="Select Day"
                                                    options={[
                                                        { value: "Monday", label: "Monday" },
                                                        { value: "Tuesday", label: "Tuesday" },
                                                        { value: "Wednesday", label: "Wednesday" },
                                                        { value: "Thrusday", label: "Thrusday" },
                                                        { value: "Friday", label: "Friday" },
                                                        { value: "Saturday", label: "Saturday" },
                                                        { value: "Sunday", label: "Sunday" },
                                                        // ... other providers
                                                    ]}
                                                    defaultValue="Monday"
                                                    onChange={(selectedValue) => {
                                                        console.log("Selected Monday:", selectedValue);
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={2} xs={12} lg={3} >
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        From <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="time" />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={2} xs={12} lg={3} >
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        To <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="time" />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={1} xs={12} lg={1} >
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Enable <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Switch
                                                    checked={checked}
                                                    onChange={handleChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={2} xs={12} lg={1} >
                                            <SoftBox className="icon-cntner">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Clear
                                                    </SoftTypography>
                                                </SoftBox>
                                                <IoMdCloseCircle />
                                            </SoftBox>
                                        </Grid>

                                    </Grid> */}
                    {/* <Grid container spacing={2}>
                                        <Grid item md={4} xs={12} lg={4} >
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Select Day <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SuiSelect className="filter"
                                                    placeholder="Select Day"
                                                    options={[
                                                        { value: "Monday", label: "Monday" },
                                                        { value: "Tuesday", label: "Tuesday" },
                                                        { value: "Wednesday", label: "Wednesday" },
                                                        { value: "Thrusday", label: "Thrusday" },
                                                        { value: "Friday", label: "Friday" },
                                                        { value: "Saturday", label: "Saturday" },
                                                        { value: "Sunday", label: "Sunday" },
                                                        // ... other providers
                                                    ]}
                                                    defaultValue="Monday"
                                                    onChange={(selectedValue) => {
                                                        console.log("Selected Monday:", selectedValue);
                                                    }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={2} xs={12} lg={3} >
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        From <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="time" value="Brack" placeholder="Last Name" />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={2} xs={12} lg={3} >
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        To <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="time" value="+91812454412" placeholder="Number" />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={1} xs={12} lg={1} >
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Enable <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Switch
                                                    checked={checked}
                                                    onChange={handleChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={2} xs={12} lg={1} >
                                            <SoftBox className="icon-cntner">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Clear
                                                    </SoftTypography>
                                                </SoftBox>
                                                <IoMdCloseCircle />
                                            </SoftBox>
                                        </Grid>

                                    </Grid> */}
                  </SoftBox>
                  {/* <SoftBox mt={3} display="flex" spacing={2} alignItems="center" justifyContent="space-between">
                                        <SoftTypography variant="h6">Add Branch Details</SoftTypography>
                                        <SoftButton variant="contained" size="small" color="info">Add New</SoftButton>
                                    </SoftBox> */}

                  <SoftBox mt={3} className="provider_details">
                    <SoftTypography mb={2} variant="h6">
                      Edit Dentist{" "}
                    </SoftTypography>

                    <SoftBox
                      display="flex"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">
                        {/* Add Working Hours Details */}
                      </SoftTypography>
                      <SoftButton
                        variant="contained"
                        size="small"
                        onClick={handleClickOpen}
                        color="info"
                      >
                        Add New Dentist
                      </SoftButton>
                    </SoftBox>

                    <SoftBox py={2}>
                      <Grid container spacing={3}>
                        {providerData.map((val) => (
                          <Grid item xs={12} md={6} xl={3}>
                            <DefaultProjectCard2
                              image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.profile_image}`}
                              label="Dentist"
                              title={val?.full_name}
                              description={val?.bio}
                              // action={null}
                            
                              action={{
                                      type: "internal",
                                      route: `/providerProfile/${val?._id}`,
                                      color: "info",
                                      label: "View Profile",
                                                        }}
                              providerId={val?._id}
                              setProviderData={setProviderData}
                              status={val?.status == "active" ? true : false}
                              getServicesData1={getServicesData}
                            />
                            {/* {console.log(val, "-------diojvfuhfjud")} */}
                          </Grid>
                        ))}
                        {/* <Grid item xs={12} md={6} xl={3}>
                                                    <DefaultProjectCard2
                                                        image={Doctor02}
                                                        label="Dentist"
                                                        title="Mark Hay Smith"
                                                        description="Music is something that every person has his or her own specific opinion about."
                                                        action={{
                                                            type: "internal",
                                                            route: "/pages/profile/profile-overview",
                                                            color: "info",
                                                            label: "View Profile",
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} xl={3}>
                                                    <DefaultProjectCard2
                                                        image={Doctor03}
                                                        label="Dentist"
                                                        title="Dr.Smith Bruklin"
                                                        description="Different people have different taste, and various types of music."
                                                        action={{
                                                            type: "internal",
                                                            route: "/pages/profile/profile-overview",
                                                            color: "info",
                                                            label: "View Profile",
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6} xl={3}>
                                                    <DefaultProjectCard2
                                                        image={Doctor02}
                                                        label="Dentist"
                                                        title="Cristina Groves"
                                                        description="Music is something that every person has his or her own specific opinion about."
                                                        action={{
                                                            type: "internal",
                                                            route: "/pages/profile/profile-overview",
                                                            color: "info",
                                                            label: "View Profile",
                                                        }}
                                                    />
                                                </Grid> */}
                      </Grid>
                    </SoftBox>
                  </SoftBox>

                  {/* --edit-Branches-- */}

                  <SoftBox my={3} className="provider_details">
                    <SoftTypography mb={3} variant="h6">
                      Edit Location Grid
                    </SoftTypography>

                    <SoftBox
                      display="flex"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">
                        {/* Add Working Hours Details */}
                      </SoftTypography>
                      <SoftButton
                        variant="contained"
                        size="small"
                        onClick={handleClickOpen2}
                        color="info"
                      >
                        Add New Location
                      </SoftButton>
                    </SoftBox>

                    <SoftBox>
                      <Card>
                        <SoftBox py={2}>
                          <Grid container spacing={3}>
                            {branchData.map((val) => (
                              <Grid item xs={12} md={6} xl={3}>
                                <DefaultProjectCard3
                                  image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.clinic_image}`}
                                  label={`Location #${val?.branch_number}`}
                                  title={val?.practice_name}
                                  description={val?.bio}
                                  action={{
                                    type: "internal",
                                    route: `/viewBranch/${val?._id}`,
                                    color: "info",
                                    label: "view branch",
                                  }}
                                  authors={[
                                    { image: team1, name: "Elena Morison" },
                                    { image: team2, name: "Ryan Milly" },
                                    { image: team3, name: "Nick Daniel" },
                                    { image: team4, name: "Peterson" },
                                  ]}
                                  id={val?._id}
                                  status={
                                    val?.status == "active" ? true : false
                                  }
                                />
                              </Grid>
                            ))}
                            {/* <Grid item xs={12} md={6} xl={3}>
                                                            <DefaultProjectCard3
                                                                image={branch01}
                                                                label="Branch #1"
                                                                title="Sunshine Dental"
                                                                description="Music is something that every person has his or her own specific opinion about."
                                                                action={{
                                                                    type: "internal",
                                                                    route: "/viewBranch",
                                                                    color: "info",
                                                                    label: "view branch",
                                                                }}
                                                                authors={[

                                                                    { image: team1, name: "Elena Morison" },
                                                                    { image: team2, name: "Ryan Milly" },
                                                                    { image: team3, name: "Nick Daniel" },
                                                                    { image: team4, name: "Peterson" },

                                                                ]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} xl={3}>
                                                            <DefaultProjectCard3
                                                                image={branch03}
                                                                label="Branch #3"
                                                                title="	Studio Smile"
                                                                description="Different people have different taste, and various types of music."
                                                                action={{
                                                                    type: "internal",
                                                                    route: "/viewBranch",
                                                                    color: "info",
                                                                    label: "view branch",
                                                                }}
                                                                authors={[
                                                                    { image: team1, name: "Elena Morison" },
                                                                    { image: team2, name: "Ryan Milly" },
                                                                    { image: team3, name: "Nick Daniel" },
                                                                    { image: team4, name: "Peterson" },

                                                                ]}
                                                            />
                                                        </Grid> */}
                          </Grid>
                        </SoftBox>
                      </Card>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                {/* {open1 && (
                  
                )} */}
                {
                  open1 && 
                  <DialogProviders
                  handleClose1={handleClose1}
                  open1={open1}
                  clinicId={id}
                  getProviderList={getData}
                  key={id}
                  branchId={id}
                />

                }
               

                {/* <DialogProviders handleClose1={handleClose1} open1={open1} /> */}
                {/* <DialogBranches handleClose2={handleClose2} open2={open2} /> */}
              {
                open2 && 
                <DialogBranches
                  handleClose2={handleClose2}
                  open2={open2}
                  id={id}
                  setBranchData={setBranchData}
                  getBranchList={id && getData}
                  key={id}
                />
              }
                
                <SoftBox
                  display="flex"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <SoftButton
                    variant="contained"
                    size="small"
                    type="submit"
                    color="info"
                    //   onClick={() => navigate('/clinicManagement')}
                  >
                    Finish
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </Card>
          </SoftBox>
        </SoftBox>
      </form>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
