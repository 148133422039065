

// @mui material components
// import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import UserTableData from "./data/UserTableData";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import { Card, Box, TextField, Button, Typography, IconButton, InputAdornment, Grid } from '@mui/material';
import { Edit, Star, StarBorder } from '@mui/icons-material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useState } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import SoftInput from "components/SoftInput";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));


const EditReview = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }

  }

  const handleRatingChange = (index) => {
    setRating(index + 1);
  };

  const handleSubmit = () => {
    console.log({
      name,
      image,
      rating,
      review,
    });
    // Add your form submission logic here
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Edit Review</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Card style={{ padding: 20, margin: '0 auto' }}>

                <Box component="form" noValidate autoComplete="off">

                  <Grid container spacing={2}>

                    <Grid item xs={6} md={4}>
                      <Item>{/* Image Upload */}

                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            upload image of the user{" "}
                            <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <Box display="flex" alignItems="center" marginY={2}>

                          <label htmlFor="upload-image">
                            <InputAdornment position="start">
                              <IconButton color="primary" component="span">
                                <Edit />
                              </IconButton>
                            </InputAdornment>
                          </label>
                          <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-image"
                            type="file"
                            onChange={handleImageUpload}
                          />
                          {image && (
                            <img
                              src={image}
                              alt="Uploaded User"
                              style={{ width: 80, height: 80, marginLeft: 16, objectFit: 'cover' }}
                            />
                          )}
                        </Box></Item>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <Item>
                        {/* User Name Input */}

                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Enter the name of the user on behalf of whom you are entering the
                            review{" "}
                            <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          fullWidth
                          type="text"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />



                        {/* Star Rating */}
                        <Box display="flex" alignItems="center" marginY={2}>
                          <SoftTypography variant="h6">Rating: </SoftTypography>
                          <Box display="flex" marginLeft={2}>
                            {[...Array(5)].map((_, index) => (
                              <IconButton
                                key={index}
                                onClick={() => handleRatingChange(index)}
                                color="primary"
                                aria-label={`star-${index}`}
                              >
                                {index < rating ? <Star /> : <StarBorder />}
                              </IconButton>
                            ))}
                          </Box>
                        </Box>

                        {/* Review Text */}
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Enter review (optional)
                            {/* <span className="text-danger">*</span> */}
                          </SoftTypography>
                        </SoftBox>
                        <SoftTextArea
                          label="Review"
                          fullWidth
                          multiline
                          rows={4}
                          margin="normal"
                          value={review}
                          onChange={(e) => setReview(e.target.value)}
                        />
                      </Item>
                    </Grid>
                  </Grid>


                  {/* Submit Button */}
                  <SoftButton
                    variant="contained"
                    color="info"
                    style={{ marginTop: 20 }}
                    onClick={handleSubmit}
                  >
                    Submit Review
                  </SoftButton>
                </Box>
              </Card>
            </SoftBox>
          </Card>
        </SoftBox>

      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  )
}

export default EditReview;



