

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from 'react-toastify';


function ForgotPassword() {
    const [agreement, setAgremment] = useState(true);
    const [email, setemail] = useState('');


    // const handleSetAgremment = () => setAgremment(!agreement);

    const sendMail = async (e) => {

        e.preventDefault();

        try {

            if (email == '') {
                toast.error('Please enter email');
                return;
            }

            const data = {
                email: email,
                is_development: false
            }

            const resp = await makeHttpRequest('post', 'sendForgotPasswordEmail', data);
            setemail('');
            toast.success('A reset password link has been sent to your email. Please check your inbox.')
            console.log(resp);
        } catch (e) {
            toast.error('Failed to send reset password link. Please try again later.');
        }
    }

    return (
        <BasicLayout
            title="Forgot Password?"
            image={curved6}
        >
            <Card>
                <SoftBox p={3} mb={1} textAlign="center">
                    <SoftTypography variant="h5" fontWeight="medium">
                        Enter your email address below to reset your password
                    </SoftTypography>
                </SoftBox>
                <SoftBox pt={2} pb={3} px={3}>
                    <SoftBox component="form" role="form">

                        <SoftBox>
                            {/* <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    Email <span className='text-danger'>*</span>
                                </SoftTypography>
                            </SoftBox> */}
                            <SoftInput type="Email" placeholder="Email" value={email} onChange={(e) => { setemail(e.target.value) }} />
                        </SoftBox>

                        <SoftBox mt={3} textAlign="center">
                            <SoftButton type="contained" fullWidth color="info" onClick={sendMail}>SENT RESET LINK</SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </Card>
        </BasicLayout>
    );
}

export default ForgotPassword;
