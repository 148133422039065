import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";

import user01 from "assets/images/team-3.jpg";
import { FaCloudUploadAlt, FaEdit } from "react-icons/fa";
import SoftInput from "components/SoftInput";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";

import { FaSearchPlus } from "react-icons/fa";
import DefaultInfoCard from "components/DefaultInfoCard";
import DefaultInfoCard1 from "components/DefaultInfoCard1"
import SoftButton from "components/SoftButton";
import Table from "examples/Tables/Table";
import DeleteModal from "components/DeleteModal/DeleteModal";
import user02 from "assets/images/team-1.jpg";
import user03 from "assets/images/team-2.jpg";

import DialogBoxFAQ from "components/DialogBox/DialogBoxFAQ";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import AddIcon from "@mui/icons-material/Add";
import { IoIosAddCircle } from "react-icons/io";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DialogActions from "@mui/material/DialogActions";
import { TiTimes } from "react-icons/ti";
import { FaMinusCircle } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { MdEditSquare } from "react-icons/md";
import * as Yup from "yup";
export default function HomeManagement() {
  const [open, setOpen] = React.useState(false);
  const [id, setID] = useState("");

  const [getFirstImg, setGetFirstImg] = useState(null);
  const [firstSelectdImg, setFirstSelectdImg] = useState(null);
  const [firstUrlImg, setFirstUrlImg] = useState(null);

  const [getLastImg, setGetLastImg] = useState(null);
  const [lastSelectdImg, setLastSelectdImg] = useState(null);
  const [lastUrlImg, setLastUrlImg] = useState(null);
  const [updateButton, setUpdateButton] = useState(false);
  const [boxId, setBoxId] = useState("");
  const [testimonialImg, setTestimonialImg] = useState("");

  const [editTestimonial , setEditTestimonial] = useState({})
  console.log("🚀 ~ HomeManagement ~ editTestimonial:", editTestimonial)

  const [cardData, setCardData] = useState([
    {
      icon: "",
      title: "",
      description: "",
    },
    {
      icon: "",
      title: "",
      description: "",
    },
    {
      icon: "",
      title: "",
      description: "",
    },
  ]);

  const [cardData1, setCardData1] = useState([
    {
      image: "",
      question: "",
      answer: "",
    },
    {
      image: "",
      question: "",
      answer: "",
    },
    {
      image: "",
      question: "",
      answer: "",
    },
  ]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  // const [showEditTestimonial , setShowEditTestimonial] = useState(false);
  // const handleShowEditTestimonial = () => setShowEditTestimonial(true);
  // const handleCloseEditTestimonial = () => setShowEditTestimonial(false);

  const validationSchema = Yup.object().shape({
    image: Yup.string().required("Image is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik1 = useFormik({
    initialValues: {
      image: "",
      description: "",
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: addTestimonial,
  });

  const formik = useFormik({
    initialValues: {
      first_title: "",
      description: "",
      second_title: "",
      second_description: "",
      third_title: "",
      third_description: "",
      fourth_title: "",
      fourth_description: "",
      patient_testimonials: {
        title: "",
        description: "",
        testimonials: [],
      },
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingRow, setDeletingRow] = useState(null);
  const [userTableData, setUserTableData] = useState({
    columns: [
      { name: "Image", align: "left" },
      { name: "questions", align: "left" },
      { name: "answer", align: "left" },
      { name: "action", align: "center" },
    ],
    rows: [],
  });

  const handleDeleteClick = (rowData) => {
    setDeletingRow(rowData);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    console.log(deletingRow);

    try {
      const resp = await makeHttpRequest(
        "delete",
        `deleteFaq/${deletingRow?._id}`
      );
      const Resp = await makeHttpRequest("get", "getFaqs", null, {
        type: "home",
        sort: 1,
      });
      console.log(Resp?.item);

      setUserTableData({
        ...userTableData, // Copy the existing state properties
        rows: Resp?.item.map((val) => ({
          Image: (
            <SoftBox>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${val?.image}`}
                width={100}
                height={100}
                style={{ objectFit: "cover" }}
                alt="user"
              />
            </SoftBox>
          ),
          questions: (
            <SoftTypography className="Question data" variant="h6">
              {val?.question}
            </SoftTypography>
          ),
          answer: (
            <SoftTypography className="Answer data" variant="h6">
              {val?.answer}
            </SoftTypography>
          ),
          action: (
            <SoftBox>
              <SoftTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                marginRight="10px"
                //   onClick={handleOpenModal}
              >
                View
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                marginRight="10px"
                onClick={() => {
                  handleClickOpen();
                  setBoxId(val?._id);
                }}
              >
                Edit
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                onClick={() => handleDeleteClick(val)}
              >
                Delete
              </SoftTypography>
            </SoftBox>
          ),
        })),
      });
      // const updatedRows = userTableData.rows.filter((row) => row !== deletingRow);
      // setUserTableData({ ...userTableData, rows: updatedRows });
      setDeleteModalOpen(false);
      toast.success("Deleted successfully");
    } catch (e) {
      toast.error("Network Error");
      console.log(e);
    }
  };

  const handleInputChange = (value) => {
    // Handle input changes as needed
    console.log("Input value:", value);
  };

  const imageHandler = (e) => {
    try {
      const allowedImageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
      const file = e.target.files[0];
      console.log(file);
      const fileName = file.name;
      const allowedExt = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;

      // Checking for image extension
      if (allowedExt.test(fileName) === false) {
        toast.info(
          `Invalid file extension. Please select a file with one of the following extensions: ${allowedImageExtensions.join(
            ", "
          )}`
        );
        return;
      }

      if (file) {
        setFirstSelectdImg(file);
        const reader = new FileReader();

        reader.onload = (event) => {
          setFirstUrlImg(event.target.result);
          console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (e) {
      toast.error("Network Error");
      console.log(e);
    }
  };

  const imageHandler1 = (e) => {
    try {
      const allowedImageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
      const file = e.target.files[0];
      console.log(file);
      const fileName = file.name;
      const allowedExt = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;

      // Checking for image extension
      if (allowedExt.test(fileName) === false) {
        toast.info(
          `Invalid file extension. Please select a file with one of the following extensions: ${allowedImageExtensions.join(
            ", "
          )}`
        );
        return;
      }
      if (file) {
        setLastSelectdImg(file);
        const reader = new FileReader();

        reader.onload = (event) => {
          setLastUrlImg(event.target.result);
          console.log(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (e) {
      toast.error("Network Error");
      console.log(e);
    }
  };

  const imageHandler2 = async (e) => {
    const file = e.target.files[0];

    try {
      const formDataObject = new FormData();
      formDataObject.append("path", "pageImages");
      formDataObject.append("media", file);
      const resp = await makeHttpRequest(
        "post",
        "uploadAdminMedia",
        formDataObject
      );
      formik1.setFieldValue("image", resp?.path);
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (
        !formik.values.first_title ||
        !formik.values.description ||
        !firstSelectdImg ||
        !formik.values.second_title ||
        !formik.values.second_description ||
        !cardData[0].icon ||
        !cardData[0].title ||
        !cardData[0].description ||
        !cardData[1].icon ||
        !cardData[1].title ||
        !cardData[1].description ||
        !cardData[2].icon ||
        !cardData[2].title ||
        !cardData[2].description ||
        !formik.values.third_title ||
        !formik.values.third_description ||
        !formik.values.fourth_title ||
        !formik.values.fourth_description ||
        !lastSelectdImg ||
        !formik.values.patient_testimonials.title ||
        !formik.values.patient_testimonials.description ||
        formik.values.patient_testimonials.testimonials.length === 0 ||
        !cardData1[0].image ||
        !cardData1[0].question ||
        !cardData1[0].answer ||
        !cardData1[1].image ||
        !cardData1[1].question ||
        !cardData1[1].answer ||
        !cardData1[2].image ||
        !cardData1[2].question ||
        !cardData1[2].answer
      ) {
        toast.info("All fields are mandatory.");
        return;
      }

      let firstImg = "";
      let secondImg = "";

      if (firstSelectdImg) {
        const formDataObject1 = new FormData();
        formDataObject1.append("path", "pageImages");
        formDataObject1.append("media", firstSelectdImg);

        const resp = await makeHttpRequest(
          "post",
          "uploadAdminMedia",
          formDataObject1
        );
        firstImg = resp?.path;
      }

      if (lastSelectdImg) {
        const formDataObject1 = new FormData();
        formDataObject1.append("path", "pageImages");
        formDataObject1.append("media", lastSelectdImg);

        const resp = await makeHttpRequest(
          "post",
          "uploadAdminMedia",
          formDataObject1
        );
        secondImg = resp.path;
      }

      const objData = {
        first_section: {
          title: formik.values.first_title,
          description: formik.values.description,
          side_image: firstImg,
        },
        second_section: {
          title: formik.values.second_title,
          description: formik.values.second_description,
        },
        card_section: [
          {
            icon: cardData[0].icon,
            title: cardData[0].title,
            description: cardData[0].description,
          },
          {
            icon: cardData[1].icon,
            title: cardData[1].title,
            description: cardData[1].description,
          },
          {
            icon: cardData[2].icon,
            title: cardData[2].title,
            description: cardData[2].description,
          },
        ],
        third_section: {
          title: formik.values.third_title,
          description: formik.values.third_description,
        },
        last_section: {
          title: formik.values.fourth_title,
          description: formik.values.fourth_description,
          side_image: secondImg,
        },
        patient_testimonials: {
          title: formik?.values?.patient_testimonials?.title,
          description: formik?.values?.patient_testimonials?.description,
          testimonials: formik?.values?.patient_testimonials?.testimonials,
        },
      };

      const resp = await makeHttpRequest("post", "addHomeContent", objData);
      console.log(resp);

      for (let index = 0; index < 3; index++) {
        
        const objData1 = {
            question: cardData1[index]?.question,
            answer: cardData1[index]?.answer,
            type: 'home',
            image: cardData1[index]?.image
    
        }
    
        const resp1 = await makeHttpRequest('post', 'addFaq', objData1);
        // console.log(resp);
        
      }

      

    // const Resp = await makeHttpRequest('get', 'getFaqs', null, { type: 'home', sort: 1 });
    // console.log(Resp?.item)
      toast.success("Added Successfully.");
    } catch (e) {
      toast.error("Network Error");
      console.log(e);
    }
  };

  const getData = async () => {
    try {
      const resp = await makeHttpRequest("get", "getHomeContent");
      console.log(resp);

      if (resp) {
        setUpdateButton(true);
      }

      setID(resp?.data?._id);

      formik.setValues({
        first_title: resp?.data?.first_section?.title || "",
        description: resp?.data?.first_section?.description || "",
        second_title: resp?.data?.second_section?.title || "",
        second_description: resp?.data?.second_section?.description || "",
        third_title: resp?.data?.third_section?.title || "",
        third_description: resp?.data?.third_section?.description || "",
        fourth_title: resp?.data?.last_section?.title || "",
        fourth_description: resp?.data?.last_section?.description || "",
        patient_testimonials: {
          title: resp?.data?.patient_testimonials?.title || "",
          description: resp?.data?.patient_testimonials?.description || "",
          testimonials: resp?.data?.patient_testimonials?.testimonials || [],
        },
      });

      setGetFirstImg(resp?.data?.first_section?.side_image || "");
      setGetLastImg(resp?.data?.last_section?.side_image || "");

      setCardData(resp?.data?.card_section || cardData);

      const Resp = await makeHttpRequest("get", "getFaqs", null, {
        type: "home",
        sort: 1,
      });
      console.log(Resp?.item);

      setUserTableData({
        ...userTableData, // Copy the existing state properties
        rows: Resp?.item.map((val) => ({
          Image: (
            <SoftBox>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${val?.image}`}
                width={100}
                height={100}
                style={{ objectFit: "cover" }}
                alt="user"
              />
            </SoftBox>
          ),
          questions: (
            <SoftTypography className="Question data" variant="h6">
              {val?.question}
            </SoftTypography>
          ),
          answer: (
            <SoftTypography className="Answer data" variant="h6">
              {val?.answer}
            </SoftTypography>
          ),
          action: (
            <SoftBox>
              {/* <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                        //   onClick={handleOpenModal}
                        >
                            View
                        </SoftTypography> */}
              <SoftTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                marginRight="10px"
                onClick={() => {
                  handleClickOpen();
                  setBoxId(val?._id);
                }}
              >
                Edit
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                onClick={() => handleDeleteClick(val)}
              >
                Delete
              </SoftTypography>
            </SoftBox>
          ),
        })),
      });

      setCardData1(Resp?.item || cardData1)
    } catch (error) {
      console.log(error);
      toast.error("Network Error.");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const updateHandler = async (e) => {
    e.preventDefault();
    try {
      const firstIMG = firstSelectdImg ? firstSelectdImg : getFirstImg;
      const lastIMG = lastSelectdImg ? lastSelectdImg : getLastImg;

      if (
        !formik.values.first_title ||
        !formik.values.description ||
        !firstIMG ||
        !formik.values.second_title ||
        !formik.values.second_description ||
        !cardData[0].icon ||
        !cardData[0].title ||
        !cardData[0].description ||
        !cardData[1].icon ||
        !cardData[1].title ||
        !cardData[1].description ||
        !cardData[2].icon ||
        !cardData[2].title ||
        !cardData[2].description ||
        !formik.values.third_title ||
        !formik.values.third_description ||
        !formik.values.fourth_title ||
        !formik.values.fourth_description ||
        !lastIMG ||
        !formik.values.patient_testimonials.title ||
        !formik.values.patient_testimonials.description ||
        formik.values.patient_testimonials.testimonials.length === 0  ||
        !cardData1[0].image ||
        !cardData1[0].question ||
        !cardData1[0].answer ||
        !cardData1[1].image ||
        !cardData1[1].question ||
        !cardData1[1].answer ||
        !cardData1[2].image ||
        !cardData1[2].question ||
        !cardData1[2].answer
      ) {
        toast.info("All fields are mandatory.");
        return;
      }

      let firstImg = null;
      let secondImg = null;

      if (firstSelectdImg) {
        const formDataObject = new FormData();
        formDataObject.append("path", "pageImages");
        formDataObject.append("media", firstSelectdImg);

        const resp = await makeHttpRequest(
          "post",
          "uploadAdminMultipleMedia",
          formDataObject
        );
        firstImg = resp.path[0];
      } else {
        firstImg = getFirstImg;
      }

      if (lastSelectdImg) {
        const formDataObject1 = new FormData();
        formDataObject1.append("path", "pageImages");
        formDataObject1.append("media", lastSelectdImg);

        const resp = await makeHttpRequest(
          "post",
          "uploadAdminMultipleMedia",
          formDataObject1
        );
        console.log(resp);
        secondImg = resp.path[0];
      } else {
        secondImg = getLastImg;
      }

      const objData = {
        first_section: {
          title: formik.values.first_title,
          description: formik.values.description,
          side_image: firstImg,
        },
        second_section: {
          title: formik.values.second_title,
          description: formik.values.second_description,
        },
        card_section: [
          {
            icon: cardData[0].icon,
            title: cardData[0].title,
            description: cardData[0].description,
          },
          {
            icon: cardData[1].icon,
            title: cardData[1].title,
            description: cardData[1].description,
          },
          {
            icon: cardData[2].icon,
            title: cardData[2].title,
            description: cardData[2].description,
          },
        ],
        third_section: {
          title: formik.values.third_title,
          description: formik.values.third_description,
        },
        last_section: {
          title: formik.values.fourth_title,
          description: formik.values.fourth_description,
          side_image: secondImg,
        },
        patient_testimonials: {
          title: formik?.values?.patient_testimonials?.title,
          description: formik?.values?.patient_testimonials?.description,
          testimonials: formik?.values?.patient_testimonials?.testimonials,
        },
      };

      const resp = await makeHttpRequest(
        "patch",
        `editHomeContent/${id}`,
        objData
      );
      console.log(resp);

      for (let index = 0; index < 3; index++) {
        
        const objData1 = {
            question: cardData1[index]?.question,
            answer: cardData1[index]?.answer,
            type: 'home',
            image: cardData1[index]?.image
    
        }
    
        const resp1 = await makeHttpRequest('patch', `updateFaq/${cardData1[index]?._id}`, objData1);
        // console.log(resp);
        
      }
      toast.success("Updated Successfully.");
    } catch (e) {
      toast.error("Network Error");
      console.log(e);
    }
  };

  function addTestimonial(e) {
    // e.preventDefault();
    const newTestimonial = {
      description: formik1?.values?.description || "",
      name: formik1?.values?.name || "",
      image: formik1?.values?.image || "",
      _id : formik1?.values?._id ||"",
    };

    // Get the current testimonial array (or initialize it as an empty array)
    const currentTestimonial =
      formik?.values?.patient_testimonials?.testimonials;
 
      let updatedTestimonial = [];

 if(newTestimonial?._id){
  updatedTestimonial = currentTestimonial?.map((testimonial)=> testimonial?._id === newTestimonial?._id ?  newTestimonial : testimonial)
 }else{

    updatedTestimonial = [...currentTestimonial, newTestimonial];
 }
  

    // Set the updated testimonial array in formik.values
    formik.setFieldValue(
      "patient_testimonials.testimonials",
      updatedTestimonial
    );
    handleClose1();
    formik1.setValues({
      description: "",
      name: "",
      image: "",
    });
  }

  function deleteHandler(index) {
    const newWorkingHours = [
      ...formik?.values?.patient_testimonials?.testimonials,
    ];
    newWorkingHours.splice(index, 1);
    formik.setFieldValue("patient_testimonials.testimonials", newWorkingHours);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3} pb={0} className="main-title">
              <SoftTypography variant="h6">Home</SoftTypography>
            </SoftBox>

            {/* ---Home--Page--First__section */}

            <SoftBox p={3}>
              <SoftBox className="main-title" mb={3}>
                <SoftTypography variant="h6">First Section</SoftTypography>
              </SoftBox>

              <Grid container spacing={4}>
                <Grid item md={5} xs={12} lg={5} xl={5}>
                  <SoftBox className="Image_Wrappr">
                    <SoftBox mb={3} className="Image_Wrappr_inner">
                      <SoftBox className="image-container">
                        {firstSelectdImg ? (
                          <img src={firstUrlImg} width={100} height={100} />
                        ) : getFirstImg ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${getFirstImg}`}
                            width={100}
                            height={100}
                          />
                        ) : (
                          <img
                            src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png"
                            width={100}
                            height={100}
                            alt=""
                          />
                        )}
                      </SoftBox>
                      <label htmlFor="uploadd-image">
                        <FaCloudUploadAlt />
                      </label>
                      <input
                        type="file"
                        id="uploadd-image"
                        onChange={imageHandler}
                      />
                    </SoftBox>
                  </SoftBox>
                </Grid>
                <Grid item md={7} xs={12} lg={7} xl={7}>
                  <SoftBox mb={3}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        First Title
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="Last Name"
                      name="first_title"
                      {...formik.getFieldProps("first_title")}
                    />
                  </SoftBox>

                  <SoftBox>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Description <span className="text-danger">*</span>
                      </SoftTypography>
                    </SoftBox>
                    <SoftTextArea
                      placeholder={"Add some description"}
                      rows={8}
                      cols={8}
                      name="description"
                      {...formik.getFieldProps("description")}
                    />
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>

            {/* ---Home--Page--Second__section */}
            <SoftBox p={3}>
              <SoftBox className="main-title" mb={3}>
                <SoftTypography variant="h6">Second Section</SoftTypography>
              </SoftBox>
              <SoftBox mb={1}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    First Title
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  placeholder="Last Name"
                  name="second_title"
                  {...formik.getFieldProps("second_title")}
                />
              </SoftBox>

              <SoftBox mb={3}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Description <span className="text-danger">*</span>
                  </SoftTypography>
                </SoftBox>
                <SoftTextArea
                  placeholder={"Add some description"}
                  rows={3}
                  cols={3}
                  name="second_description"
                  {...formik.getFieldProps("second_description")}
                />
              </SoftBox>

              <Grid container spacing={4}>
                <Grid item md={4} xs={4} lg={4} xl={4}>
                  <DefaultInfoCard
                    icon={<FaSearchPlus />}
                    title="Your Title"
                    description="Your Description"
                    setCardData={setCardData}
                    cardData={cardData}
                    index={0}
                  />
                </Grid>
                <Grid item md={4} xs={4} lg={4} xl={4}>
                  <DefaultInfoCard
                    icon={<FaSearchPlus />}
                    title="Your Title"
                    description="Your Description"
                    setCardData={setCardData}
                    cardData={cardData}
                    index={1}
                  />
                </Grid>
                <Grid item md={4} xs={4} lg={4} xl={4}>
                  <DefaultInfoCard
                    icon={<FaSearchPlus />}
                    title="Your Title"
                    description="Your Description"
                    setCardData={setCardData}
                    cardData={cardData}
                    index={2}
                  />
                </Grid>
              </Grid>
              {/* <Grid item md={12}>
                                <SoftBox my={2} display="flex" alignItems="end" justifyContent="end">
                                    <SoftButton variant="contained" color="info">Add New</SoftButton>
                                </SoftBox>
                            </Grid> */}
            </SoftBox>

            {/* --Home-Page-Third_section-- */}

            <SoftBox p={3}>
              <SoftBox className="main-title" mb={3}>
                <SoftTypography variant="h6">Third Section</SoftTypography>
              </SoftBox>
              <SoftBox mb={1}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    First Title
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  placeholder="Last Name"
                  name="third_title"
                  {...formik.getFieldProps("third_title")}
                />
              </SoftBox>

              <SoftBox mb={3}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <SoftTextArea
                  placeholder={"Add some description"}
                  rows={3}
                  cols={3}
                  name="third_description"
                  {...formik.getFieldProps("third_description")}
                />
              </SoftBox>

              <Grid container spacing={4}>
                <Grid item md={4} xs={4} lg={4} xl={4}>
                  <DefaultInfoCard1
                    icon={<FaSearchPlus />}
                    title="Enter Question"
                    description="Enter Answer"
                    setCardData={setCardData1}
                    cardData={cardData1}
                    index={0}
                  />
                </Grid>
                <Grid item md={4} xs={4} lg={4} xl={4}>
                  <DefaultInfoCard1
                    icon={<FaSearchPlus />}
                    title="Enter Question"
                    description="Enter Answer"
                    setCardData={setCardData1}
                    cardData={cardData1}
                    index={1}
                  />
                </Grid>
                <Grid item md={4} xs={4} lg={4} xl={4}>
                  <DefaultInfoCard1
                    icon={<FaSearchPlus />}
                    title="Enter Question"
                    description="Enter Answer"
                    setCardData={setCardData1}
                    cardData={cardData1}
                    index={2}
                  />
                </Grid>
              </Grid>

              {/* <Card className="question_cntainer">
                <SoftBox p={2}>
                  <Grid item md={4}>
                    <SoftBox
                      display="flex"
                      alignItems="end"
                      justifyContent="end"
                    >
                      <SoftButton
                        variant="contained"
                        onClick={() => handleClickOpen()}
                        color="info"
                      >
                        Add New
                      </SoftButton>
                    </SoftBox>
                  </Grid>

                  <Table
                    columns={userTableData.columns}
                    rows={userTableData.rows}
                  />
                  <DeleteModal
                    isOpen={isDeleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    onConfirm={handleDeleteConfirm}
                  />
                  <DialogBoxFAQ
                    handleClose={() => {
                      handleClose();
                      setBoxId();
                    }}
                    open={open}
                    boxId={boxId}
                    setUserTableData={setUserTableData}
                    userTableData={userTableData}
                    handleClickOpen={handleClickOpen}
                    setBoxId={setBoxId}
                    handleDeleteClick={handleDeleteClick}
                  />
                </SoftBox>
              </Card> */}
            </SoftBox>

            <SoftBox p={3}>
              <SoftBox className="main-title" mb={3}>
                <SoftTypography variant="h6">Fourth Section</SoftTypography>
              </SoftBox>

              <Grid container>
                <Grid item md={12} xs={12} lg={12} xl={12}>
                  <SoftBox mb={3}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Testimonial Title
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder=""
                      {...formik.getFieldProps("patient_testimonials.title")}
                    />
                  </SoftBox>
                  <SoftBox>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Description <span className="text-danger">*</span>
                      </SoftTypography>
                    </SoftBox>
                    <SoftTextArea
                      placeholder=""
                      rows={3}
                      cols={3}
                      {...formik.getFieldProps(
                        "patient_testimonials.description"
                      )}
                    />
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} xs={12} lg={12} xl={12} mt={3}>
                  <SoftBox display="flex" alignItems="end" justifyContent="end">
                    <SoftButton
                      variant="contained"
                      onClick={handleOpen1}
                      color="info"
                    >
                      Add New
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>

              <Grid container spacing={4} mt={3} mb={3}>
                {formik?.values?.patient_testimonials?.testimonials?.map(
                  (val, index) => (
                    <Grid item md={4} xs={4} lg={4} xl={4}>
                      <Card>
                        <SoftBox className="Image_Wrappr">
                          <SoftBox
                            mb={3}
                            className="Image_Wrappr_inner testimonial-img"
                          >
                            <SoftBox className="image-container">
                              <img
                                src={
                                  val?.image
                                    ? `${process.env.REACT_APP_IMAGE_URL}pageImages/${val?.image}`
                                    : process.env.REACT_APP_DEFAULT_IMAGE_URL
                                }
                              ></img>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox className="testimonial-content">
                            <SoftBox mb={1}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                {val?.description}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1} className="author-name">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                {val?.name}
                              </SoftTypography>
                            </SoftBox>
                         
                          </SoftBox>
                        </SoftBox>
              
 
                        <span
                          className="edtBtn"
                          onClick={() =>{ formik1.setValues(val); handleOpen1(); }} 
                        >
                          <FaEdit />{" "}
                        </span>
                        
                        <span
                          className="clsBtn"
                          onClick={() => deleteHandler(index)}
                        >
                          <FaMinusCircle />{" "}
                        </span>
                      </Card>
                    </Grid>
                  )
                )}
               
              </Grid>


            </SoftBox>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="testi-modal"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add Testimonial
                </Typography>
                <form onSubmit={formik1.handleSubmit}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <SoftBox className="Image_Wrappr">
                      <SoftBox mb={3} className="Image_Wrappr_inner">
                        <SoftBox className="image-container">
                          {formik1?.values?.image ? (
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}/pageImages/${formik1?.values?.image}`}
                              width={100}
                              height={100}
                            />
                          ) : (
                            <img
                              src={process.env.REACT_APP_DEFAULT_IMAGE_URL}
                              width={100}
                              height={100}
                              alt=""
                            />
                          )}
                        </SoftBox>
                        <label htmlFor="uploadd23-image">
                          <FaCloudUploadAlt />
                        </label>
                        <input
                          type="file"
                          id="uploadd23-image"
                          onChange={imageHandler2}
                        />
                      </SoftBox>
                    </SoftBox>
                    {formik1.touched.image && formik1.errors.image ? (
                      <div className="form-error1">{formik1.errors.image}</div>
                    ) : null}
                    <SoftBox mb={2}>
                      <SoftBox>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Description
                        </SoftTypography>
                      </SoftBox>
                      {/* <SoftTextArea
                                            placeholder=""
                                            rows={5}
                                            cols={5}
                                            name='description'
                                            {...formik1.getFieldProps('description')}
                                            /> */}
                      <textarea
                        placeholder={"Add some description"}
                        rows={5}
                        cols={5}
                        name="description"
                        {...formik1.getFieldProps("description")}
                      />
                      {formik1.touched.description &&
                      formik1.errors.description ? (
                        <div className="form-error">
                          {formik1.errors.description}
                        </div>
                      ) : null}
                    </SoftBox>
                    <SoftBox mb={1}>
                      <SoftBox>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Name
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput
                        type="text"
                        placeholder=""
                        name="name"
                        {...formik1.getFieldProps("name")}
                      />
                      {formik1.touched.name && formik1.errors.name ? (
                        <div className="form-error">{formik1.errors.name}</div>
                      ) : null}
                    </SoftBox>
                    {/* <SoftBox mb={1}>
                                        <SoftBox>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Position
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="" name='position' 
                                            {...formik1.getFieldProps('position')}/>
                                            {formik1.touched.position &&
                                            formik1.errors.position ? (
                                            <div className="form-error">
                                            {formik1.errors.position}
                                             </div>
                                            ) : null}
                                            
                                    </SoftBox> */}
                    <DialogActions>
                      <Button onClick={() => handleClose1()}>Cancel</Button>
                      <Button
                          type="submit"
                          // onClick={(e) => {

                          //     addTestimonial();

                          // }
                          // }
                          autoFocus
                        >
                          Done
                        </Button>
                      {/* {boxId ? (
                        <Button
                          onClick={(e) => {
                            handleClose1();
                          }}
                          autoFocus
                        >
                          Done
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          // onClick={(e) => {

                          //     addTestimonial();

                          // }
                          // }
                          autoFocus
                        >
                          Done
                        </Button>
                      )} */}
                    </DialogActions>
                  </Typography>
                </form>
              </Box>
            </Modal>


            <SoftBox p={3}>
              <SoftBox className="main-title" mb={3}>
                <SoftTypography variant="h6">Fifth Section</SoftTypography>
              </SoftBox>

              <Grid container spacing={4}>
                <Grid item md={7} xs={12} lg={7} xl={7}>
                  <SoftBox mb={3}>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        First Title
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="text"
                      placeholder="Last Name"
                      name="fourth_title"
                      {...formik.getFieldProps("fourth_title")}
                    />
                  </SoftBox>

                  <SoftBox>
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Description <span className="text-danger">*</span>
                      </SoftTypography>
                    </SoftBox>
                    <SoftTextArea
                      placeholder={"Add some description"}
                      rows={8}
                      cols={8}
                      name="fourth_description"
                      {...formik.getFieldProps("fourth_description")}
                    />
                  </SoftBox>
                </Grid>

                <Grid item md={5} xs={12} lg={5} xl={5}>
                  <SoftBox className="Image_Wrappr">
                    <SoftBox mb={3} className="Image_Wrappr_inner">
                      <SoftBox className="image-container">
                        {lastSelectdImg ? (
                          <img src={lastUrlImg} width={100} height={100} />
                        ) : getLastImg ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${getLastImg}`}
                            width={100}
                            height={100}
                          />
                        ) : (
                          <img
                            src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png"
                            width={100}
                            height={100}
                            alt=""
                          />
                        )}
                      </SoftBox>
                      <label htmlFor="uploadd-image1">
                        <FaCloudUploadAlt />
                      </label>
                      <input
                        type="file"
                        id="uploadd-image1"
                        onChange={imageHandler1}
                      />
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </SoftBox>
        {updateButton == false ? (
          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            pb={3}
          >
            <SoftButton
              variant="contained"
              color="info"
              onClick={submitHandler}
            >
              Save
            </SoftButton>
          </SoftBox>
        ) : (
          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            pb={3}
          >
            <SoftButton
              variant="contained"
              color="info"
              onClick={updateHandler}
            >
              Update
            </SoftButton>
          </SoftBox>
        )}
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
