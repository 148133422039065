import { MenuItem } from "@mui/material";

// export function generateHoursOptions(selectedStartTime) {
//     const options = [];
//     for (let i = 0; i < 24; i++) {
//       const hour = i.toString().padStart(2, '0'); 
//       const time = `${hour}:00`; 
//       const isDisabled = selectedStartTime && time <= selectedStartTime;
//       options.push(
//         <MenuItem key={i} value={time} disabled={isDisabled}>
//           {time}
//         </MenuItem>
//       );
//     }
//     return options;
//   }

export function generateHoursOptions(selectedStartTime) {
    const options = [];
    for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0'); 
        
        // Add full hour option
        const fullHourTime = `${hour}:00`;
        const isFullHourDisabled = selectedStartTime && fullHourTime <= selectedStartTime;
        options.push(
            <MenuItem key={`${hour}:00`} value={fullHourTime} disabled={isFullHourDisabled}>
                {fullHourTime}
            </MenuItem>
        );

        // Add half hour option
        const halfHourTime = `${hour}:30`;
        const isHalfHourDisabled = selectedStartTime && halfHourTime <= selectedStartTime;
        options.push(
            <MenuItem key={`${hour}:30`} value={halfHourTime} disabled={isHalfHourDisabled}>
                {halfHourTime}
            </MenuItem>
        );
    }
    return options;
}

  export function capitalizeFirstChar(sentence) {
  
    let capitalizedWord = sentence.charAt(0).toUpperCase() + sentence.slice(1);
    
    return capitalizedWord;
}