/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import TimelineItem from "components/Timeline/TimelineItem";
import TimelineList from "components/Timeline/TimelineList";
import SoftBadge from "components/SoftBadge";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeHttpRequest } from "utils/httpServices";

function ViewUserSearch() {
  const [data, setData] = useState();
  const { id } = useParams();

  async function getData() {
    try {
      const resp = await makeHttpRequest("get", `getQuestionire/${id}`);
      console.log(resp);
      setData(resp?.data);
    } catch (error) {}
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <SoftBox mb={3}>
        <Card>
          <SoftBox className="usrDetailsWrap" pt={2} px={2}>
            <SoftBox mb={2} className="usrSearchHeading">
              <SoftTypography variant="h6" fontWeight="medium">
                User Details
              </SoftTypography>
              {/* <span className="badge">
                Loged In
              </span> */}
              <SoftBadge
                variant="gradient"
                badgeContent="Guest"
                color={"active"}
                size="xs"
                container
              />
            </SoftBox>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={6} xl={4}>
                <SoftBox mb={2} className="detailItem">
                  <h6>
                    User Name
                  </h6>
                  <p>John Doe</p>
                </SoftBox>
              </Grid> */}
              <Grid item xs={12} md={6} xl={4}>
                <SoftBox mb={2} className="detailItem">
                  <h6>Email address</h6>
                  <p>{data?.email}</p>
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <SoftBox mb={2} className="detailItem">
                  <h6>Time</h6>
                  <p>{new Date(data?.createdAt).toLocaleString()}</p>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>

      <SoftBox mt={5} mb={3}>
        <Card>
          <SoftBox px={3} pt={3}>
            <SoftTypography variant="h6" fontWeight="medium">
              Question Answers
            </SoftTypography>
          </SoftBox>

          <SoftBox>
            <TimelineList title="User Preference">
              <TimelineItem
                color="info"
                number="1"
                title="What is the main purpose of your visit?"
                // dateTime="22 DEC 7:20 PM"
                badges={[data?.main_purpose]}
              />
              <TimelineItem
                color="success"
                number="2"
                title="What specific procedure are you looking to have?"
                // dateTime="21 DEC 11 PM"
                badges={data?.service?.map((val) => val)}
              />
              <TimelineItem
                number="3"
                title="What I'm looking for in a dentist?"
                // dateTime="21 DEC 9:34 PM"
                badges={data?.looking_in_dentist?.map((val) => val)}
              />
              <TimelineItem
                number="4"
                title="When was the last time you saw a dentist?"
                // dateTime="21 DEC 9:34 PM"
                badges={[data?.last_time]}
              />
              <TimelineItem
                number="5"
                title="What time works best for your visit?"
                // dateTime="21 DEC 9:34 PM"
                badges={data?.best_visit_time?.map((val) => val)
                  // [data?.questionires?.best_visit_time]
                }
                // {[data?.questionires?.best_visit_time]}
              />
              {/* <TimelineItem
                                                        number="6"
                                                        title="What time for your visit works best for you"
                                                        dateTime="21 DEC 9:34 PM"
                                                        badges={["Morning 8am-12pm"]}

                                                    /> */}
              <TimelineItem
                number="7"
                title="How soon would you like to receive care?"
                // dateTime="21 DEC 9:34 PM"
                badges={[data?.receive_care]}
              />
              <TimelineItem
                number="8"
                title="Do you have dental insurance?"
                // dateTime="21 DEC 9:34 PM"
                badges={[
                  data?.have_insurance
                    ? "Yes i have Insurance"
                    : "No I don't have insurance",
                ]}
              />
              {data?.have_insurance && (
                <TimelineItem
                  number="9"
                  title="Who is your dental insurance provider?"
                  // dateTime="21 DEC 9:34 PM"
                  badges={[data?.insurance]}
                  lastItem
                />
              )}
            </TimelineList>
          </SoftBox>
        </Card>
      </SoftBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ViewUserSearch;
