import { Card, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTextArea from 'components/SoftTextArea/SoftTextArea'
import SoftTypography from 'components/SoftTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect } from 'react'
import Header from 'components/Header/Banner'

import SoftButton from 'components/SoftButton'
import Footer from 'examples/Footer'
import DefaultInfoCardOne from 'components/DefaultInfoCardOne'
import { useFormik } from 'formik'
import { useState } from 'react'
import { toast } from 'react-toastify';
import { makeHttpRequest } from 'utils/httpServices'



export default function ForDentistManagement() {

    const [getBackImg, setGetBackImg] = useState(null);
    const [backSelectdImg, setBackSelectdImg] = useState(null);
    const [backUrlImg, setBackUrlImg] = useState(null);
    const [update, setUpdate] = useState(false)
    const [id, setId] = useState('');

    const [cardData, setCardData] = useState([
        {
            icon: '',
            title: '',
            description: ''
        },
        {
            icon: '',
            title: '',
            description: ''
        },
        {
            icon: '',
            title: '',
            description: ''
        }
    ]);

    const formik = useFormik({
        initialValues: {
            first_title: '',
            first_step: "",
            second_step: "",
            third_step: "",
            second_title: '',
            second_description: ""
        }
    });

    const submitHandler = async (e) => {
        e.preventDefault();
        try {

            if (!formik.values.first_title || !backSelectdImg || !formik.values.second_title
                || !formik.values.second_description || !cardData[0].title || !cardData[0].description
                || !cardData[1].title || !cardData[1].description || !cardData[2].title || !cardData[2].description
                || !formik.values.first_step || !formik.values.second_step ||
                !formik.values.third_step) {

                toast.info('All fields are mandatory.');
                return;
            }


            const formDataObject1 = new FormData();
            formDataObject1.append('path', 'pageImages');
            formDataObject1.append('media', backSelectdImg);

            const resp = await makeHttpRequest('post', 'uploadAdminMedia', formDataObject1);


            const objData = {
                first_section: {
                    title: formik.values.first_title,
                    background_image: resp?.path,
                    keyword: [
                        {
                            title: formik.values.first_step
                        },
                        {
                            title: formik.values.second_step
                        },
                        {
                            title: formik.values.third_step
                        },
                    ]
                },
                second_section: {
                    title: formik.values.second_title,
                    description: formik.values.second_description
                },
                card_section: [
                    {
                        title: cardData[0].title,
                        description: cardData[0].description
                    },
                    {
                        title: cardData[1].title,
                        description: cardData[1].description
                    },
                    {
                        title: cardData[2].title,
                        description: cardData[2].description
                    }
                ]
            }

            const Resp = await makeHttpRequest('post', 'addDentistContent', objData);
            console.log(Resp);
            toast.success('Added Successfully.')
        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }
    }

    const getData = async () => {

        try {
            const resp = await makeHttpRequest('get', 'getDentistContent');
            console.log(resp?.data)

            setUpdate(true);
            setId(resp?.data?._id);
            formik.setValues({
                first_title: resp?.data?.first_section?.title || '',
                first_step: resp?.data?.first_section?.keyword[0].title || "",
                second_step: resp?.data?.first_section?.keyword[1].title || "",
                third_step: resp?.data?.first_section?.keyword[2].title || "",
                second_title: resp?.data?.second_section?.title || '',
                second_description: resp?.data?.second_section?.description || ""
            });

            setGetBackImg(resp?.data?.first_section?.background_image || '');
            setCardData(resp?.data?.card_section || cardData);
        } catch (error) {

        }
    }

    const updateHandler = async (e) => {

        e.preventDefault();
        try {
            const firstIMG = backSelectdImg ? backSelectdImg : getBackImg;

            if (!formik.values.first_title || !firstIMG || !formik.values.second_title
                || !formik.values.second_description || !cardData[0].title || !cardData[0].description
                || !cardData[1].title || !cardData[1].description || !cardData[2].title || !cardData[2].description
                || !formik.values.first_step || !formik.values.second_step ||
                !formik.values.third_step) {

                toast.info('All fields are mandatory.');
                return;
            }


            let firstImg = null;
            // let secondImg = null;

            if (backSelectdImg) {
                const formDataObject = new FormData();
                formDataObject.append('path', 'pageImages');
                formDataObject.append('media', backSelectdImg);

                const resp = await makeHttpRequest('post', 'uploadAdminMultipleMedia', formDataObject);
                console.log(resp,'kjk')
                firstImg = resp?.path[0];
            } else {
                firstImg = getBackImg;
            }

            const objData = {
                first_section: {
                    title: formik.values.first_title,
                    background_image: firstImg,
                    keyword: [
                        {
                            title: formik.values.first_step
                        },
                        {
                            title: formik.values.second_step
                        },
                        {
                            title: formik.values.third_step
                        },
                    ]
                },
                second_section: {
                    title: formik.values.second_title,
                    description: formik.values.second_description
                },
                card_section: [
                    {
                        title: cardData[0].title,
                        description: cardData[0].description
                    },
                    {
                        title: cardData[1].title,
                        description: cardData[1].description
                    },
                    {
                        title: cardData[2].title,
                        description: cardData[2].description
                    }
                ]

            }
           

            const resp = await makeHttpRequest('patch', `editDentistContent/${id}`, objData);
            console.log(resp);
            toast.success('Updated Successfully.')
        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <DashboardLayout >
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        {/* ---Home--Page--First__section */}
                        <SoftBox p={3}>

                            <SoftBox mb={3}>
                                <SoftBox className="main-title" mb={3}>
                                    <SoftTypography variant="h6">First Section</SoftTypography>
                                </SoftBox>

                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold" >
                                        First Title
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="text" value="Find Your Best Dentist In Your City" placeholder="Last Name"
                                    name='first_title'
                                    {...formik.getFieldProps('first_title')} />
                            </SoftBox>

                            <SoftBox mb={3} display="flex" >
                                {/* <Card  >
                                    <SoftBox p={3}>
                                        <SoftTypography style={{ fontSize: "16px" }} fontWeight="bold">
                                            Form Section
                                        </SoftTypography>
                                        <SoftBox mb={3}>
                                            <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                    <SoftBox >
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                First title
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput type="text" placeholder="Email" />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <SoftBox >

                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Second Title
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput type="text" placeholder="Phone" />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <SoftBox >

                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Third Title
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput type="text" placeholder="Practice Name" />
                                                    </SoftBox>
                                                </Grid>

                                            </Grid>
                                        </SoftBox>
                                    </SoftBox>


                                </Card> */}
                                <Card  >
                                    <SoftBox p={3}>
                                        <SoftTypography style={{ fontSize: "16px" }} fontWeight="bold">
                                            Step Section
                                        </SoftTypography>
                                        <SoftBox mb={3}>
                                            <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                    <SoftBox >
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                First Step
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput type="text" placeholder="First Step" name='first_step'
                                                            {...formik.getFieldProps('first_step')} />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <SoftBox >

                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Second Step
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput type="text" placeholder="Second Step" name='second_step'
                                                            {...formik.getFieldProps('second_step')} />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <SoftBox >

                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Third Step
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput type="text" placeholder="Third Step" name='third_step'
                                                            {...formik.getFieldProps('third_step')} />
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                        </SoftBox>
                                    </SoftBox>


                                </Card>
                            </SoftBox>






                            <Header getBackImg={getBackImg} backSelectdImg={backSelectdImg} setBackSelectdImg={setBackSelectdImg} backUrlImg={backUrlImg} setBackUrlImg={setBackUrlImg} />

                        </SoftBox>

                        {/* ---Home--Page--Second__section */}
                        <SoftBox p={3}>
                            <SoftBox className="main-title" mb={3}>
                                <SoftTypography variant="h6">Second Section</SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        First Title
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="text" value="Find Your Best Dentist In Your City" placeholder="Second title" name='second_title'
                                    {...formik.getFieldProps('second_title')} />
                            </SoftBox>

                            <SoftBox mb={3}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Description <span className='text-danger'>*</span>
                                    </SoftTypography>
                                </SoftBox>
                                <SoftTextArea placeholder={"Add some description"}
                                    rows={3}
                                    cols={3} name='second_description'
                                    {...formik.getFieldProps('second_description')} />
                            </SoftBox>

                            <Grid container spacing={4}>

                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCardOne

                                        title="Your Title"
                                        description="Your Description"
                                        setCardData={setCardData}
                                        cardData={cardData}
                                        index={0}
                                    />

                                </Grid>
                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCardOne

                                        title="Your Title"
                                        description="Your Description"
                                        setCardData={setCardData}
                                        cardData={cardData}
                                        index={1}
                                    />

                                </Grid>
                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCardOne

                                        title="Your Title"
                                        description="Your Description"
                                        setCardData={setCardData}
                                        cardData={cardData}
                                        index={2}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid item md={12}>
                                <SoftBox my={2} display="flex" alignItems="end" justifyContent="end">
                                    <SoftButton variant="contained" color="info">Add New</SoftButton>
                                </SoftBox>
                            </Grid> */}
                        </SoftBox>

                    </Card>
                </SoftBox>
                <SoftBox display="flex" justifyContent="center" alignItems="center" pb={3}>
                    {update ? <SoftButton variant="contained" color="info" onClick={updateHandler}>Update</SoftButton>
                        : <SoftButton variant="contained" color="info" onClick={submitHandler}>Save</SoftButton>}
                </SoftBox>
            </SoftBox>
            {/* <Footer /> */}
        </DashboardLayout>
    )
}
