/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import SoftInput from "components/SoftInput";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";
import SoftButton from "components/SoftButton";
import ProfileInfoCard from "./components/ProfileInfoCard";
import { FormControlLabel, Switch } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";


function Overview() {
  const [selectedImg, setSelectedImg] = useState(null);
  const [urlImg, setUrlImg] = useState(null);
  const [getImg, setGetImg] = useState(null);
  const [checked, setChecked] = useState(false)

  // const [profileData, setProfileData] = useState({
  //   name: 'John Doe',
  //   email: 'john.doe@example.com',
  //   phone: '123-456-7890',
  //   address: '123 Main St, Cityville, Country',
  //   imageUrl: 'https://source.unsplash.com/random/200x200?sig=1', // Replace with the default image URL
  //   bio: 'uhfuhfusdlhdlhfdhuhbkuy'
  // });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setProfileData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleSubmit = async (values) => {
    // e.preventDefault();
    const body = { ...values };
    console.log(values)
    try {

      if (selectedImg) {
        const formData = new FormData();
        formData.append("path", "userProfile");
        formData.append("media", selectedImg);
        const resp = await makeHttpRequest("post", "uploadAdminMedia", formData);
        body.profile_img = resp?.path;
      } else {
        body.profile_img = getImg;
      }

      const resp = await makeHttpRequest('patch', 'editAdminProfile', body);
      toast.success('Added successfully.')
    } catch (error) {
      toast.error('Network error');
      console.log(error)
    }

  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter valid email.').required("Email is required"),
    phone_number: Yup.string()
      .required("Phone Number is required")
      .matches(/^[0-9]+$/, "Phone Number must only contain numbers"),
    name: Yup.string().required("Name is required."),
    address: Yup.string().required("Address is required."),
    bio: Yup.string().required("Bio is required."),
    profile_img: Yup.string().required("Profile image is required."),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      address: '',
      bio: '',
      profile_img: ''
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  })



  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setSelectedImg(file)
    formik.setFieldValue('profile_img', file)

    // // Perform image upload logic and update the state with the new image URL
    const imageUrl = URL.createObjectURL(file);
    setUrlImg(imageUrl)
  };

  const getData = async () => {
    try {

      const resp = await makeHttpRequest('get', 'getAdminProfile');
      console.log(resp);

      formik.setValues({
        name: resp?.data?.name || '',
        email: resp?.data?.email || '',
        phone_number: resp?.data?.phone_number || '',
        address: resp?.data?.address || '',
        bio: resp?.data?.bio || '',
        profile_img: resp?.data?.profile_img || ''
      })
      setGetImg(resp?.data?.profile_img || '')
      setChecked(resp?.data?.two_step_verification)
    } catch (error) {
      toast.error('Neywork error.')
    }
  }


  useEffect(() => {
    getData();
  }, [])

  const otpVerification = async (val) => {
    try {
      const resp = await makeHttpRequest('post', 'changeTwoStepSetting');
      setChecked(!checked);
      toast.success('Status changed successfully.')
    } catch (error) {
      toast.error('Network error')
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={8}>
            <Card className="edit-profile-page">
              <SoftTypography variant="h4" fontWeight="bold" >Edit Profile</SoftTypography>
              <form onSubmit={formik.handleSubmit}>
                <SoftBox className="form-group">
                  <label>Profile Image</label>
                  <SoftBox className="image-upload">
                    {selectedImg ? <img src={urlImg} /> : getImg ? <img src={`${process.env.REACT_APP_IMAGE_URL}userProfile/${getImg}`} /> :
                      <img src='https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png' alt='Upload image' />}
                    <input
                      type="file"
                      id="upload"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleImageUpload}
                    />{formik.touched.profile_img &&
                      formik.errors.profile_img ? (
                      <div className="form-error">
                        {formik.errors.profile_img}
                      </div>
                    ) : null}
                    <label className="upload-btn" htmlFor="upload"> upload</label>
                  </SoftBox>
                </SoftBox>

                <SoftBox className="form-group">
                  <label htmlFor="name">Name</label>
                  <SoftInput
                    type="text"
                    id="name"
                    name="name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name &&
                    formik.errors.name ? (
                    <div className="form-error">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </SoftBox>

                <SoftBox className="form-group">
                  <label htmlFor="email">Email</label>
                  <SoftInput
                    type="email"
                    id="email"
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email &&
                    formik.errors.email ? (
                    <div className="form-error">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </SoftBox>

                <SoftBox className="form-group">
                  <label htmlFor="bio">Bio</label>
                  <SoftTextArea
                    id="bio"
                    name="bio"
                    {...formik.getFieldProps("bio")}
                  />
                  {formik.touched.bio &&
                    formik.errors.bio ? (
                    <div className="form-error">
                      {formik.errors.bio}
                    </div>
                  ) : null}
                </SoftBox>

                <SoftBox className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <SoftInput
                    type="tel"
                    id="phone_number"
                    name="phone_number"
                    {...formik.getFieldProps("phone_number")}
                  />
                  {formik.touched.phone_number &&
                    formik.errors.phone_number ? (
                    <div className="form-error">
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                </SoftBox>

                <SoftBox className="form-group">
                  <label htmlFor="address">Address</label>
                  <SoftTextArea
                    id="address"
                    name="address"
                    {...formik.getFieldProps("address")}
                  />
                  {formik.touched.address &&
                    formik.errors.address ? (
                    <div className="form-error">
                      {formik.errors.address}
                    </div>
                  ) : null}
                </SoftBox>

                <SoftBox className="form-group">
                  <label htmlFor="address">OTP Verification</label>
                  <FormControlLabel style={{ marginLeft: '1%' }} control={<Switch checked={checked}
                    onChange={(e) => {
                      otpVerification(e.target.checked)
                    }}
                  />
                  }
                  />
                </SoftBox>

                <SoftButton type="submit" color="primary">Save Changes</SoftButton>
              </form>
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="profile information"
              description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
              info={{
                fullName: "Alec M. Thompson",
                mobile: "(44) 123 1234 123",
                email: "alecthompson@mail.com",
                location: "USA",
              }}
              social={[
                {
                  link: "https://www.facebook.com/CreativeTim/",
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: "https://twitter.com/creativetim",
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: "https://www.instagram.com/creativetimofficial/",
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
              ]}
              action={{ route: "", tooltip: "Edit Profile" }}
            />
          </Grid> */}
        </Grid>
      </SoftBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
