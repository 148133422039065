import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput'
import SoftTextArea from 'components/SoftTextArea/SoftTextArea';

function DefaultInfoCardOne({ title, description, setCardData, cardData, index }) {
  return (
    <Card>

      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <SoftBox mb={0.5}>
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SoftTypography>
          <SoftInput type="text"  value={cardData[index]?.title || ''} name='title' onChange={(e) => {
              const updatedCardData = [...cardData];
              updatedCardData[index] = { ...updatedCardData[index], title: e.target.value };
              setCardData(updatedCardData);
            }}/>
        </SoftBox>

        <SoftBox mb={3}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              {description}
            </SoftTypography>
          </SoftBox>
          <SoftTextArea placeholder={"Add some description"}
            rows={3}
            cols={3} name='description' value={cardData[index]?.description || ''}

            onChange={(e) => {
              const updatedCardData = [...cardData];
              updatedCardData[index] = { ...updatedCardData[index], description: e.target.value };
              setCardData(updatedCardData);
            }}/>
        </SoftBox>


      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCardOne.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCardOne.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};



export default DefaultInfoCardOne;
