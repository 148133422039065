

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";


// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { makeHttpRequest } from 'utils/httpServices'

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { red } from "@mui/material/colors";
import { Box, Button } from "@mui/material";


const counterStyle = {
  color: 'white',
  fontSize: '15px',
  fontWeight: 'bold',
  background: '#344767',
  width: '20px',
  height: '20px',
  position: 'absolute',
  top: '-12px',
  borderRadius: '6px',
  right: '-6px',
  display: 'grid',
  placeContent: 'center',
}

const customBtn = {
  background: '#dbdbdb',
  padding: '10px',
  borderRadius: '6px',
  textAlign: 'center'

}


function DashboardNavbar({ absolute, light, isMini }) {
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [notifications, setNotifications] = useState([])
  const route = useLocation().pathname.split("/").slice(1,2);
  const [countOfNotifications, setCountOfNotification] = useState(0)

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
 
 
  const handleOpenMenu = async(event) => {
    setOpenMenu(event.currentTarget)
    try {
      const resp = await makeHttpRequest('post', 'seenNotification');
      getCountNotifications();
      getNotifications();
     
    } catch (error) {
      
    }
  };


  const handleCloseMenu = () => setOpenMenu(false);

  const handleCloseMenu1 = (id, type)=>{
    if(type === 'appointment'){
      navigate(`/viewAppointment/${id}`);
      setOpenMenu(false);
    }
    else if(type === 'user'){
      navigate(`/viewUserManagement/${id}`);
      setOpenMenu(false);
    }
    else if(type === 'enquiry'){
      navigate(`/enquiryManagement`);
      setOpenMenu(false);
    }
  }

  // Render the notifications menu
  function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  const timeSetter = (time)=>{

    var aDay = new Date(time);
    return (timeSince(aDay));
  }
  
  const renderMenu = () => (
    <>
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}


      >
        <Box style={{ maxHeight: "220px", height: '220xpx' }}>
          {notifications?.map((val)=>(<NotificationItem
            image={<img src={val?.sender_details?.profile_image ? `${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.sender_details?.profile_image}`:
          process.env.REACT_APP_DEFAULT_IMAGE_URL} alt="person" />}
            title={[`${val?.title}`, `${val?.body}`]}
            date={timeSetter(val?.createdAt)}
            onClick={()=>handleCloseMenu1(val?.related_to, val?.type)}
          />))}
          {/* <NotificationItem
            image={<img src={logoSpotify} alt="person" />}
            title={["New album", "by Travis Scott"]}
            date="1 day"
            onClick={handleCloseMenu}
          />

          <NotificationItem
            color="secondary"
            image={
              <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                payment
              </Icon>
            }
            title={["", "Payment successfully completed"]}
            date="2 days"
            onClick={handleCloseMenu}
          /> */}
        </Box>
        <Box style={customBtn}>
          <Link to="/notification" color="inherit" >View All</Link>
        </Box>

      </Menu>

    </>

  );

  async function getNotifications(){
    try {
      const params ={
        limit : 3
      }
      const resp = await makeHttpRequest('get', 'getNotification', null, params);
      console.log('resp123123', resp)
      setNotifications(resp?.data)
    } catch (error) {
      
    }
  } 

  async function getCountNotifications(){
  const resp1 = await makeHttpRequest('get', 'unseenNotificationCount');
  setCountOfNotification(resp1?.data)
  }
 
  useEffect(()=>{
    getCountNotifications()
  },[])

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <SoftBox pr={1}>
              <SoftInput
                placeholder="Type here..."
                icon={{ component: "search", direction: "left" }}
              />
            </SoftBox> */}
            <SoftBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in"> */}
              <IconButton sx={navbarIconButton} size="small" onClick={() => {
                localStorage.removeItem('token');
                navigate("/authentication/sign-in", { replace: true });
              }}>
                <Icon
                  sx={({ palette: { dark, white } }) => ({
                    color: light ? white.main : dark.main,
                  })}
                >
                  account_circle
                </Icon>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? "white" : "dark"}
                >
                  Sign out
                </SoftTypography>
              </IconButton>
              {/* </Link> */}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton> */}

              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                {countOfNotifications === 0?null:<span className="counter" style={counterStyle}>{countOfNotifications}</span>}
              </IconButton>
              {renderMenu()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
