import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
} from "@mui/material";
import { Button } from "react-bootstrap";
import SoftBox from "components/SoftBox";
import { MdModeEdit } from "react-icons/md";
import user01 from "../../assets/images/team-1.jpg";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";
import { IoMdCloseCircle } from "react-icons/io";
import SuiSelect from "components/SuiSelect/SuiSelect";
import Services from "components/Services/Services";
import { FaCloudUploadAlt } from "react-icons/fa";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import { makeHttpGoogleMapRequest } from "utils/httpServices";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-tabpanel-${index}`}
      aria-labelledby={`scrollable-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function DialogProviders({
  handleClose1,
  open1,
  clinicId,
  getProviderList,
  branchId = null
}) {
  const [value, setValue] = React.useState(0);
  const [day, setDay] = useState("Monday");
  const [timeString, setTimeString] = useState("");
  const [branchListing, setBranchListing] = useState([]);
  const [val, setVal] = React.useState("");
  const [getServicesData, setGetServicesData] = useState([]);
  const [otherSer, setOtherSer] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [clinicWorkingHours, setClinicWokingHour] = useState([]);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
    // const WeekDays = [
    //   "Monday",
    //   "Tuesday",
    //   "Wednesday",
    //   "Thursday",
    //   "Friday",
    //   "Saturday",
    //   "Sunday",
    // ];
    // setDay(WeekDays[newValue]);
  };

  const [serData, setSerData] = useState("");

  const [checked, setChecked] = React.useState(true);
  const [services, setServices] = React.useState([]);

  const [dentistSlots, setDentistSlots] = useState([]);
  console.log("🚀 ~ dentistSlots:", dentistSlots);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    // Define a function to convert ISO date string to time string
    const formatTime = (isoDateString) => {
      const date = new Date(isoDateString);
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    if (clinicWorkingHours) {
      const filteredWorkingHours = clinicWorkingHours.filter(
        (workingHour) => workingHour.day === day
      );
      if (filteredWorkingHours.length > 0) {
        const { start_time, end_time } = filteredWorkingHours[0];
        const startTimeFormatted = formatTime(start_time);
        const endTimeFormatted = formatTime(end_time);
        const timeString = `Clinic Working Hours: ${startTimeFormatted} to ${endTimeFormatted}`;
        setTimeString(timeString);
      }
    }
  }, [clinicWorkingHours, day]);

  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    // email: Yup.string().email().required("Email is required"),

    // .matches(
    //   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    //   "Invalid email address"
    // )
    experience: Yup.string()
      .required("Experience is required")
      .matches(/^[0-9]+$/, "Experience must only contain numbers"),
    // email: Yup.string().email().required("Email is required"),
    // address: Yup.string().required("Address is required"),
    designation: Yup.string().required("Designation is required"),
    profile_image: Yup.string().required("Profile Image is required"),
    // joining_date: Yup.string().required("Joining date is required"),
    branch_id: Yup.string().required("Branch is required"),
  });

  const formik = useFormik({
    initialValues: {
      clinic_id: clinicId,
      branch_id: branchId ? branchId : "",
      first_name: "",
      last_name: "",
      email: "",
      profile_image: "",
      experience: "",
      joining_date: "",
      designation: "",
      address: "",
      // slots: [
      //   {
      //     day: "Monday",
      //     slot: [
      //       {
      //         start: "10:25",
      //         end: "23:59",
      //       },
      //     ],
      //   },
      //   {
      //     day: "Tuesday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },

      //   {
      //     day: "WednesDay",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },

      //   {
      //     day: "Thursday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },

      //   {
      //     day: "Friday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },

      //   {
      //     day: "Saturday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },
      //   {
      //     day: "Sunday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },
      // ],
      services: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
  });

  //check the all fields are filled before go to the next page
  const handleformikCheck = () => {
    formik.validateForm(formik.values).then((errors) => {
      // Set touched for all fields
      formik.setTouched({
        first_name: true,
        last_name: true,
        experience: true,
        email: true,
        designation: true,
        address: true,
        profile_image: true,
        joining_date: true,
        branch_id: true,
      });
      if (Object.keys(errors).length === 0) {
        handleNext();
      }
    });
  };

  //handle Profile image change
  const hanldemediaUpload = async (e, media) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("path", "userProfile");
      formData.append("media", file);

      try {
        const resp = await makeHttpRequest(
          "post",
          "uploadAdminMedia",
          formData
        );
        console.log("resp", resp);
        if (media === "profile_image") {
          formik.setFieldValue("profile_image", resp?.path);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong");
      }
    }
  };

  const getBranchListing = async () => {
    const params = {
      clinic_id: clinicId,
    };
    try {
      const resp = await makeHttpRequest(
        "get",
        "branchListForDentistForm",
        null,
        params
      );
      console.log(resp);
      setBranchListing(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBranchListing();
  }, []);

  // useEffect(() => {
  //   // Create an array with the names of all days of the week
  //

  //   formik.setValues({
  //     ...formik.values,
  //     slots: daysOfWeek.map((day) => {
  //       const workingHour = clinicWorkingHours?.find(
  //         (hour) => hour.day === day
  //       );
  //       return {
  //         day,
  //         slot: workingHour
  //           ? generateTimeSlots(workingHour.start_time, workingHour.end_time)
  //           : [{ start: "", end: "" }],
  //       };
  //     }),
  //   });
  // }, [clinicWorkingHours]);

  // Function to generate time slots based on start and end time
  const generateTimeSlots = (startTime, endTime) => {
    const slots = [];
    let current = new Date(startTime);
    const endDateTime = new Date(endTime);

    // Skip the first slot
    const startHour = current.getHours();
    const nextHour = startHour === 23 ? 0 : startHour + 1;
    const startMinute = current.getMinutes();
    current.setHours(nextHour);
    current.setMinutes(0);

    while (current < endDateTime) {
      const startHour = current.getHours();
      const startMinute = current.getMinutes();

      // Calculate the end time of the slot
      const nextHour = startHour === 23 ? 0 : startHour + 1;
      const endHour = nextHour;
      const endMinute = 0;

      const start = `${startHour < 10 ? "0" + startHour : startHour}:${
        startMinute < 10 ? "0" + startMinute : startMinute
      }`;
      const end = `${endHour < 10 ? "0" + endHour : endHour}:${
        endMinute < 10 ? "0" + endMinute : endMinute
      }`;

      slots.push({ start, end });

      // Move to the next slot
      const nextSlotTime = new Date(current.getTime());
      nextSlotTime.setHours(endHour);
      nextSlotTime.setMinutes(endMinute);
      current = nextSlotTime;
    }

    // Remove the last slot
    slots.pop();

    return slots;
  };

  const handleAddSlot = (dayIndex) => {
    // Add a new slot to the specified day's slots array
    formik.setValues((prevValues) => ({
      ...prevValues,
      slots: prevValues.slots.map((day, index) => {
        if (index === dayIndex) {
          return {
            ...day,
            slot: [
              ...day.slot,
              {
                start: "",
                end: "",
              },
            ],
          };
        }
        return day;
      }),
    }));
  };

  //handling removing slot times
  const handleSlotTimeDelete = (dayIndex, slotIndex) => {
    // Remove the specified slot from the specified day's slots array
    formik.setValues((prevValues) => ({
      ...prevValues,
      slots: prevValues.slots.map((day, index) => {
        if (index === dayIndex) {
          return {
            ...day,
            slot: day.slot.filter((_, i) => i !== slotIndex),
          };
        }
        return day;
      }),
    }));
  };

  //get services from Child Component
  const getServices = (services) => {
    setServices(services);
  };

  const convertTimeArray = (originalArray) => {
    return originalArray
      .map((day) => ({
        day: day.day,
        slot: day.slot
          .filter((timeSlot) => timeSlot.start !== "" || timeSlot.end !== "")
          .map((timeSlot) => ({
            start: timeSlot.start ? convertToDateString(timeSlot.start) : "",
            end: timeSlot.end ? convertToDateString(timeSlot.end) : "",
          })),
      }))
      .filter((day) => day.slot.length > 0);
  };

  const convertToDateString = (time) => {
    const [starhour, startminute] = time.split(":");
    // const [endHour, endMinute] = endTime.split(':');

    // if (starhour > endHour || (starhour === endHour && startminute >= endMinute)) {
    //     toast.error('Invalid working hours. Start time must be before end time.');
    //     return;
    //   }

    const startInputTime = new Date("2023-01-01");
    // const endInputTime = new Date();

    startInputTime.setHours(starhour, 0, 0, 0); // Set the hours and minutes
    // endInputTime.setHours(endHour, endMinute, 0, 0); // Set the hours and minutes

    // const timeZoneOffset = startInputTime.getTimezoneOffset();

    // Adjust the start time to UTC by subtracting the offset
    // const startUtcTime = new Date(startInputTime.getTime() - timeZoneOffset * 60000);
    const startUtcTime = startInputTime.toISOString();
    // const endUtcTime = new Date(endInputTime.getTime() - timeZoneOffset * 60000);
    return startUtcTime;
  };

  // ******************************validating time  slots ****************************
  const validateTimeSlots = async () => {
    //by passing function becz of flow change
    const body = { ...formik.values };

    let checker = false;
    body.slots.forEach((val) => {
      val.slot.some((times) => {
        if (times.start && times.end) {
          const startTime = times.start;
          const endTime = times.end;

          const [starthour, startminute] = startTime.split(":");
          const [endHour, endMinute] = endTime.split(":");
          let time = 0;
          starthour === "00" ? (time = 24) : (time = starthour);

          if (time > endHour) {
            if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
              toast.error(
                "Invalid working hours. Start time must be before end time."
              );

              checker = true;
              return true;
            } else if (
              time >= 13 &&
              time <= 24 &&
              endHour >= 13 &&
              endHour <= 24
            ) {
              toast.error(
                "Invalid working hours. Start time must be before end time."
              );

              checker = true;
              return true;
            }
          } else if (time === endHour && startminute >= endMinute) {
            toast.error(
              "Invalid working hours. Start time must be before end time."
            );
            checker = true;
            return true;
          }

          if (checker == true) {
            return true;
          } else {
            return false;
          }
        }

        if (checker == true) {
          return true;
        } else {
          return false;
        }
      });
    });

    if (checker === true) return;
    const slots = convertTimeArray(body.slots);
    body.slots = slots;

    // console.log("body*******",body)

    if (slots.length == 0) {
      toast.error("All fields are mandatory.");
      return;
    }

    const Body = {
      branch_id: formik.values.branch_id,
      slots: body.slots,
    };

    try {
      const Resp = await makeHttpRequest("post", "isSlotsValid", Body);
      if (Resp?.data == true) {
        handleNext();
      } else {
        toast.error("Please add valid slots.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //add dentist removed code form add function -------------------------
  // body.slots.forEach((val) => {
  //   val.slot.some((times) => {
  //     if (times.start && times.end) {
  //       const startTime = times.start;
  //       const endTime = times.end;

  //       const [starthour, startminute] = startTime.split(":");
  //       const [endHour, endMinute] = endTime.split(":");
  //       let time = 0;
  //       starthour === "00" ? (time = 24) : (time = starthour);

  //       if (time > endHour) {
  //         if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
  //           toast.error(
  //             "Invalid working hours. Start time must be before end time."
  //           );

  //           checker = true;
  //           return true;
  //         } else if (
  //           time >= 13 &&
  //           time <= 24 &&
  //           endHour >= 13 &&
  //           endHour <= 24
  //         ) {
  //           toast.error(
  //             "Invalid working hours. Start time must be before end time."
  //           );

  //           checker = true;
  //           return true;
  //         }
  //       } else if (time === endHour && startminute >= endMinute) {
  //         toast.error(
  //           "Invalid working hours. Start time must be before end time."
  //         );
  //         checker = true;
  //         return true;
  //       }

  //       if (checker == true) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }

  //     if (checker == true) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // });

  // if (checker === true) return;
  // const slots = convertTimeArray(body.slots);
  // body.slots = slots;

  // console.log("body*******",body)

  // if (slots.length == 0) {
  //   toast.error("All fields are mandatory.");
  //   return;
  // }

  //   ***********************************Adding Providder******************************

  const handleAddProvider = async () => {
    try {
      const body = { ...formik.values };
      body.slots = dentistSlots;
      body.services = services;
      body.clinic_id = clinicId;

      console.log("🚀 ~ handleAddProvider ~ body:", body);
      let checker = false;

      if (services.length == 0) {
        toast.error("All fields are mandatory.");
        return;
      }

      // const Body = {
      //   branch_id: formik.values.branch_id,
      //   slots: body.slots,
      // };

      // const Resp = await makeHttpRequest("post", "isSlotsValid", Body);
      // if (Resp?.data == true) {
      try {
        const encodedAddress = encodeURIComponent(body.address);
        const Resp2 = await makeHttpGoogleMapRequest(encodedAddress);
        console.log("Resp1------------->", Resp2);

        if (Resp2?.status == "ZERO_RESULTS") {
          toast.error("Please add a valid address.");
          return;
        }
        const longitude = Resp2?.results[0].geometry.location.lng;
        const latitude = Resp2?.results[0].geometry.location.lat;
        const location = {
          type: "Point",
          coordinates: [longitude, latitude],
        };

        body.location = location;
         try {
          const resp = await makeHttpRequest("post", "addDentist", body);
          console.log(resp);
          toast.success(resp?.message);
          getProviderList(clinicId);
          // getBranchList()
          handleClose1();
         } catch (error) {
          console.log(error)
          toast.error(error?.response?.data?.errors?.msg);
         }
       
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.errors?.msg);
      }
      // } else {
      //   toast.error("Please add valid slots.");
      // }
    } catch (e) {
      toast.error("Network Error.");
      console.log(e);
    }
  };

  const serviceHandler = (val) => {
    // if (serData?.includes(val)) {
    //   return;
    // }
    setSerData(val);
    setVal(val);
  };

  const branchAndServiceHandler = async (branchId) => {
    formik.setFieldValue("branch_id", branchId);
    try {
      const resp = await makeHttpRequest("get", `getSingleBranch/${branchId}`);
      console.log(resp, "responseeeeee");
      setClinicWokingHour(resp.data.working_hours);
      setServicesList(resp?.data?.services);
    } catch (error) {}
  };

  useEffect(() => {
  if(formik?.values?.branch_id){

    getSlotsAccToClinic(formik?.values?.branch_id);
    branchAndServiceHandler(formik?.values?.branch_id)
  }
    
  }, [formik?.values?.branch_id]);

  const getSlotsAccToClinic = async (brachId) => {
    try {
      const resp = await makeHttpRequest("get", `clinic-slots/${brachId}`);
      setDentistSlots(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const convertDateToTimeString = (slot) => {
    const start = new Date(slot?.start)?.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const end = new Date(slot?.end)?.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return `${start} to ${end}`;
  };

  const toggleSlot = (dayIndex, slotIndex) => {
    console.log({ dayIndex, slotIndex });
    setDentistSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];
      updatedSlots[dayIndex].slot[slotIndex] = {
        ...updatedSlots[dayIndex].slot[slotIndex],
        enable: !updatedSlots[dayIndex].slot[slotIndex].enable,
      };
      return updatedSlots;
    });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          open={open1}
          onClose={() => handleClose1()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              minWidth: "600px",
              minHeight: "550px",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">{"Add Dentist"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="provider_container">
              {currentStep === 1 && (
                <SoftBox className="first_Step">
                  <SoftBox mb={3} className=" upload-image">
                    <SoftBox className="image-container">
                      <img
                        src={
                          formik.values.profile_image
                            ? process.env.REACT_APP_IMAGE_URL +
                              "userProfile/" +
                              formik.values.profile_image
                            : user01
                        }
                        alt=""
                      />
                    </SoftBox>
                    <label htmlFor="uploadd-image">
                      <FaCloudUploadAlt />
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => hanldemediaUpload(e, "profile_image")}
                      id="uploadd-image"
                    />
                  </SoftBox>
                  {formik.touched.profile_image &&
                  formik.errors.profile_image ? (
                    <div className="form-error1">
                      {formik.errors.profile_image}
                    </div>
                  ) : null}
                  <SoftBox>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              First Name <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            {...formik.getFieldProps("first_name")}
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div className="form-error">
                              {formik.errors.first_name}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Last Name <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Last Name"
                            {...formik.getFieldProps("last_name")}
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div className="form-error">
                              {formik.errors.last_name}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Email
                               {/* <span className="text-danger">*</span> */}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="form-error">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Experience <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Experience"
                            {...formik.getFieldProps("experience")}
                          />
                          {formik.touched.experience &&
                          formik.errors.experience ? (
                            <div className="form-error">
                              {formik.errors.experience}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Joining Date{" "}
                              {/* <span className="text-danger">*</span> */}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="date"
                            placeholder="Joining Date"
                            {...formik.getFieldProps("joining_date")}
                          />
                          {formik.touched.joining_date &&
                          formik.errors.joining_date ? (
                            <div className="form-error">
                              {formik.errors.joining_date}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Designation <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Designation"
                            {...formik.getFieldProps("designation")}
                          />
                          {formik.touched.designation &&
                          formik.errors.designation ? (
                            <div className="form-error">
                              {formik.errors.designation}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                     {!branchId &&
                      <Grid item xs={12}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Branch <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>

                          <SuiSelect
                            className="filter"
                            placeholder="Select Branch"
                            value={formik.values.branch_id}
                            options={branchListing?.map((obj) => ({
                              value: obj?._id,
                              label: obj?.practice_name,
                            }))}
                            // options={[
                            //   {
                            //     value: "Stress-Free Dentistry",
                            //     label: "Stress-Free Dentistry",
                            //   },
                            //   { value: "Sure Smile", label: "Sure Smile" },
                            //   {
                            //     value: "Teeth Fairy Dental",
                            //     label: "Teeth Fairy Dental",
                            //   },
                            //   {
                            //     value: "TeethMeet Clinic",
                            //     label: "TeethMeet Clinic",
                            //   },
                            //   // ... other providers
                            // ]}
                            // defaultValue="TeethMeet Clinic"

                            onChange={(selectedValue) => {
                              branchAndServiceHandler(selectedValue);
                            }}
                          />
                          {formik.touched.branch_id &&
                          formik.errors.branch_id ? (
                            <div className="form-error">
                              {formik.errors.branch_id}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                     }
                     

                      {/* <Grid item md={12} xs={12} lg={12} xl={12}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Add Address <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftTextArea
                            placeholder={"Enter Your Full Address"}
                            rows={4}
                            cols={4}
                            {...formik.getFieldProps("address")}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className="form-error">
                              {formik.errors.address}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid> */}
                    </Grid>
                  </SoftBox>
                </SoftBox>
              )}

              {currentStep === 2 && (
                <SoftBox className="second_Step">
                  <SoftBox
                    mt={2}
                    component="form"
                    role="form"
                    className="working_hours"
                  >
                    <SoftBox
                      display="flex"
                      mb={2}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">
                        Add Working Hours Details
                      </SoftTypography>
                      <br />
                    </SoftBox>
                    {/* <SoftTypography variant="h6">
                      <span style={{ marginLeft: "5px" }}>{timeString}</span>
                    </SoftTypography> */}
                    <Box
                      sx={{
                        flexGrow: 1,
                        maxWidth: { xs: 320, sm: 480, md: "100%" },
                        bgcolor: "background.paper",
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange1}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                          },
                        }}
                      >
                        <Tab label="Monday" />
                        <Tab label="Tuesday" />
                        <Tab label="Wednesday" />
                        <Tab label="Thursday" />
                        <Tab label="Friday" />
                        <Tab label="Saturday" />
                        <Tab label="Sunday" />
                      </Tabs>
                      {/* {formik.values.slots?.map((obj, index) => (
                        <TabPanel value={value} index={index} key={index}>
                          {obj?.slot?.map((time, timeIndex) => (
                            <Grid container spacing={2} key={timeIndex}>
                              <Grid item md={4} xs={12}>
                                <SoftBox>
                                  <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                    >
                                      From{" "}
                                      <span className="text-danger">*</span>
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftInput
                                    type="time"
                                    value="Brack"
                                    placeholder="Last Name"
                                    {...formik.getFieldProps(
                                      `slots.${index}.slot.${timeIndex}.start`
                                    )}
                                  />
                                </SoftBox>
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <SoftBox>
                                  <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                    >
                                      To <span className="text-danger">*</span>
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftInput
                                    type="time"
                                    {...formik.getFieldProps(
                                      `slots.${index}.slot.${timeIndex}.end`
                                    )}
                                  />
                                </SoftBox>
                              </Grid>
                              <Grid item md={2} xs={12}>
                                <SoftBox>
                                  <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                    >
                                      Enable{" "}
                                      <span className="text-danger">*</span>
                                    </SoftTypography>
                                  </SoftBox>
                                  <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </SoftBox>
                              </Grid>
                              <Grid item md={1} xs={12}>
                                <SoftBox className="icon-cntner">
                                  <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                    >
                                      Clear
                                    </SoftTypography>
                                  </SoftBox>
                                  <IoMdCloseCircle
                                    onClick={() =>
                                      handleSlotTimeDelete(index, timeIndex)
                                    }
                                  />
                                </SoftBox>
                              </Grid>
                            </Grid>
                          ))}
                          <SoftBox
                            display="flex"
                            my={4}
                            alignItems="end"
                            style={{ width: "100%" }}
                            justifyContent="end"
                          >
                            <SoftButton
                              variant="contained"
                              size="small"
                              color="info"
                              onClick={() => handleAddSlot(index)}
                            >
                              Add New
                            </SoftButton>
                          </SoftBox>
                        </TabPanel>
                      ))} */}

                      {daysOfWeek.map((day, dayIndex) => {
                 
                        const obj = dentistSlots.find((slot) => slot.day === day );
                        const objIndex = dentistSlots.findIndex((slot) => slot.day === day);
                        
                          
                        return (
                          <TabPanel
                            key={dayIndex}
                            value={value}
                            index={dayIndex}
                          >
                            <SoftBox className="timeSlotsContainer">
                              {obj ? (
                                obj.slot.map((slot, slotIndex) => (
                                  <SoftBox
                                    key={slotIndex}
                                    className="time-slot"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={slot.enable}
                                      id={`time-slot-${dayIndex}-${slotIndex}`}
                                    />
                                    <label
                                      htmlFor={`time-slot-${dayIndex}-${slotIndex}`}
                                      onClick={() =>
                                        toggleSlot(objIndex, slotIndex)
                                      }
                                    >
                                      {convertDateToTimeString(slot)}
                                    </label>
                                  </SoftBox>
                                ))
                              ) : (
                                <p>No slots available for {day}.</p>
                              )}
                            </SoftBox>
                          </TabPanel>
                        );
                      })}

                      <TabPanel value={value} index={1}></TabPanel>
                    </Box>
                  </SoftBox>
                </SoftBox>
              )}

              {currentStep === 3 && (
                <SoftBox className="third_Step">
                  <SoftBox
                    mt={3}
                    component="form"
                    role="form"
                    className="working_hours"
                  >
                    <SoftBox
                      display="flex"
                      mb={2}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">
                        Add Services Details
                      </SoftTypography>
                      {/* <SoftButton variant="contained" size="small" color="info">
                        Add New
                      </SoftButton> */}
                    </SoftBox>
                  </SoftBox>
                  <Services
                    setServicesToParent={getServices}
                    serData={serData}
                    getServicesData={getServicesData}
                  />

                  <div
                    className="selectWrpSm"
                    style={{ width: "86%", margin: "1rem 0" }}
                  >
                    <SuiSelect
                      className="filter selctServc mb-4"
                      placeholder="Select Day"
                      options={servicesList?.map((val) => ({
                        value: val,
                        label: val,
                      }))}
                      // { value: "Crowns", label: "Crowns" },
                      // { value: "Root Canal", label: "Root Canal" },
                      // {
                      //   value: "Wisdom teeth Removal",
                      //   label: "Wisdom teeth Removal",
                      // },
                      // { value: "Teeth Whitening", label: "Teeth Whitening" },
                      // { value: "Dental Bridge", label: "Dental Bridge" },
                      // {
                      //   value: "Tooth Extraction",
                      //   label: "Tooth Extraction",
                      // },
                      // {
                      //   value: "Checkup $ Cleaning",
                      //   label: "Checkup $ Cleaning",
                      // },
                      // }
                      value={val}
                      defaultValue="Filling"
                      onChange={serviceHandler}
                    />
                  </div>
                </SoftBox>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <SoftButton variant='outlined' onClick={() => handleClose1()}>Cancel</SoftButton>
                    <SoftButton variant='contained' onClick={() => handleClose1()} autoFocus>
                        Done
                    </SoftButton> */}

            <SoftBox
              display="flex"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {currentStep === 1 && (
                <SoftButton
                  variant="outlined"
                  style={{ marginRight: "10px" }}
                  size="small"
                  color="info"
                  onClick={() => {
                    handleformikCheck();
                  }}
                >
                  Next
                </SoftButton>
              )}
              {currentStep === 2 && (
                <SoftBox>
                  <SoftButton
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    size="small"
                    color="info"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>

                  <SoftButton
                    variant="outlined"
                    size="small"
                    color="info"
                    // onClick={validateTimeSlots}
                    onClick={handleNext}
                  >
                    Next
                  </SoftButton>
                </SoftBox>
              )}

              {currentStep === 3 && (
                <SoftBox>
                  <SoftButton
                    variant="contained"
                    size="small"
                    style={{ marginRight: "10px" }}
                    color="info"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>

                  <SoftButton
                    variant="outlined"
                    size="small"
                    color="info"
                    // onClick={() => handleClose1()}
                    onClick={() => handleAddProvider()}
                    type="submit"
                  >
                    Finish
                  </SoftButton>
                </SoftBox>
              )}
            </SoftBox>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
