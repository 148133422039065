import { Card, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTextArea from 'components/SoftTextArea/SoftTextArea'
import SoftTypography from 'components/SoftTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import user01 from "assets/images/team-5.jpg"
import React, { useEffect, useState } from 'react'
import { FaCloudUploadAlt, FaSearchPlus } from 'react-icons/fa'
import DefaultInfoCard from 'components/DefaultInfoCard'
import SoftButton from 'components/SoftButton'
import Footer from 'examples/Footer'
import { makeHttpRequest } from 'utils/httpServices'
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from 'yup';


export default function AboutUsManagement() {

    const [cardData, setCardData] = useState([
        {
            icon: '',
            title: '',
            description: ''
        },
        {
            icon: '',
            title: '',
            description: ''
        },
        {
            icon: '',
            title: '',
            description: ''
        }
    ]);
    const [sideIMAGE, setSideIMAGE] = useState(null);
    const [image, setImage] = useState(null);
    const [sendImage, setSendImage] = useState(null)


    const [id, setId] = useState('');

    const getData = async () => {

        try {
            const resp = await makeHttpRequest('get', 'getAboutUsContent');

            if (resp != null) {
                setId(resp?.data?._id)

                console.log(resp);
                setSendImage(resp?.data?.first_section?.side_image);
                setImage(`${process.env.REACT_APP_IMAGE_URL}pageImages/${resp?.data?.first_section?.side_image}`);
                console.log(`${process.env.REACT_APP_IMAGE_URL}pageImages/${resp?.data?.first_section?.side_image}`)

                await formik.setValues({

                    first_title: resp?.data?.first_section?.title || '',
                    description: resp?.data?.first_section?.description || '',
                    second_title: resp?.data?.second_section?.title || '',
                    second_description: resp?.data?.second_section?.description || ''
                });

                setCardData(resp?.data?.card_section || cardData);
            }
        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }

    }

    // console.log(cardData)
    console.log(cardData)

    useEffect(() => {
        getData();
    }, []);

    // const validationSchema = Yup.object().shape({
    //     first_title: Yup.string().required('First title is required'),
    //     description: Yup.string().required('First description is required'),
    //     second_title: Yup.string().required('Second title is required'),
    //     second_description: Yup.string().required('Second description is required'),


    //   });

    const formik = useFormik({

        initialValues: {
            first_title: '',
            description: '',
            second_title: '',
            second_description: ''
        }
        // validationSchema : validationSchema
    });

    const submitHandler = async () => {
        try {
            const DataObject = {
                first_section: {
                    title: formik.values.first_title,
                    description: formik.values.description,
                    side_image: sendImage
                },
                second_section: {
                    title: formik.values.second_title,
                    description: formik.values.second_description
                },
                card_section: [
                    {
                        icon: cardData[0].icon,
                        title: cardData[0].title,
                        description: cardData[0].description
                    },
                    {
                        icon: cardData[1].icon,
                        title: cardData[1].title,
                        description: cardData[1].description
                    },
                    {
                        icon: cardData[2].icon,
                        title: cardData[2].title,
                        description: cardData[2].description
                    }
                ]
            }
            // console.log(formik.values.second_title)
            // console.log(formik.values.second_description)
            // console.log(formik.values.cardData[0]?.icon )
            // console.log(formik.values.cardData[0].title)
            // console.log(formik.values.cardData[0].description)
            // console.log(formik.values.cardData[1].icon)
            // console.log(formik.values.cardData[1].description)
            // console.log(formik.values.cardData[1].title)
            // console.log(formik.values.cardData[2].icon)
            // console.log(formik.values.cardData[2].title )
            // console.log(formik.values.cardData[2].description)
            // console.log(formik.values.formik.values.first_title)
            // console.log(formik.values.formik.values.description)

            if (!formik.values.second_title ||
                !formik.values.second_description ||
                !cardData[0].icon ||
                !cardData[0].title ||
                !cardData[0].description ||
                !cardData[1].icon ||
                !cardData[1].description ||
                !cardData[1].title ||
                !cardData[2].icon ||
                !cardData[2].title ||
                !cardData[2].description ||
                !formik.values.first_title ||
                !formik.values.description) {
                toast.error("All fields are mandatory")
                return
            }

            if (id == undefined) {
                const resp1 = await makeHttpRequest('post', 'addAboutUsContent', DataObject);
                toast.success('Added successfully')
                console.log(resp1);
            } else {
                console.log(id)
                const resp1 = await makeHttpRequest('patch', `editAboutUsContent/${id}`, DataObject);
                toast.success('Updated successfully')
            }
        }
        catch (e) {
            toast.error('Network Error');
            console.log(e)
        }
    }

    const imageHandler = async (e) => {
        try {
            const allowedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const file = e.target.files[0];
            console.log(file)
            const fileName = file.name;
            const allowedExt = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;

            // Checking for image extension
            if (allowedExt.test(fileName) === false) {
                toast.info(`Invalid file extension. Please select a file with one of the following extensions: ${allowedImageExtensions.join(', ')}`);
                return;

            }

            if (file) {


                const formDataObject1 = new FormData();
                formDataObject1.append('path', 'pageImages');
                formDataObject1.append('media', file);

                const resp = await makeHttpRequest('post', 'uploadAdminMedia', formDataObject1);
                setSendImage(resp.path);
                setImage(resp.path)
                const reader = new FileReader()

                reader.onload = (event) => {
                    setSideIMAGE(event.target.result)
                    console.log(event.target.result)
                }
                reader.readAsDataURL(file)
            }
        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }
    }




    return (
        <DashboardLayout >
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card  >

                        <SoftBox p={3} pb={0} className="main-title">
                            <SoftTypography variant="h6">About Us</SoftTypography>
                        </SoftBox>

                        {/* ---Home--Page--First__section */}

                        <SoftBox p={3}>
                            <SoftBox className="main-title" mb={3}>
                                <SoftTypography variant="h6">First Section</SoftTypography>
                            </SoftBox>

                            <Grid container spacing={4}>
                                <Grid item md={5} xs={12} lg={5} xl={5}>
                                    <SoftBox className="Image_Wrappr">
                                        <SoftBox mb={3} className="Image_Wrappr_inner">
                                            <SoftBox className="image-container" >
                                                {console.log(image)}
                                                <img src={sideIMAGE ? sideIMAGE : image ? image : 'https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png'
                                                } width={100} height={100} alt="" />
                                            </SoftBox>
                                            <label htmlFor="uploadd-image"><FaCloudUploadAlt /></label>
                                            <input type="file" id="uploadd-image" onChange={imageHandler} accept="image/*" />

                                        </SoftBox>


                                    </SoftBox>
                                </Grid>
                                <Grid item md={7} xs={12} lg={7} xl={7}>

                                    <SoftBox mb={3}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                First Title
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" value="Find Your Best Dentist In Your City" placeholder="Last Name" name='first_title'
                                            {...formik.getFieldProps('first_title')}
                                        />
                                        {/* {formik.errors.first_title && formik.touched.first_title ? (<p className='form-error'>
                                            {formik.errors.first_title}
                                        </p>):null} */}
                                    </SoftBox>

                                    <SoftBox >
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Description <span className='text-danger'>*</span>
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftTextArea placeholder={"Add some description"}
                                            rows={8}
                                            cols={8} name='description' {...formik.getFieldProps('description')} />
                                    </SoftBox>
                                    {/* {formik.errors.description && formik.touched.description ? (<p className='form-error'>
                                            {formik.errors.description}
                                        </p>):null} */}
                                </Grid>

                            </Grid>
                        </SoftBox>

                        {/* ---Home--Page--Second__section */}
                        <SoftBox p={3}>
                            <SoftBox className="main-title" mb={3}>
                                <SoftTypography variant="h6">Second Section</SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        First Title
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="text" value="Find Your Best Dentist In Your City" placeholder="Last Name"
                                    name='second_title' {...formik.getFieldProps('second_title')}
                                />
                                {/* {formik.errors.second_title && formik.touched.second_title ? (<p className='form-error'>
                                            {formik.errors.second_title}
                                        </p>):null} */}
                            </SoftBox>

                            <SoftBox mb={3}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Description <span className='text-danger'>*</span>
                                    </SoftTypography>
                                </SoftBox>
                                <SoftTextArea placeholder={"Add some description"}
                                    rows={3}
                                    cols={3}
                                    name='second_description' {...formik.getFieldProps('second_description')}
                                />
                                {/* {formik.errors.second_description && formik.touched.second_description ? (<p className='form-error'>
                                            {formik.errors.second_description}
                                        </p>):null} */}
                            </SoftBox>

                            <Grid container spacing={4}>

                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCard
                                        icon={<FaSearchPlus />}
                                        title="Your Title"
                                        description="Your Description"
                                        setCardData={setCardData}
                                        cardData={cardData}
                                        // val={cardData[0]}
                                        index={0}

                                    />

                                </Grid>
                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCard
                                        icon={<FaSearchPlus />}
                                        title="Your Title"
                                        description="Your Description"
                                        // val={cardData[1]}
                                        setCardData={setCardData}
                                        cardData={cardData}
                                        index={1}

                                    />

                                </Grid>
                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCard
                                        icon={<FaSearchPlus />}
                                        title="Your Title"
                                        description="Your Description"
                                        // val={cardData[2]}
                                        setCardData={setCardData}
                                        cardData={cardData}
                                        index={2}

                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid item md={12}>
                                <SoftBox my={2} display="flex" alignItems="end" justifyContent="end">
                                    <SoftButton variant="contained" color="info" onClick={() => setCardData([...cardData, {}])}>Add New</SoftButton>
                                </SoftBox>
                            </Grid> */}
                        </SoftBox>

                        {/* --Home-Page-Third_section-- */}

                    </Card>

                </SoftBox>
                <SoftBox display="flex" justifyContent="center" alignItems="center" pb={3}>
                    <SoftButton variant="contained" color="info" onClick={submitHandler}>Save</SoftButton>
                </SoftBox>
            </SoftBox>
            {/* <Footer /> */}
        </DashboardLayout>
    )
}
