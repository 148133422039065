// @mui material components
import Card from "@mui/material/Card";
import React from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data

import SoftInput from "components/SoftInput";
import { Grid, TablePagination } from "@mui/material";
import SuiSelect from "components/SuiSelect/SuiSelect";
// import UserTableData from "./data/UserTableData";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditEnquiryModal from "components/EditEnquiryModal/EditEnquiryModal";
import ViewEnquiryModal from "components/ViewEnquiryModal/ViewEnquiryModal";
import DeleteModal from "components/DeleteModal/DeleteModal";
import Pagination from "../pagination/index";

// import projectsTableData from "layouts/tables/data/projectsTableData";

function EnquiryManagement() {
  // const { columns, rows } = UserTableData;

  // console.log({ columns })
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [rows, setRows] = useState([]);
  const [inpText, setInpText] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState("");
  const [isViewEnquiryModalOpen, setViewEnquiryModalOpen] = useState(false);
  const [isEditEnquiryModalOpen, setEditEnquiryModalOpen] = useState(false);
  const [totalCount,setTotalCount] = useState(100);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingRow, setDeletingRow] = useState(null);
  const handleOpenModal = (id) => {
    setViewEnquiryModalOpen(true);
    setId(id);
  };

  // Function to handle closing the modal
  // const handleCloseModal = () => {
  //     setViewEnquiryModalOpen(false);
  // };

  // --Edit--

  const handleOpenModal2 = (id) => {
    setEditEnquiryModalOpen(true);
    setId(id);
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  // Function to handle closing the modal
  // const handleCloseModal2 = () => {
  //     setEditEnquiryModalOpen(false);
  // };

  const getData = async (year, month, inpText) => {
    try {
      const offset = currentPage * rowsPerPage;
      const param = {
        sort: -1,
        year: year,
        month: month,
        search: inpText,
        offset: offset,
        limit: 10,
      };
      const resp = await makeHttpRequest("get", "enquiryListing", null, param);
      console.log(resp);
      setTotalCount(resp?.count)
      const count1 = Math.ceil(resp?.count / 10);
      setTotalPage(count1);
      setRows(
        resp?.data.map((val) => {
          return {
            email: val?.email,
            number: val?.number,
            "practic Name": val?.practic_name,
            id: val?._id,
          };
        })
      );
    } catch (error) {
      // toast.error('Network error.')
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getData(year, month, inpText);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [year, month, inpText, currentPage]);

  const handleDeleteClick = (id) => {
    // setDeletingRow(rowData);
    setDeleteModalOpen(true);
    setId(id);
  };

  const handleDeleteConfirm = async () => {
    try {
      const resp = await makeHttpRequest("delete", `deleteEnquiry/${id}`);
      toast.success("Deleted successfully.");
      getData();
      const updatedRows = userTableData.rows.filter(
        (row) => row !== deletingRow
      );
      //   setUserTableData({ ...userTableData, rows: updatedRows });
      setDeleteModalOpen(false);
    } catch (error) {}
  };

  console.log("rows--------------->", rows);

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const searchHandle = (text, date) => {
    setSelectedDate(date);
    setInpText(text);
    if (date !== null) {
      setMonth(date.getMonth() + 1);
      setYear(date.getFullYear());
    }
    // console.log(date.getMonth() + 1, date.getFullYear(), text, '-------------->')
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    console.log(`Selected page: ${value}`);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Grid item xs={12} md={8}>
              <SoftBox
                className="table_header"
                display="flex"
                alignItems="center"
              >
                <SoftTypography variant="h6">Users List</SoftTypography>
                <SoftBox
                  ml={2}
                  className="filter_box"
                  display="flex"
                  gap="15px"
                >
                  <SoftInput
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    value={inpText}
                    onChange={(e) => searchHandle(e.target.value, selectedDate)}
                  />

                  {/* <DatePicker
                    selected={selectedDate}
                    renderMonthContent={renderMonthContent}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    onChange={(e) => searchHandle(inpText, e)}
                  /> */}
                </SoftBox>
              </SoftBox>
            </Grid>
          </SoftBox>
          <Card>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table
                columns={[
                  { name: "email", align: "left" },
                  { name: "number", align: "left" },
                  { name: "practic Name", align: "center" },
                  { name: "action", align: "center" },
                ]}
                rows={rows?.map((row) => ({
                  ...row,
                  action: (
                    <SoftBox>
                      {/* <SoftTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="secondary"
                        fontWeight="medium"
                        marginRight="10px"
                        onClick={() => handleOpenModal(row?.id)}
                      >
                        View
                      </SoftTypography> */}
                      {/* <SoftTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="secondary"
                        fontWeight="medium"
                        marginRight="10px"
                        onClick={() => handleOpenModal2(row?.id)}
                      >
                        Edit
                      </SoftTypography> */}
                      <SoftTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="secondary"
                        fontWeight="medium"
                        onClick={() => handleDeleteClick(row?.id)}
                      >
                        Delete
                      </SoftTypography>
                    </SoftBox>
                  ),
                }))}
              />
            </SoftBox>
          </Card>

          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 15, 20, 25]}
          />
        </SoftBox>

        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
        />
        {isEditEnquiryModalOpen && (
          <EditEnquiryModal
            isOpen={isEditEnquiryModalOpen}
            onClose={() => setEditEnquiryModalOpen(false)}
            id={id && id}
            setRows={setRows}
          />
        )}
        {console.log("id----------->", id)}

        {isViewEnquiryModalOpen && (
          <ViewEnquiryModal
            id={id && id}
            isOpen={isViewEnquiryModalOpen}
            onClose={() => setViewEnquiryModalOpen(false)}
          />
        )}
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
export default EnquiryManagement;
