import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SuiSelect from "components/SuiSelect/SuiSelect";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsArray({ setServicesToParent, serData, getInsurancesData }) {
  const [chipData, setChipData] = React.useState([]);
  // { key: 0, label: 'Tooth cleaning' },
  //     { key: 1, label: 'Root Canal Therapy' },
  //     { key: 2, label: 'Implants' },
  //     { key: 3, label: 'Composite Bonding' },
  //     { key: 4, label: 'Fissure Sealants' },
  //     { key: 5, label: 'Surgical Extractions' },

  const [newChipLabel, setNewChipLabel] = React.useState("");

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleAddChip = (e) => {
    if (!chipData.find((chip) => chip.label === newChipLabel)) {
      if (newChipLabel.trim() !== "") {
        const newChip = {
          key: chipData.length,
          label: newChipLabel,
        };

        setChipData((chips) => [...chips, newChip]);
        setNewChipLabel("");
      }
    }
  };

  const handleChange = (event) => {
    setNewChipLabel(event.target.value);
  };

  React.useEffect(() => {
    const insuranceData = chipData?.map((val)=> val?.label)
    setServicesToParent(insuranceData);
  }, [chipData]);

  React.useEffect(() => {

    const newLabel = serData;

    if (newLabel && newLabel !== 'Canadian Insurances' && newLabel !== 'Others' && !chipData.find((chip) => chip.label === newLabel)) {
      const newData = {
        key: chipData.length,
        label: newLabel,
      };
      setChipData((chips) => [...chips, newData]);
    }
    setNewChipLabel("");
  }, [serData]);

  React.useEffect(() => {
    // const newLabel = serData;

    for (let index = 0; index < getInsurancesData.length; index++) {
        const newLabel = getInsurancesData[index]
    if (newLabel && !chipData.find((chip) => chip.label === newLabel)) {
        const newData = {
            key: chipData.length + index,
            label: newLabel,
        };
        setChipData((chips) => [...chips, newData]);
    }}
    // setNewChipLabel("");
  }, [getInsurancesData]);

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 2,
          overflowY: "hidden",
          overflowX: "scroll",
          overflow:"auto",
        }}
        component="div"
      >
        <div className="abc service_data">
          {chipData.map((data) => {
            let icon;

            if (data.label === "React") {
              icon = <TagFacesIcon />;
            }

            return (
              <ListItem key={data.key}>
                <Chip
                  icon={icon}
                  label={data.label}
                  onDelete={
                    data.label === "React" ? undefined : handleDelete(data)
                  }
                />
              </ListItem>
            );
          })}
        </div>
      </Paper>

      <SoftBox display="flex" mt={3} style={{ gap: "10px" }}>
        <SoftInput
          label="New Chip"
          value={newChipLabel}
          onChange={handleChange}
          variant="outlined"
          placeholder="Add Insurances"
        />
        <SoftButton color="info" variant="contained" onClick={handleAddChip}>
          Add
        </SoftButton>
      </SoftBox>
    </>
  );
}
