import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import brand from "assets/images/logo-ct.png";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { DiscFull } from "@mui/icons-material";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";

function SignIn() {
  const viewpass = {
    position: "absolute",
    top: "10px",
    right: "20px",
  };

  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(true);
  const [view, setView] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const validation = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validation,
  });

  const signInHandler = async (e) => {
    e.preventDefault();

    try {
      const obj = {
        email: formik.values.email,
        password: formik.values.password,
        remember_me: rememberMe,
      };
      console.log(obj);

      const resp = await makeHttpRequest("post", "login", obj);
      console.log("resp?.code", resp);
      const email = formik.values.email;
      if (resp?.two_step_verification === true) {
        navigate(`/authentication/otp/${encodeURIComponent(email)}`);
        toast.info("OTP has been send to your register email.");
      } else if (resp?.user?.two_step_verification === false) {
        console.log("veriu")
        if(resp?.user?.role === "supportAdmin"){
          navigate("/chat-Management");
        }else{

          navigate("/dashboard");
        }
        toast.success("Login successfully");
        localStorage.setItem("token", resp?.token);
        localStorage.setItem("user",JSON.stringify(resp?.user))
      }
    } catch (e) {
      console.log(e);
      // toast.error(e?.response?.data?.errors?.msg);
    }
  };

  // console.log(authToken)
  // useEffect(()=>{
  //   let timeoutId;
  //   console.log('--------------->hir')

  //   if (authToken) {
  //     const daysInMilliseconds = rememberMe ? 30 * 24 * 60 * 60 * 1000 : 7 * 24 * 60 * 60 * 1000;
  // console.log('token----------------------->')
  //     timeoutId = setTimeout(() => {
  //       localStorage.removeItem('token');
  //     }, 1000);
  //   }

  //   // Cleanup function to clear the timeout when the component is unmounted or when authToken changes
  //   return () => clearTimeout(timeoutId);
  // }, [authToken, rememberMe])

  // const getData = async () => {
  //   try {

  //     const resp = await makeHttpRequest('get', 'getAdminProfile');
  //     setStatus(resp?.data?.two_step_verification)
  //   } catch (error) {
  //     toast.error('Network error.')
  //   }
  // }
  // useEffect(() => {
  //   getData();
  // }, [])
  const handleTogglePasswordVisibility = () => {
    setView((prev) => !prev);
  };

  return (
    <>
      <CoverLayout
        title="Welcome back"
        description="Enter your email and password to sign in"
        image={curved9}
      >
        <SoftBox component="form" role="form">
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Email
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="email"
              name="email"
              placeholder="Email"
              {...formik.getFieldProps("email")}
            />
            {formik.errors.email && formik.touched.email ? (
              <p className="form-error">{formik.errors.email}</p>
            ) : null}
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Password
              </SoftTypography>
            </SoftBox>
            <SoftBox className="softbox-ctner" style={{ position: "relative" }}>
              <SoftInput
                type={`${view ? "text" : "password"}`}
                placeholder="Password"
                name="password"
                {...formik.getFieldProps("password")}
              />
              {view ? (
                <VisibilityIcon
                  style={viewpass}
                  onClick={handleTogglePasswordVisibility}
                />
              ) : (
                <VisibilityOffIcon
                  style={viewpass}
                  onClick={handleTogglePasswordVisibility}
                />
              )}
            </SoftBox>

            {formik.errors.password && formik.touched.password ? (
              <p className="form-error">{formik.errors.password}</p>
            ) : null}
          </SoftBox>
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <SoftBox>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;Remember me
              </SoftTypography>
            </SoftBox>

            <SoftBox textAlign="center">
              <SoftTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                <SoftTypography
                  component={Link}
                  to="/authentication/forgot-password "
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Forgot Password
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={signInHandler}
            >
              sign in
            </SoftButton>
          </SoftBox>
          {/* <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Don&apos;t have an account?{" "}
              <SoftTypography
                component={Link}
                to="/authentication/sign-up"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign up
              </SoftTypography>
            </SoftTypography>
          </SoftBox> */}
        </SoftBox>
      </CoverLayout>
    </>
  );
}

export default SignIn;
