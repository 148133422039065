import {
  Card,
  CardHeader,
  Grid,
  Select,
  Table,
  TablePagination,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useCallback, useEffect, useState } from "react";
import ClinicChargesData from "./data/ClinicChargesData";
import { columns } from "./data/data";
import { CardBody } from "react-bootstrap";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SuiSelect from "components/SuiSelect/SuiSelect";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import { Search } from "@mui/icons-material";
import debounce from "lodash.debounce";

function ClinicCharges() {


  const [rows, setRows] = useState([]);

  const [selectedId, setSelectedId] = useState(null);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState("");

  const [ totalCount , setTotalCount ] = useState(50)

  const [sort , setSort ] = useState('-1')

  const options = [
    { value: "-1", label: "Latest" },
    { value: "1", label: "Oldest" },
  ];



 

  useEffect(() => {
    
    getClinicChargeList();

  }, [currentPage, rowsPerPage, sort]);

  const getClinicChargeList = async (debounceSearch) => {
    const params = {
      offset: currentPage * rowsPerPage,
      limit: rowsPerPage,
      name: debounceSearch || search,
      sort: sort,
    };

    try {
      const resp = await makeHttpRequest(
        "get",
        "getClinicAppointmentPerPrice",
        null,
        params
      );
      console.log("🚀 ~ getClinicChargeList ~ resp:", resp);
      setRows(resp?.data);
      setTotalCount(resp?.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePrice = async (newPrice) => {
    if (!selectedId) {
      console.log("selected id not found");
      return;
    }
    const body = {
      price_per_appointment: newPrice,
    };

    try {
      const resp = await makeHttpRequest(
        "patch",
        `editClinicAppointmentPerPrice/${selectedId}`,
        body
      );
      getClinicChargeList();
      toast.success("Price updated successfully");
      setSelectedId(null);
      setConfirmModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    fetchResults(value); 
  };


  const fetchResults = useCallback(
    debounce((searchValue) => {
      console.log('Fetching results for:', searchValue);
      getClinicChargeList(searchValue)
    }, 500),
    []
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox p={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={2}>
              <SoftTypography variant="h6">Clinic Charges List</SoftTypography>
              <Grid />
            </Grid>
            <Grid item xs={12} md={10}>
              <SoftBox
                className="table_header"
                display="flex"
                flexWrap="wrap"
                alignItems="center"
              >
                <SoftBox display="flex" gap="15px" className="filter_box">
                  <SoftInput
                    type="text"
                    style={{ minWidth: "150px" }}
                    className="form-control"
                    placeholder="Search here"
                    value={search}
                    onChange={handleInputChange}
                  />
                  <SuiSelect
                    placeholder="Choose an option"
                    options={options}
                    defaultValue={sort}
                    onChange={(value)=>setSort(value)}
                  />
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Card className="clinicCharger-table">
          <ClinicChargesData
            columns={columns}
            rows={rows}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            handleChangePrice={handleChangePrice}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={totalCount}
          />
        </Card>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ClinicCharges;
