import SoftBox from 'components/SoftBox'

import SoftTypography from 'components/SoftTypography'
import Footer from 'examples/Footer'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import Card from "@mui/material/Card";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SoftButton from 'components/SoftButton'
import { Link } from 'react-router-dom'
import contained from 'assets/theme/components/button/contained'

export default function ViewFAQ() {

    
    return (
        <DashboardLayout >
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card className="faqTable">
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">FAQ</SoftTypography>
                        </SoftBox>
                        <SoftBox p={4}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <SoftTypography variant="h6" >Accordion 1</SoftTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <SoftTypography variant="h6" >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </SoftTypography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <SoftTypography variant="h6">Accordion 2</SoftTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <SoftTypography variant="h6" >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </SoftTypography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <SoftTypography variant="h6">Accordion 2</SoftTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <SoftTypography variant="h6" >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </SoftTypography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <SoftTypography variant="h6">Accordion 2</SoftTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <SoftTypography variant="h6" >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </SoftTypography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <SoftTypography variant="h6">Accordion 2</SoftTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <SoftTypography variant="h6" >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </SoftTypography>
                                </AccordionDetails>
                            </Accordion>

                        </SoftBox>
                        <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                            <SoftButton component={Link} to="./FaqManagement" color="info" variant="contained">
                                Back
                            </SoftButton>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox >
            {/* <Footer /> */}
        </DashboardLayout >
    )
}
