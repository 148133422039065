/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Link } from "react-router-dom";

function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const UserTableData = {
  columns: [
    { name: "patient", align: "left" },
    // { name: "Consulting Doctor", align: "left" },
    { name: "Clinic", align: "left" },
    { name: "Branch", align: "left" },
    { name: "status", align: "center" },
    { name: "Date", align: "center" },
    { name: "Time", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: []
  //   {
  //     patient: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
  //     "Consulting Doctor": (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Dr.Bernardo James
  //       </SoftTypography>
  //     ),

  //     Clinic: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Historic Core Dental Care
  //       </SoftTypography>
  //     ),

  //     Branch: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Core Dental Care
  //       </SoftTypography>
  //     ),
  //     status: (
  //       <SoftBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
  //     ),
  //     Date: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         23/04/18
  //       </SoftTypography>
  //     ),

  //     Time: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         11:00 AM
  //       </SoftTypography>
  //     ),
  //     action: (
  //       <SoftBox>


  //         <SoftTypography
  //           component={Link}
  //           to="/viewAppointment"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //           marginRight="10px"
  //         >
  //           View
  //         </SoftTypography>

  //         <SoftTypography
  //           component={Link}
  //           to="/editAppointment"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //         >
  //           Edit
  //         </SoftTypography>

  //       </SoftBox>

  //     ),
  //   },

  //   {
  //     patient: <Author image={team3} name="Alexa Liras" email="alexa@creative-tim.com" />,
  //     "Consulting Doctor": (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Dr.Bernardo James
  //       </SoftTypography>
  //     ),

  //     Clinic: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Historic Core Dental Care
  //       </SoftTypography>
  //     ),

  //     Branch: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Core Dental Care
  //       </SoftTypography>
  //     ),
  //     status: (
  //       <SoftBadge variant="gradient" badgeContent="Not Visited" color="secondary" size="xs" container />
  //     ),
  //     Date: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         23/04/18
  //       </SoftTypography>
  //     ),

  //     Time: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         11:00 AM
  //       </SoftTypography>
  //     ),
  //     action: (
  //       <SoftBox>


  //         <SoftTypography
  //           component={Link}
  //           to="/viewAppointment"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //           marginRight="10px"
  //         >
  //           View
  //         </SoftTypography>

  //         <SoftTypography
  //           component={Link}
  //           to="/editAppointment"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //         >
  //           Edit
  //         </SoftTypography>

  //       </SoftBox>
  //     ),
  //   },
  //   {
  //     patient: <Author image={team4} name="Laurent Perrier" email="laurent@creative-tim.com" />,
  //     "Consulting Doctor": (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Dr.Bernardo James
  //       </SoftTypography>
  //     ),

  //     Clinic: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Historic Core Dental Care
  //       </SoftTypography>
  //     ),

  //     Branch: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Core Dental Care
  //       </SoftTypography>
  //     ),
  //     status: (
  //       <SoftBadge variant="gradient" badgeContent="Visited" color="success" size="xs" container />
  //     ),
  //     Date: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         23/04/18
  //       </SoftTypography>
  //     ),

  //     Time: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         11:00 AM
  //       </SoftTypography>
  //     ),
  //     action: (
  //       <SoftBox>


  //         <SoftTypography
  //           component={Link}
  //           to="/viewAppointment"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //           marginRight="10px"
  //         >
  //           View
  //         </SoftTypography>

  //         <SoftTypography
  //           component="a"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //         >
  //           Edit
  //         </SoftTypography>

  //       </SoftBox>
  //     ),
  //   },
  //   {
  //     patient: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
  //     "Consulting Doctor": (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Dr.Bernardo James
  //       </SoftTypography>
  //     ),

  //     Clinic: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Historic Core Dental Care
  //       </SoftTypography>
  //     ),

  //     Branch: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Core Dental Care
  //       </SoftTypography>
  //     ),
  //     status: (
  //       <SoftBadge variant="gradient" badgeContent="Not Visited" color="secondary" size="xs" container />
  //     ),
  //     Date: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         23/04/18
  //       </SoftTypography>
  //     ),

  //     Time: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         11:00 AM
  //       </SoftTypography>
  //     ),
  //     action: (
  //       <SoftBox>


  //         <SoftTypography
  //           component={Link}
  //           to="/viewAppointment"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //           marginRight="10px"
  //         >
  //           View
  //         </SoftTypography>

  //         <SoftTypography
  //           component="a"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //         >
  //           Edit
  //         </SoftTypography>

  //       </SoftBox>
  //     ),
  //   },
  //   {
  //     patient: <Author image={team3} name="Michael Levi" email="michael@creative-tim.com" />,
  //     "Consulting Doctor": (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Dr.Bernardo James
  //       </SoftTypography>
  //     ),

  //     Clinic: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Historic Core Dental Care
  //       </SoftTypography>
  //     ),

  //     Branch: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         Core Dental Care
  //       </SoftTypography>
  //     ),
  //     status: (
  //       <SoftBadge variant="gradient" badgeContent="Visited" color="success" size="xs" container />
  //     ),
  //     Date: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         23/04/18
  //       </SoftTypography>
  //     ),

  //     Time: (
  //       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
  //         11:00 AM
  //       </SoftTypography>
  //     ),
  //     action: (
  //       <SoftBox>


  //         <SoftTypography
  //           component={Link}
  //           to="/viewAppointment"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //           marginRight="10px"
  //         >
  //           View
  //         </SoftTypography>

  //         <SoftTypography
  //           component="a"
  //           href="#"
  //           variant="caption"
  //           color="secondary"
  //           fontWeight="medium"
  //         >
  //           Edit
  //         </SoftTypography>

  //       </SoftBox>
  //     ),
  //   },




  
};

export default UserTableData;
