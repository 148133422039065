import SoftBox from 'components/SoftBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import user01 from "../../assets/images/team-1.jpg"
import { MdModeEdit } from "react-icons/md";
import SoftTypography from 'components/SoftTypography'
import { Card, Grid } from '@mui/material'
import SoftInput from 'components/SoftInput'
import SoftButton from 'components/SoftButton'
import Footer from 'examples/Footer'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { makeHttpRequest } from 'utils/httpServices'
import { toast } from 'react-toastify'

export default function EditUserManagement() {
    const { id } = useParams();
    const [getImg, setGetImg] = useState(null);
    const [selectedImg, setSelectedImg] = useState(null);
    const [urlImg, setUrlImg] = useState(null);



    const handleSubmit = async () => {
        try {

            let img = '';

            if (selectedImg) {
                const formData = new FormData();
                formData.append("path", "userProfile");
                formData.append("media", selectedImg);

                const resp = await makeHttpRequest("post", "uploadAdminMedia", formData);
                img = resp?.path;
            } else {
                img = getImg;
            }

            if (!img) {
                toast.error('Please select profile image.')
            }

            const objData = {
                first_name: formik?.values?.first_name,
                last_name: formik?.values?.last_name,
                phone_number: formik?.values?.phone_number,
                email: formik?.values?.email,
                status: formik?.values?.status.toLowerCase(),
                profile_image: img
            }

            const resp = await makeHttpRequest('patch', `editUser/${id}`, objData);
            console.log(resp)
            toast.success('Added successfully.');
        } catch (error) {
            toast.error('Network Error.');
            console.log(error);
        }
    }


    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        phone_number: Yup.string()
            .required("Phone Number is required")
            .matches(/^[0-9]+$/, "Phone Number must only contain numbers"),
        email: Yup.string().email('Please enter valid email.').required('Email name is required'),
        status: Yup.string().required('Status name is required'),
        profile_image: Yup.string().required('Profile image is required'),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            status: '',
            profile_image: ''
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    const getData = async () => {
        try {
            const resp = await makeHttpRequest('get', `getSingleUser/${id}`);
            console.log(resp?.data?.status);
            const inputString = resp?.data?.status;
            const capitalizedString = inputString.charAt(0).toUpperCase() + inputString.slice(1);


            formik.setValues({
                first_name: resp?.data?.first_name || '',
                last_name: resp?.data?.last_name || '',
                phone_number: resp?.data?.phone_number || '',
                email: resp?.data?.email || '',
                status: capitalizedString || '',
                profile_image: resp?.data?.profile_image
            })

            setGetImg(resp?.data?.profile_image)
        } catch (error) {
            toast.error('Network Error.')
        }
    }

    useEffect(() => {
        getData();
    }, [id]);

    const hanldemediaUpload = async (e) => {
        const file = e.target.files[0];

        setSelectedImg(file)

        const reader = new FileReader();

        reader.onload = function (e) {

            const fileContent = e.target.result;
            setUrlImg(fileContent);
            formik.setFieldValue('profile_image', fileContent)

        };

        reader.readAsDataURL(file);
    }
    return (
        <DashboardLayout >
            <DashboardNavbar />
            <form onSubmit={formik.handleSubmit}>
                <SoftBox py={3}>
                    <SoftBox mb={3}>
                        <Card >
                            <SoftBox p={3}>
                                <SoftBox mb={3} className="upload-image">
                                    <SoftBox className="image-container" >
                                        {selectedImg ? <img src={urlImg} /> : getImg ? <img src={`${process.env.REACT_APP_IMAGE_URL}userProfile/${getImg}`} /> : <img src='https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png' />}
                                    </SoftBox>
                                    <label htmlFor="uploadd55-image"><MdModeEdit /></label>
                                    <input type="file" id="uploadd55-image" onChange={hanldemediaUpload} />

                                </SoftBox>
                                {formik.touched.profile_image &&
                                    formik.errors.profile_image ? (
                                    <div className="form-error1">
                                        {formik.errors.profile_image}
                                    </div>
                                ) : null}
                                <SoftBox mb={3} component="form" role="form" className="appointment_form" >
                                    <SoftTypography variant="h6">User Details</SoftTypography>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12} lg={6} xl={6}>
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        First Name <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="First Name" name='first_name'
                                                    {...formik.getFieldProps('first_name')} />
                                                {formik.touched.first_name &&
                                                    formik.errors.first_name ? (
                                                    <div className="form-error">
                                                        {formik.errors.first_name}
                                                    </div>
                                                ) : null}
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={6} xs={12} lg={6} xl={6}>
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Last Name <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" value="Brack" placeholder="Last Name" name='last_name'
                                                    {...formik.getFieldProps('last_name')} />
                                                {formik.touched.last_name &&
                                                    formik.errors.last_name ? (
                                                    <div className="form-error">
                                                        {formik.errors.last_name}
                                                    </div>
                                                ) : null}
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={6} xs={12} lg={6} xl={6}>
                                            <SoftBox>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Mobile Number <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="tel" value="+91812454412" placeholder="Number" name='phone_number'
                                                    {...formik.getFieldProps('phone_number')} />
                                                {formik.touched.phone_number &&
                                                    formik.errors.phone_number ? (
                                                    <div className="form-error">
                                                        {formik.errors.phone_number}
                                                    </div>
                                                ) : null}
                                            </SoftBox>
                                        </Grid>
                                        <Grid item md={6} xs={12} lg={6} xl={6}>
                                            <SoftBox >
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Email ID <span className='text-danger'>*</span>
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="email" value="DarrenBrack@gmail.com" placeholder="email" name='email'
                                                    {...formik.getFieldProps('email')} />
                                                {formik.touched.email &&
                                                    formik.errors.email ? (
                                                    <div className="form-error">
                                                        {formik.errors.email}
                                                    </div>
                                                ) : null}
                                            </SoftBox>
                                        </Grid>
                                        {/* <Grid item md={6} xs={12} lg={6} xl={6}>
                                        <SoftBox >
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Joining Date <span className='text-danger'>*</span>
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput type="Date" value="DD/MM/YY" placeholder="DD/MM/YY" />
                                        </SoftBox>
                                    </Grid> */}
                                        <Grid item xs={12} md={3}>
                                            <SoftBox display="flex" justifyContent="end" pt={4} >
                                                <FormControl>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Status
                                                    </SoftTypography>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        // defaultValue="Active"
                                                        size="small"
                                                        {...formik.getFieldProps('status')}
                                                    >
                                                        <FormControlLabel value="Active" control={<Radio />} label="Active" />
                                                        <FormControlLabel value="Deactive" control={<Radio />} label="Deactive" />
                                                    </RadioGroup>
                                                    {formik.touched.status &&
                                                        formik.errors.status ? (
                                                        <div className="form-error">
                                                            {formik.errors.status}
                                                        </div>
                                                    ) : null}
                                                </FormControl>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </SoftBox>

                            <SoftBox display="flex" justifyContent="center" alignItems="center" pb={3}>
                                <SoftButton style={{ marginRight: '10px' }} component={Link} to="/userManagement" variant="outlined" color="info">Back</SoftButton>
                                <SoftButton variant="contained" color="info" type='submit'>Save</SoftButton>
                            </SoftBox>
                        </Card>
                    </SoftBox>
                </SoftBox>
            </form>
            {/* <Footer /> */}
        </DashboardLayout>
    )
}
