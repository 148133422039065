import React from "react";
import TablePagination from "@mui/material/TablePagination";

export default function PaginationComponent({ count, onChange, onRowsPerChange }) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value)); 
    setPage(1); 
    onRowsPerChange(parseInt(event.target.value)); 
  };

  return (
    <div className="pagi">
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
