// @mui material components
import Card from "@mui/material/Card";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { Link } from "react-router-dom";

// Data
import UserTableData from "./data/UserTableData";
import { User } from "./data/UserTableData";
import SoftInput from "components/SoftInput";
import { Grid, TablePagination } from "@mui/material";
import SuiSelect from "components/SuiSelect/SuiSelect";
import { useEffect } from "react";
import { makeHttpRequest } from "utils/httpServices";
import SoftBadge from "components/SoftBadge";

import { toast } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "components/DeleteModal/DeleteModal";
import Pagination from "../pagination/index";

// import projectsTableData from "layouts/tables/data/projectsTableData";

function UserSearches() {
  const [rows, setRows] = useState([]);
  const { columns } = UserTableData;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleDeleteConfirm = async (id) => {
    try {
      const resp = await makeHttpRequest("delete", `deleteQuestionire/${id}`);
      toast.success("Deleted successfully");
      console.log(resp);
      getData();
      setDeleteModalOpen(false);
    } catch (e) {
      toast.error("Network Error");
      console.log(e);
    }
  };

  const deleteHandler = (value) => {
    setDeleteId(value);
    setDeleteModalOpen(true);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    console.log(`Selected page: ${value}`);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const getData = async (val) => {
    try {
      const offset = currentPage* rowsPerPage;
     
      const param = {
        name: val,
        sort: -1,
        limit: rowsPerPage,
        offset: offset,
      };
      const resp = await makeHttpRequest(
        "get",
        "getQuestionireListing",
        null,
        param
      );
      console.log(resp);
      setTotalCount(resp?.count ?? 0);
      const count1 = Math.ceil(resp?.count / 10);
      setTotalPage(count1);
      let row_data = resp?.data?.map((val) => ({
        email: <User email={val?.email} />,
        // Number: (
        //   <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        //     {val?.phone_number}
        //   </SoftTypography>
        // ),
        // type: (
        //   <SoftBadge variant="gradient" badgeContent="Loged in" color={val?.status === "active" ? "success" : "error"} size="xs" container />
        // ),
        time: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {new Date(val?.createdAt).toLocaleString()}
          </SoftTypography>
        ),
        action: (
          <SoftBox>
            <SoftTypography
              component={Link}
              to={`/view-UserSearch/${val?._id}`}
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              marginRight="10px"
            >
              View
            </SoftTypography>

            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              onClick={() => deleteHandler(val?._id)}
            >
              Delete
            </SoftTypography>
          </SoftBox>
        ),
      }));
      setRows(row_data);
      console.log(resp);
    } catch (error) {
      console.log(error);
      toast.error("Network Error.");
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getData(search);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [search, currentPage, limit]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <Grid item xs={12} md={8}>
                <SoftBox
                  className="table_header"
                  display="flex"
                  alignItems="center"
                >
                  <SoftTypography variant="h6">Email</SoftTypography>
                  <SoftBox
                    ml={2}
                    className="filter_box"
                    display="flex"
                    gap="15px"
                  >
                    <SoftInput
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </SoftBox>
                </SoftBox>
              </Grid>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
              <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={() => handleDeleteConfirm(deleteId)}
              />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
export default UserSearches;
