/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Link } from "react-router-dom";

export function User({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        {/* <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography> */}
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}



const UserTableData = {
  columns: [
    { name: "email", align: "left" },
    // { name: "type", align: "center" },
    { name: "time", align: "center" },
    { name: "action", align: "center" },
  ],

  rows:[]
};

export default UserTableData;
