

import { useState } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { makeHttpRequest } from "utils/httpServices";
import { useNavigate } from "react-router-dom";


function ResetPassword() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [agreement, setAgremment] = useState(true);

    const handleSetAgremment = () => setAgremment(!agreement);

    const reserPassHandler = async (e) => {
        console.log(id)
        e.preventDefault();
        try {
            const data = {
                verification: id,
                password: formik?.values?.newPassword
            }

            const resp = await makeHttpRequest('patch', 'resetForgotPassword', data);
            toast.success('Password changed successfully')
            navigate('/authentication/sign-in')
            console.log(resp)
        } catch (e) {
            toast.error('Network Error');
        }
    }

    const validationSchema = Yup.object().shape({

        newPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm Password is required')

    })

    const formik = new useFormik({

        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema

    });



    return (
        <BasicLayout
            title="Welcome!"
            image={curved6}
        >
            <Card>
                <SoftBox p={3} mb={1} textAlign="center">
                    <SoftTypography variant="h5" fontWeight="medium">
                        Reset Password
                    </SoftTypography>
                </SoftBox>
                <SoftBox pt={2} pb={3} px={3}>
                    <SoftBox component="form" role="form">

                        <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    Enter New Password <span className='text-danger'>*</span>
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput type="text" placeholder="Enter New Password" name='newPassword'
                                {...formik.getFieldProps('newPassword')}
                            />
                            {formik.errors.newPassword && formik.touched.newPassword ? (<p className='form-error'>
                                {formik.errors.newPassword}
                            </p>) : null}
                        </SoftBox>
                        <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    Confirm New Password <span className='text-danger'>*</span>
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput type="text" placeholder="Confirm New Password" name='confirmPassword'
                                {...formik.getFieldProps('confirmPassword')} />
                            {formik.errors.confirmPassword && formik.touched.confirmPassword ? (<p className='form-error'>
                                {formik.errors.confirmPassword}
                            </p>) : null}
                        </SoftBox>

                        <SoftBox mt={3} textAlign="center">
                            <SoftButton type="contained" fullWidth color="info" onClick={reserPassHandler} >Reset Now</SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </Card>
        </BasicLayout>
    );
}

export default ResetPassword;
