import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftInput from 'components/SoftInput';
import { WidthFull } from '@mui/icons-material';
import SoftTextArea from 'components/SoftTextArea/SoftTextArea';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { FaCloudUploadAlt } from 'react-icons/fa';
import user01 from "assets/images/team-1.jpg"
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { makeHttpRequest } from 'utils/httpServices'

export default function DialogBoxFAQ2({ handleClose, open, boxId, setUserTableData, userTableData, handleClickOpen, setBoxId, handleDeleteClick}) {


    const validationSchema = yup.object({
        question: yup.string().required('Question is required'),
        answer: yup.string().required('Answer is required')
    })

    const formik = useFormik({
        initialValues: {
            question: '',
            answer: '',
            type: 'support',
        },
        validationSchema: validationSchema
    });

    const submitHandler = async (e) => {
        e.preventDefault();
        try {


            const objData = {
                question: formik?.values?.question,
                answer: formik?.values?.answer,
                type: 'support',

            }

            const resp = await makeHttpRequest('post', 'addFaq', objData);
            console.log(resp);

            const Resp = await makeHttpRequest('get', 'getFaqs', null, { type: 'support', sort: 1 });
            console.log(Resp?.item)

            setUserTableData({
                ...userTableData,  // Copy the existing state properties
                rows: Resp?.item.map((val) => ({
                    // Image: (
                    //     <SoftBox>
                    //         <img src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${val?.image}`} width={100} height={100} style={{ objectFit: "cover" }} alt="user" />
                    //     </SoftBox>
                    // ),
                    questions: (
                        <SoftTypography className="Question data" variant="h6">{val?.question}</SoftTypography>
                    ),
                    answer: (
                        <SoftTypography className="Answer data" variant="h6">{val?.answer}</SoftTypography>
                    ),
                    action: (<SoftBox>
                        {/* <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                        //   onClick={handleOpenModal}
                        >
                            View
                        </SoftTypography> */}
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                            onClick={() => { handleClickOpen(); setBoxId(val?._id); }}
                        >
                            Edit
                        </SoftTypography>
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            onClick={() => handleDeleteClick(val)}
                        >
                            Delete
                        </SoftTypography>
                    </SoftBox>)
                }))
            });
            toast.success('Added successfully.');

        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }




    }

    const editHandler = async (e) => {
        e.preventDefault();
        try {
          

            const objData = {
                question: formik?.values?.question,
                answer: formik?.values?.answer,
                type: 'support',

            }

            const Resp = await makeHttpRequest('patch', `updateFaq/${boxId}`, objData);
            console.log(Resp);
            toast.success('Updated successfully.');
            const Resp1 = await makeHttpRequest('get', 'getFaqs', null, { type: 'support', sort: 1 });
            console.log(Resp?.item)

            setUserTableData({
                ...userTableData,  // Copy the existing state properties
                rows: Resp1?.item.map((val) => ({
                    // Image: (
                    //     <SoftBox>
                    //         <img src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${val?.image}`} width={100} height={100} style={{ objectFit: "cover" }} alt="user" />
                    //     </SoftBox>
                    // ),
                    questions: (
                        <SoftTypography className="Question data" variant="h6">{val?.question}</SoftTypography>
                    ),
                    answer: (
                        <SoftTypography className="Answer data" variant="h6">{val?.answer}</SoftTypography>
                    ),
                    action: (<SoftBox>
                        {/* <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                        //   onClick={handleOpenModal}
                        >
                            View
                        </SoftTypography> */}
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                            onClick={() => { handleClickOpen(); setBoxId(val?._id); }}
                        >
                            Edit
                        </SoftTypography>
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            onClick={() => handleDeleteClick(val)}
                        >
                            Delete
                        </SoftTypography>
                    </SoftBox>)
                }))
            });

        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }
    }

    const getData = async () => {
        if (boxId != '') {

            try {

                const resp = await makeHttpRequest('get', `getFaqDetails/${boxId}`);
                formik.setValues({
                    question: resp?.data?.question || '',
                    answer: resp?.data?.answer || ''
                })

            } catch (e) {
                console.log(e);
            }
        } else return;
    }
    useEffect(() => {

        getData();

    }, [boxId])
    return (
        <div>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        minWidth: '600px',
                        minHeight: '400px',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add Question"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <SoftBox component="form" role="form" className="">
                            {/* <SoftBox className="Image_Wrappr">
                                <SoftBox mb={3} className="Image_Wrappr_inner" style={{
                                    margin: 'auto', height: '200px', width: '200px'
                                }}>
                                    <SoftBox className="image-container" >
                                        {selectedImg ? (<img src={urlImg} width={100} height={100} />) :
                                            image ? (<img src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${image}`} width={100} height={100} />)
                                                : (<img src='https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png'
                                                    width={100} height={100} alt='' />)
                                        }
                                    </SoftBox>
                                    <label htmlFor="upload-image1"><FaCloudUploadAlt /></label>
                                    <input type="file" id="upload-image1" onChange={imgHandler} />

                                </SoftBox>

                            </SoftBox> */}
                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Enter Question
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="email" placeholder="Write Your Question Here" name='question'
                                    {...formik.getFieldProps('question')}
                                />
                                {formik.errors.question && formik.touched.question ? (<p className='form-error'>
                                    {formik.errors.question}
                                </p>) : null}

                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Enter Answer
                                    </SoftTypography>
                                </SoftBox>
                                <SoftTextArea
                                    placeholder="Enter your text here"
                                    rows={6}
                                    cols={50} name='answer'
                                    {...formik.getFieldProps('answer')}
                                />
                                {formik.errors.answer && formik.touched.answer ? (<p className='form-error'>
                                    {formik.errors.answer}
                                </p>) : null}

                            </SoftBox>

                        </SoftBox>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    {boxId ? <Button onClick={(e) => {
                        editHandler(e);
                        handleClose()
                    }} autoFocus>
                        Done
                    </Button> : <Button onClick={(e) => {
                        submitHandler(e);
                        handleClose()
                    }} autoFocus>
                        Done
                    </Button>}
                </DialogActions>
            </Dialog>

        </div>
    )
}
