// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from 'react'


// editor
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SoftButton from "components/SoftButton";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import SoftInput from "components/SoftInput";


function TermCond() {
    const [title,setTitle] = useState('')

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };


    const handleSave = async () => {
        try {

            if(title == ''){
                toast.error("Please fill title.");
                return;
            }

            const contentState = editorState.getCurrentContent();
           
            const data = draftToHtml(convertToRaw(contentState));

            if(data.trim() === '' || data.trim() === '<p></p>'){
                toast.error('Please add data.');
                return;
            }

           

            const objDta = {
                title: title,
                description: data
            }

            const resp = await makeHttpRequest('put', 'addTermsOfUse', objDta);
            console.log(resp);
            toast.success('Added successfully.')
        } catch (e) {
            console.log(e);
        }

    }

    const getData = async () => {
        try {

            const resp = await makeHttpRequest('get', 'getTermsOfUse');
            setTitle(resp?.data?.title)
            console.log(resp)
            const contentBlock = htmlToDraft(resp?.data?.description);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorStateFromApi = EditorState.createWithContent(contentState);
                setEditorState(editorStateFromApi);
            }
        } catch (e) {
            toast.error('Network Error.')
            console.log(e)
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        {/* <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                            <SoftTypography variant="h5"  >{title}</SoftTypography>
                        </SoftBox> */}

                        <SoftBox p={3}>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Title <span className='text-danger'>*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput type="text" placeholder="title" value={title} onChange={(e)=>setTitle(e.target.value)}/>
                         
                        </SoftBox>
                        
                        <SoftBox className="editoCss">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </SoftBox>

                        <SoftBox display="flex" justifyContent="center" alignItems="center" pb={3}>
                            <SoftButton variant="contained" color="info" onClick={handleSave}>Save</SoftButton>
                        </SoftBox>

                    </Card>
                </SoftBox>

            </SoftBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default TermCond;
