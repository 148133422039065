/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Link } from "react-router-dom";

export function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}


const UserTableData = {
  columns: [
    { name: "author", align: "left" },
    { name: "rating", align: "center" },
    { name: "dentist", align: "center" },
    { name: "reviews", align: "left" },
    { name: "created by", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: [
    {
      author: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
      rating: (
        <SoftTypography variant="caption" color="secondary">
          {'★'.repeat(4)}{'☆'.repeat(1)} (4.0)
        </SoftTypography>
      ),
      reviews: (
        <SoftTypography className="reviewDescription" variant="caption" color="secondary" fontWeight="medium">
          Great service and very responsive.
        </SoftTypography>
      ),
      "created by": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          created by admin
        </SoftTypography>
      ),
      action: (

        <SoftBox>
          <SoftTypography
            component={Link}
            to="../edit-review"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            Edit
          </SoftTypography>
          <SoftTypography
            component={Link}
            to="../view-review"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            View
          </SoftTypography>

          <SoftTypography
            href="#"
            component={Link}
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            Delete
          </SoftTypography>

        </SoftBox>


      ),
    },
    {
      author: <Author image={team3} name="John Michael" email="john@creative-tim.com" />,
      rating: (
        <SoftTypography variant="caption" color="secondary">
          {'★'.repeat(4)}{'☆'.repeat(1)} (4.0)
        </SoftTypography>
      ),
      reviews: (
        <SoftTypography className="reviewDescription" variant="caption" color="secondary" fontWeight="medium">
          Great service and very responsive.
        </SoftTypography>
      ),
      "created by": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          left by user
        </SoftTypography>
      ),
      action: (

        <SoftBox>
          <SoftTypography
            component={Link}
            to="../edit-review"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            Edit
          </SoftTypography>
          <SoftTypography
            component={Link}
            to="../view-review"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            View
          </SoftTypography>

          <SoftTypography
            href="#"
            component={Link}
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            Delete
          </SoftTypography>

        </SoftBox>
      ),
    },
    {
      author: <Author image={team4} name="John Michael" email="john@creative-tim.com" />,
      rating: (
        <SoftTypography variant="caption" color="secondary">
          {'★'.repeat(4)}{'☆'.repeat(1)} (4.0)
        </SoftTypography>
      ),
      reviews: (
        <SoftTypography className="reviewDescription" variant="caption" color="secondary" fontWeight="medium">
          Great service and very responsive.
        </SoftTypography>
      ),
      "created by": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          created by admin
        </SoftTypography>
      ),
      action: (

        <SoftBox>
          <SoftTypography
            component={Link}
            to="../edit-review"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            Edit
          </SoftTypography>
          <SoftTypography
            component={Link}
            to="../view-review"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            View
          </SoftTypography>

          <SoftTypography
            href="#"
            component={Link}
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            Delete
          </SoftTypography>

        </SoftBox>
      ),
    },
    {
      author: <Author image={team4} name="John Michael" email="john@creative-tim.com" />,
      rating: (
        <SoftTypography variant="caption" color="secondary">
          {'★'.repeat(4)}{'☆'.repeat(1)} (4.0)
        </SoftTypography>
      ),
      reviews: (
        <SoftTypography className="reviewDescription" variant="caption" color="secondary" fontWeight="medium">
          Great service and very responsive.
        </SoftTypography>
      ),
      "created by": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          left by user
        </SoftTypography>
      ),
      action: (

        <SoftBox>
          <SoftTypography
            component={Link}
            to="../edit-review"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            Edit
          </SoftTypography>
          <SoftTypography
            component={Link}
            to="../view-review"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            View
          </SoftTypography>

          <SoftTypography
            href="#"
            component={Link}
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"

          >
            Delete
          </SoftTypography>

        </SoftBox>
      ),
    },
    // Add more user rows here as needed
  ],
}




// const UserTableData = {
//   columns: [
//     { name: "author", align: "left" },
//     { name: "Rating", align: "center" },
//     { name: "Description", align: "left" },
//     { name: "Joined", align: "center" },
//     { name: "action", align: "center" },
//   ],

//   rows: [
//     {
//       author: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
//       Description: (
//         <SoftTypography className="reviewDescription" variant="caption" color="secondary" fontWeight="medium">
//           loremipsum
//         </SoftTypography>
//       ),
//       Rating: (
//         <SoftTypography className="reviewDescription" variant="caption" color="secondary" fontWeight="medium">
//           {user.reviews}
//         </SoftTypography>
//         ),
//       Joined: (
//         <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//           23/04/18
//         </SoftTypography>
//       ),
//       action: (
//         <SoftTypography
//           component="a"
//           href="#"
//           variant="caption"
//           color="secondary"
//           fontWeight="medium"
//         >
//           Edit
//         </SoftTypography>
//       ),
//     },
//     {
//       author: <Author image={team3} name="Alexa Liras" email="alexa@creative-tim.com" />,
//       Designation: <Function job="Programator" org="Developer" />,
//       status: (
//         <SoftBadge variant="gradient" badgeContent="offline" color="secondary" size="xs" container />
//       ),
//       Joined: (
//         <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//           11/01/19
//         </SoftTypography>
//       ),
//       action: (
//         <SoftTypography
//           component="a"
//           href="#"
//           variant="caption"
//           color="secondary"
//           fontWeight="medium"
//         >
//           Edit
//         </SoftTypography>
//       ),
//     },
//     {
//       author: <Author image={team4} name="Laurent Perrier" email="laurent@creative-tim.com" />,
//       Designation: <Function job="Executive" org="Projects" />,
//       status: (
//         <SoftBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
//       ),
//       Joined: (
//         <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//           19/09/17
//         </SoftTypography>
//       ),
//       action: (
//         <SoftTypography
//           component="a"
//           href="#"
//           variant="caption"
//           color="secondary"
//           fontWeight="medium"
//         >
//           Edit
//         </SoftTypography>
//       ),
//     },
//     {
//       author: <Author image={team3} name="Michael Levi" email="michael@creative-tim.com" />,
//       Designation: <Function job="Programator" org="Developer" />,
//       status: (
//         <SoftBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
//       ),
//       Joined: (
//         <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//           24/12/08
//         </SoftTypography>
//       ),
//       action: (
//         <SoftTypography
//           component="a"
//           href="#"
//           variant="caption"
//           color="secondary"
//           fontWeight="medium"
//         >
//           Edit
//         </SoftTypography>
//       ),
//     },
//   ],
// };

export default UserTableData;



