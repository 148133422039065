import { Card, Grid } from '@mui/material'

import SoftBox from 'components/SoftBox'

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'


import Doctor01 from "assets/images/team-1.jpg"
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import SoftTypography from 'components/SoftTypography'
import SoftInput from 'components/SoftInput'
import SoftTextArea from 'components/SoftTextArea/SoftTextArea'
import ProfileInfoCard from './ProfileInfoCard'
import PlatformSettings from './PlatformSettings'
import SoftButton from 'components/SoftButton'
import UserTableData from './data/UserTableData'
import Table from 'examples/Tables/Table'
import SuiSelect from 'components/SuiSelect/SuiSelect'
import DefaultProjectCard from './DefaultProjectCard'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { makeHttpRequest } from 'utils/httpServices'
import DatePicker from 'react-datepicker';
import SoftBadge from "components/SoftBadge";
import { Link } from "react-router-dom";
import SoftAvatar from "components/SoftAvatar";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}






export default function ProviderProfile() {
  const { id } = useParams();
  const [data, setData] = useState(null);

  const { columns} = UserTableData;

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [inpText, setInpText] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [userData, setUserData] = useState([]);
  // const [dentistName, setDentistName] = useState('')

  function Author({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatDateTime = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const localDateString = utcDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
    const localTimeString = utcDate.toLocaleTimeString('en-US', { timeZone: 'UTC' });

    return { formattedDate: localDateString, formattedTime: localTimeString };
  };

  const getPatientsList = async (id, name, month, year) => {
    try {

      const params = {
        dentist_id: id,
        patient_name: name,
        year: year,
        month: month,
        sort: -1,
      }

      const resp = await makeHttpRequest('get', 'patientList', null, params);
      console.log(resp?.data)
      setUserData(resp?.data?.map((val)=>{
        return {
          patient: <Author image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.user_details?.profile_image}`} name={val?.user_details?.full_name} email={val?.user_details?.email} />,
          // "Consulting Doctor": (
          //   <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          //     {dentistName}
          //   </SoftTypography>
          // ),
    
          Clinic: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {val?.clinic_details?.full_name}
            </SoftTypography>
          ),
          Branch: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {val?.branch_details?.full_name}
            </SoftTypography>
          ),
          status: (
            <SoftBadge variant="gradient" badgeContent={val?.status == 'accepted'?"Visited":'Not Visited'} color={val?.status == 'accepted'?"success":"error"} size="xs" container />
          ),
          Date: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {formatDateTime(val?.start_time).formattedDate}
            </SoftTypography>
          ),
    
          Time: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
              {formatDateTime(val?.start_time).formattedTime}
            </SoftTypography>
          ),
          action: (
            <SoftBox>
    
    
              <SoftTypography
                component={Link}
                to={`/viewUserManagement/${val?.user_details?._id}`}
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                marginRight="10px"
              >
                View
              </SoftTypography>
    
              {/* <SoftTypography
                component={Link}
                to="/editAppointment"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                Edit
              </SoftTypography> */}
    
            </SoftBox>
    
          ),
        }
      }))
    } catch (error) {
      toast.error('Network error.');
      console.log(error)
    }
  }

  const getData = async () => {
    try {
      const resp = await makeHttpRequest('get', `getSingleDentist/${id}`);
      console.log(resp)
      // setDentistName(resp?.data[0]?.full_name)
      // console.log(resp?.data[0]?.full_name)
      setData(resp?.data[0]);
    } catch (error) {
      toast.error('Network Error.');
      // console.log(error)
    }
  }

  useEffect(() => {

    if (id !== null) {
      getData();
    }
  }, [id])

  useEffect(() => {
    if (id !==null) {
      const x = setTimeout(() => {

        getPatientsList(id, inpText, month, year);
      }, 1000);

      return () => clearTimeout(x);
    }

  }, [id, inpText, year, month])

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const searchHandle = async (text, date) => {
    setSelectedDate(date);
    setInpText(text)
    if (date !== null) {
      setMonth(date.getMonth() + 1);
      setYear(date.getFullYear())
    }
  };
  return (


    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid spacing={2} container >
            <Grid item xs={12} md={4} xl={4}>
              <DefaultProjectCard
                image={data ? `${process.env.REACT_APP_IMAGE_URL}userProfile/${data?.profile_image}` : Doctor01}
                label="Dentist"
                title={data ? data?.fullName : ''}
                description={data ? data?.designation : ''}
                action={{

                }}
              />
            </Grid>

            <Grid item xs={12} md={8} xl={8}>
              <Card  >
                <SoftBox p={3}>
                  <Box sx={{ bgcolor: 'background.paper', }}>
                    <AppBar position="static">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Personal Information" {...a11yProps(0)} />
                        {/* <Tab label="Settings" {...a11yProps(1)} /> */}

                      </Tabs>
                    </AppBar>

                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <ProfileInfoCard
                        title="profile information"
                        description=""
                        info={{
                          fullName: data ? data?.full_name : '',
                          // mobile: data?data?.phone_number:'',
                          email: data ? data?.email : "",
                          Experience: data ? data?.experience + " Years of Experience" : '',
                          Designation: data ? data?.designation : '',
                          "Joining Date": (() => {
                            // Assuming "Joining Date" is a property in the data object
                            const utcJoiningDate = data ? data?.createdAt : '';

                            // Create a Date object from the UTC date string
                            const utcDate = new Date(utcJoiningDate);

                            // Convert UTC date to local date string
                            return utcDate.toLocaleDateString();
                          })(),
                          // location: data ? data?.address : '',
                          branch: data ? data?.branch?.practice_name : ''
                        }}
                        Branch=""
                        action={{ route: "", tooltip: "Edit Profile" }}
                      />
                    </TabPanel>
                    {/* <TabPanel value={value} index={1} dir={theme.direction}>
                      <SoftBox p={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <SoftBox >
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                  Full Name <span className='text-danger'>*</span>
                                </SoftTypography>
                              </SoftBox>
                              <SoftInput type="text" placeholder="First Name" />
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12}>
                            <SoftBox>
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                  Email <span className='text-danger'>*</span>
                                </SoftTypography>
                              </SoftBox>
                              <SoftInput type="text" placeholder="Email" />
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12}>
                            <SoftBox>
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                  Mobile  <span className='text-danger'>*</span>
                                </SoftTypography>
                              </SoftBox>
                              <SoftInput type="text" placeholder="+1 23 456890" />
                            </SoftBox>
                          </Grid>

                          <Grid item xs={12} >
                            <SoftBox >
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                  Add Address <span className='text-danger'>*</span>
                                </SoftTypography>
                              </SoftBox>
                              <SoftTextArea placeholder={"Enter Your Full Address"}
                                rows={4}
                                cols={4} />
                            </SoftBox>
                          </Grid>


                        </Grid>

                        <SoftBox mb={4}>
                          <PlatformSettings />
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="start" alignItems="start" pb={3}>
                          <SoftButton style={{ marginRight: '10px' }} variant="contained" color="info">Apply</SoftButton>
                          <SoftButton variant="outlined" color="info">Cancel</SoftButton>
                        </SoftBox>

                      </SoftBox>

                    </TabPanel> */}


                  </Box>

                </SoftBox>

              </Card>

            </Grid>

          </Grid>


        </SoftBox>

        <SoftBox py={3}>
          <SoftBox mb={3}>
            <SoftBox display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center" p={3}>
              <Grid container spacing={2} >
                <Grid item xs={12} md={8}>
                  <SoftBox className="table_header" display="flex" alignItems="center" >
                    <SoftTypography variant="h6">Patient List</SoftTypography>
                    <SoftBox ml={2} className="filter_box" display="flex" gap="15px"  >

                      <SoftInput type="text"
                        className="form-control"
                        placeholder="Search here"
                        name="Patient Name"
                        value={inpText} onChange={(e) => searchHandle(e.target.value, selectedDate)} />

                      <DatePicker
                        selected={selectedDate}
                        renderMonthContent={renderMonthContent}
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        onChange={
                          (e) => searchHandle(inpText, e)
                        }
                      />

                    </SoftBox>
                  </SoftBox>
                </Grid>

              </Grid>

            </SoftBox>
            <Card>

              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table columns={columns} rows={userData} />
              </SoftBox>
            </Card>
          </SoftBox>

        </SoftBox>

      </SoftBox>
    </DashboardLayout>


  )
}
