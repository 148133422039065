import React, { useState } from "react";
import { Select as MuiSelect, MenuItem } from "@mui/material";

const SuiSelect = ({ placeholder, options, defaultValue, onChange, ...restProps }) => {

    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
    };


    return (
        <MuiSelect  value={selectedValue} onChange={handleChange} label={placeholder} {...restProps}>
            {options?.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                </MenuItem>
            ))}
        </MuiSelect>    
    );
};

export default SuiSelect;
