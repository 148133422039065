/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";






// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components

import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";


import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Header from "./components/Header";

import Slider from "react-slick";
import ImageSlider from "components/Slider/ImageSlider";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";


function Branch() {
  const { id } = useParams();
  const [data, setData] = useState('');
  const [providerData, setProviderData] = useState([]);


  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  const getData = async()=>{
    try {
      
      const resp = await makeHttpRequest('get', `getSingleBranch/${id}`);
      console.log(resp)
      setData(resp?.data)

      const param = {
        branch_id: id,
        sort: 1
      }
      const Resp = await makeHttpRequest('get', 'getDentistListing', null, param);
      setProviderData(Resp?.data)
      console.log(Resp)
    } catch (error) {
      toast.error('Network error.');
      // console.log(error);
    }
  }

  useEffect(() => {
    if (id !== null) {
      getData();
    }
  }, [id])
  return (
    <DashboardLayout>
      <Header data={data}/>
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="profile information"
              description=""
              info={{
                fullName: data? data?.full_name:"",
                mobile: data? data?.phone_number:'',
                email: data? data?.email:'',
                location: data? data?.address:'',
                bio:data? data?.bio:''
              }}
              // social={[
              //   {
              //     link: "https://www.facebook.com/CreativeTim/",
              //     icon: <FacebookIcon />,
              //     color: "facebook",
              //   },
              //   {
              //     link: "https://twitter.com/creativetim",
              //     icon: <TwitterIcon />,
              //     color: "twitter",
              //   },
              //   {
              //     link: "https://www.instagram.com/creativetimofficial/",
              //     icon: <InstagramIcon />,
              //     color: "instagram",
              //   },
              // ]}
              action={{ route: "", tooltip: "Edit Profile" }}
            />
          </Grid>
          {/* <Grid item xs={12} xl={4}>
            <ProfilesList title="conversations" profiles={profilesListData} />
          </Grid> */}

          <Grid item xs={12} xl={4}>
            <ImageSlider data={data?data?.clinic_documents:''}/>
          </Grid>
        </Grid>


      </SoftBox>

      <SoftBox mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <SoftTypography variant="h6" fontWeight="medium">
              Dentists
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Dentists List
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={3} pt={2}>
            <Slider {...settings}>
              {/* Your slide content goes here */}
              {providerData.map((val)=>(<SoftBox className="provider_slider" >
                <DefaultProjectCard
                  image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.profile_image}`}
                  label="Dentist"
                  title={val?.full_name}
                  description={val?.designation}
                  action={{
                    type: "internal",
                    route: `/providerProfile/${val?._id}`,
                    color: "info",
                    label: "view profile",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>))}
              {/* <SoftBox className="provider_slider" >
                <DefaultProjectCard
                  image={team2}
                  label="Dentist"
                  title="Mark Hay Smith"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/providerProfile",
                    color: "info",
                    label: "view profile",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>

              <SoftBox className="provider_slider">
                <DefaultProjectCard
                  image={team3}
                  label="Dentist"
                  title="Dr.Smith Bruklin"
                  description="Different people have different taste, and various types of music."
                  action={{
                    type: "internal",
                    route: "/providerProfile",
                    color: "info",
                    label: "view profile",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>

              <SoftBox className="provider_slider">
                <DefaultProjectCard
                  image={team4}
                  label="Branch #1"
                  title="Cristina Groves"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/providerProfile",
                    color: "info",
                    label: "view profile ",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>
              <SoftBox className="provider_slider" >
                <DefaultProjectCard
                  image={team4}
                  label="Branch #1"
                  title="Cristina Groves"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/providerProfile",
                    color: "info",
                    label: "view profile ",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>
              <SoftBox className="provider_slider" >
                <DefaultProjectCard
                  image={team4}
                  label="Branch #1"
                  title="Cristina Groves"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/providerProfile",
                    color: "info",
                    label: "view profile ",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox> */}


            </Slider>

          </SoftBox>
        </Card>
      </SoftBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Branch;
