

import { useState } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


function AuthOtp() {
    const { email } = useParams();
    // const [agreement, setAgremment] = useState(true);
    const [otp, setOtp] = useState('');

  const navigate = useNavigate();

    console.log(email)
    // const handleSetAgremment = () => setAgremment(!agreement);

    const sendMail = async (e) => {

        e.preventDefault();

        try {

            const data = {
                email: email,
            }

            const resp = await makeHttpRequest('post', 'resendOtp', data);
            console.log(resp)
            toast.success('OTP has been send to your register email.')
            console.log(resp);
        } catch (e) {
            toast.error('Failed to send otp. Please try again later.');
        }
    }

    const verifyMail = async (e) => {
        e.preventDefault();
        try {

            if (!otp) {
                toast.error('Please fill otp.')
                return
            }

            const body = {
                email: email,
                otp: otp
            }

            console.log('sfgdfcbdfbgfg')
            const resp = await makeHttpRequest('post', 'verifyOtp', body);
            setOtp('');

            console.log(resp)
            if (resp?.user) {
                localStorage.setItem('token', resp?.token);
                localStorage.setItem("user",JSON.stringify(resp?.user))
                if(resp?.user?.role === "supportAdmin"){
                    navigate("/chat-Management");
                  }else{
          
                    navigate("/dashboard");
                  }
                toast.success('Login successfully');
            }
        } catch (error) {
            toast.error(error?.response?.data?.errors?.msg)
            console.log(error)
        }
    }
    return (
        <BasicLayout
            title="Two Step Authentication!"
            image={curved6}
        >
            <Card>
                <SoftBox p={3} mb={1} textAlign="center">
                    <SoftTypography variant="h5" fontWeight="medium">
                        Enter OTP
                    </SoftTypography>
                </SoftBox>
                <SoftBox pt={2} pb={3} px={3}>
                    <SoftBox component="form" role="form">

                        <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    OTP <span className='text-danger'>*</span>
                                </SoftTypography>
                            </SoftBox>
                            <SoftInput type="TEXT" placeholder="OTP" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                        </SoftBox>
                        <SoftBox mt={3} textAlign="center">
                            <SoftButton type="contained" fullWidth color="info" onClick={verifyMail}>Submit</SoftButton>
                        </SoftBox>

                        <SoftBox mt={3} textAlign="center">
                            <SoftButton type="contained" fullWidth color="info" onClick={sendMail}>Resend Otp</SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </Card>
        </BasicLayout>
    );
}

export default AuthOtp;
