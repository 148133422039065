import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import SoftBadge from "components/SoftBadge";

import UserTableData from "./data/UserTableData";
import { Link } from "react-router-dom";
import SoftInput from "components/SoftInput";
import SuiSelect from "components/SuiSelect/SuiSelect";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import team2 from "assets/images/team-2.jpg";
import SoftAvatar from "components/SoftAvatar";
import DatePicker from "react-datepicker";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Modal } from "react-bootstrap";
import SoftButton from "components/SoftButton";

import Pagination from "../pagination/index";
import { CiSquareQuestion } from "react-icons/ci";


export default function AppointmentList() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open1, setOpen1] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(10);
  const [limit, setLimit] = useState(10);

  const [requestData, setRequestData] = useState();
  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const { columns } = UserTableData;
  const [data, setData] = useState([]);
  const [row, setRow] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [clinicLists, setClinicLists] = useState([]);
  const [branchList, steBranchList] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [inpText, setInpText] = useState("");

  const formatDateTime = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const localDateString = utcDate.toLocaleDateString();
    const localTimeString = utcDate.toLocaleTimeString();

    return { formattedDate: localDateString, formattedTime: localTimeString };
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    console.log(`Selected page: ${value}`);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  function Author({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const gteLists = async () => {
    try {
      const resp = await makeHttpRequest("get", "clinicList");
      // console.log('resp?.data[0]');
      setClinicLists(resp?.data);
      // const resp1 = await makeHttpRequest('get', )
    } catch (error) {
      // toast.error('Network error.')
    }
  };
  console.log(clinicLists, "clinicList");

  const requestHandler = async (id) => {
    try {
      const resp = await makeHttpRequest(
        "get",
        `getRequestNotToBeChargeList/${id}`
      );
      setRequestData(resp?.data[0]);
      setOpen1(!open1);
      console.log(resp);
    } catch (error) {}
  };

  const getData = async () => {
    console.log(inpText, "asdsjashhj");
    try {
      const offset = currentPage * rowsPerPage;
      const param = {
        clinic_id: selectedClinic,
        branch_id: selectedBranch,
        dentist_id: selectedProvider,
        patient_name: inpText,
        year: year,
        month: month,
        sort: -1,
        offset: offset,
        limit: rowsPerPage,
      };
      const resp = await makeHttpRequest(
        "get",
        "appointmentListWithFilters",
        null,
        param
      );
      console.log("resp-------->", resp);
      setTotalCount(resp?.count);
      const count1 = Math.ceil(resp?.count / 10);
      setTotalPage(count1);
      setRow(
        resp?.data.map((val) => {
          return {
            patient: (
              <Author
                image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.user_details?.profile_image}`}
                name={val?.user_details?.full_name}
                email={val?.user_details?.email}
              />
            ),
            "Consulting Doctor": (
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {val?.dentist_details?.full_name}
              </SoftTypography>
            ),

            Clinic: (
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {val?.clinic_details?.full_name}
              </SoftTypography>
            ),

            Branch: (
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {val?.branch_details?.full_name}
              </SoftTypography>
            ),
            status: (
              <SoftBadge
                variant="gradient"
                badgeContent={
                  val?.is_visited === "visited"
                    ? "Visited"
                    : val?.is_visited === "not visited"
                    ? "Not Visited"
                    : "Pending"
                }
                color={
                  val?.is_visited === "visited"
                    ? "success"
                    : val?.is_visited === "not visited"
                    ? "error"
                    : "warning"
                }
                size="xs"
                container
              />
            ),
            "billing status" : (
              <>
              <SoftBadge
                variant="gradient"
                badgeContent={
                  val?.not_to_be_charged ? "Not charged" :
                   val?.invoice?.status === "payed"
                    ? "Payed"
                    : val?.invoice?.status === "pending"
                    ? "Unpayed"
                    : "Pending"
                    
                }
                color={
                  val?.not_to_be_charged ? "warning" :
                  val?.invoice?.status === "payed"
                    ? "success"
                    : val?.invoice?.status === "pending"
                    ? "error"
                    : "warning"
                }
                size="xs"
                container
              />
              
                {/* <CiSquareQuestion/> */}
                </>
            ),
            Date: (
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {formatDateTime(val?.start_time).formattedDate}
              </SoftTypography>
            ),

            Time: (
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {formatDateTime(val?.start_time).formattedTime}
              </SoftTypography>
            ),
            // "Payment Status": val?.charge ? (
            //   <SoftTypography
            //     className="bookingPaymentSt"
            //     variant="caption"
            //     color="secondary"
            //     fontWeight="medium"
            //   >
            //     Charged{" "}
            //     <ErrorOutlineIcon
            //       onClick={() => requestHandler(val?.charge?._id)}
            //     />
            //   </SoftTypography>
            // ) : null,
            action: (
              <SoftBox>
                <SoftTypography
                  component={Link}
                  to={`/viewAppointment/${val?._id}`}
                  href="#"
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                  marginRight="10px"
                >
                  View
                </SoftTypography>

                {/* <SoftTypography
                  component={Link}
                  to={`/editAppointment/${val?._id}`}
                  href="#"
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                >
                  Edit
                </SoftTypography> */}
              </SoftBox>
            ),
          };
        })
      );
    } catch (error) {
      // toast.error('Network error.');
      // console.log(error)
    }
  };

  useEffect(() => {
    const x = setTimeout(() => {
      getData();
    }, 1000);

    return () => clearTimeout(x);
  }, [
    inpText,
    year,
    month,
    selectedClinic,
    selectedBranch,
    selectedProvider,
    currentPage,
    limit,
  ]);

  useEffect(() => {
    gteLists();
  }, []);

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const clinicHandler = async (cliniId) => {
    try {
      setSelectedClinic(cliniId);
      const resp = await makeHttpRequest("get", `branchList/${cliniId}`);
      // console.log(resp?.data)
      steBranchList(resp?.data);
    } catch (error) {
      // toast.error('Network error.')
    }
  };

  const branchHandler = async (branchId) => {
    try {
      setSelectedBranch(branchId);
      const resp = await makeHttpRequest("get", `dentistList/${branchId}`);
      // console.log(resp?.data)
      setProvidersList(resp?.data);
    } catch (error) {
      // toast.error('Network error.')
    }
  };

  async function sendRequestStatus(value) {
    const body = {
      request_id: requestData?._id,
      action: value,
    };

    try {
      const resp = await makeHttpRequest(
        "post",
        "acceptOrRejectChargeRequest",
        body
      );
      handleClose1();
    } catch (error) {}
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox p={3}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <SoftTypography variant="h6">Appointment List</SoftTypography>
                <Grid />
              </Grid>
              <Grid item xs={12} md={10}>
                <SoftBox
                  className="table_header"
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <SoftBox className="filter_box">
                    <SoftInput
                      type="text"
                      style={{ minWidth: "150px" }}
                      className="form-control"
                      placeholder="Search here"
                      value={inpText}
                      onChange={(e) => {
                        setInpText(e.target.value);
                      }}
                    />
                  </SoftBox>
                  <DatePicker
                    selected={selectedDate}
                    renderMonthContent={renderMonthContent}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    onChange={(e) => {
                      setSelectedDate(e);
                      setMonth(e.getMonth() + 1);
                      setYear(e.getFullYear());
                    }}
                  />
                  {/* <SuiSelect
                    className="filter"
                    options={[
                      { value: "Clinic", label: "Clinic", disabled: true },
                      clinicLists?.map((val) => ({
                        value: val?._id,
                        label: val?.practice_name,
                      })),
                    ]}
                    defaultValue="Clinic"
                    onChange={(selectedValue) => {
                      clinicHandler(selectedValue);
                    }}
                  /> */}
                  <Select
                    className="filter"
                    defaultValue="Clinic"
                    onChange={(event) => {
                      clinicHandler(event.target.value);
                    }}
                  >
                    <MenuItem value="Clinic" disabled>
                      Clinic
                    </MenuItem>
                    {clinicLists?.map((val) => (
                      <MenuItem key={val?._id} value={val?._id}>
                        {val?.practice_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    className="filter"
                    defaultValue="Branch"
                    onChange={(event) => {
                      branchHandler(event.target.value);
                    }}
                  >
                    <MenuItem value="Branch" disabled>
                      Branch
                    </MenuItem>
                    {branchList.map((val) => (
                      <MenuItem key={val?._id} value={val?._id}>
                        {val?.practice_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    className="filter"
                    defaultValue="Provider"
                    onChange={(event) => {
                      setSelectedProvider(event.target.value);
                    }}
                  >
                    <MenuItem value="Provider" disabled>
                      Dentist
                    </MenuItem>
                    {providersList.map((val) => (
                      <MenuItem key={val?._id} value={val?._id}>
                        {val?.full_name}
                      </MenuItem>
                    ))}
                  </Select>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <Card>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={row} />
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>

      {/* modal start */}
      <Dialog
        open={open1}
        onClose={() => handleClose1()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            minWidth: "600px",
            minHeight: "30px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Payment status</DialogTitle>
        <DialogContent>
          <DialogContentText id="provider_container">
            <SoftBox>
              <SoftBox>
                <SoftTypography variant="h6" style={{ margin: "0 0 1rem 0" }}>
                  Clinic has requested not to be charged for the appointment due
                  to the following reasons.
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftTypography variant="h6">Reason :</SoftTypography>
                <SoftTypography variant="h6">
                  {requestData?.comment}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SoftBox
            display="flex"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <SoftButton
              variant="outlined"
              style={{ marginRight: "10px" }}
              size="small"
              color="info"
              onClick={() => sendRequestStatus("rejected")}
            >
              Reject
            </SoftButton>
            <SoftBox>
              <SoftButton
                variant="contained"
                style={{ marginRight: "10px" }}
                size="small"
                color="info"
                onClick={() => {
                  sendRequestStatus("accepted");
                }}
              >
                Accept
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </DialogActions>
      </Dialog>
      {/* modal end */}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}
