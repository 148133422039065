import { Card, Icon } from '@mui/material'
import SoftBox from 'components/SoftBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import avatar from "../../assets/images/bruce-mars.jpg"
import NotificationItem from 'examples/Items/NotificationItem'

import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { makeHttpRequest } from 'utils/httpServices'
import {useEffect, useState} from 'react'
import {useNavigate } from "react-router-dom";


export default function index() {
  const [notifications, setNotifications] = useState([])
  const navigate = useNavigate();


  const handleCloseMenu1 = (id, type)=>{
    if(type === 'appointment'){
      navigate(`/viewAppointment/${id}`);
      // setOpenMenu(false);
    }
    else if(type === 'user'){
      navigate(`/viewUserManagement/${id}`);
      // setOpenMenu(false);
    }
    else if(type === 'enquiry'){
      navigate(`/enquiryManagement`);
      // setOpenMenu(false);
    }
  }

  function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  const timeSetter = (time)=>{

    var aDay = new Date(time);
    return (timeSince(aDay));
  }

  async function getNotifications(){
    try {

      const resp = await makeHttpRequest('get', 'getNotification');
      console.log('resp123123', resp)
      setNotifications(resp?.data)
    } catch (error) {
      
    }
  } 

  // async function getCountNotifications(){
  // const resp1 = await makeHttpRequest('get', 'unseenNotificationCount');
  // setCountOfNotification(resp1?.data)
  // }
 
  useEffect(()=>{
    getNotifications()
  },[])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card >
        <SoftBox p={4} style={{height: '500px', overflow: 'auto'}}>
        {notifications?.map((val)=>(<NotificationItem
            image={<img src={val?.sender_details?.profile_image ? `${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.sender_details?.profile_image}`:
          process.env.REACT_APP_DEFAULT_IMAGE_URL} alt="person" />}
            title={[`${val?.title}`, `${val?.body}`]}
            date={timeSetter(val?.createdAt)}
            onClick={()=>handleCloseMenu1(val?.related_to, val?.type)}
          />))}
          {/* <NotificationItem
            image={<img src={logoSpotify} alt="person" />}
            title={["New album", "by Travis Scott"]}
            date="1 day"

          />

          <NotificationItem
            color="secondary"
            image={
              <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                payment
              </Icon>
            }
            title={["", "Payment successfully completed"]}
            date="2 days"

          />
          <NotificationItem
            image={<img src={logoSpotify} alt="person" />}
            title={["New album", "by Travis Scott"]}
            date="1 day"

          />

          <NotificationItem
            color="secondary"
            image={
              <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                payment
              </Icon>
            }
            title={["", "Payment successfully completed"]}
            date="2 days"

          />

          <NotificationItem
            image={<img src={logoSpotify} alt="person" />}
            title={["New album", "by Travis Scott"]}
            date="1 day"

          /> */}


        </SoftBox>
      </Card>
    </DashboardLayout>
  )
}
