// src/components/PaginatedTable.js
import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Button, TextField, IconButton
} from '@mui/material';
import SoftTypography from 'components/SoftTypography';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';


const ClinicChargesData = ({ rows, columns,
   selectedId , setSelectedId , 
   handleChangePrice,
   isConfirmModalOpen,
   setConfirmModalOpen,
   currentPage,
   setCurrentPage,
   rowsPerPage,
   setRowsPerPage,
   totalCount
  }) => {
  


  const [newPrice ,setNewPrice ] = useState('')

  const handleEditClick = (row) => {
    setNewPrice(row?.price_per_appointment)
    setSelectedId(row?._id)
  };




  const hanldePriceUpdate = (id) => {
    if(!newPrice){
      toast.error("Please enter a price")
      return;
    }
    if (isNaN(newPrice) || newPrice <= 0) {
      toast.error("Please enter a valid number greater than 0");
      return;
    }

    setConfirmModalOpen(true)
   
  };

  const handleEditCancel = () => {
    setSelectedId(null)
  };


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };




  const handlePriceChangeConfirm= ()=>{
    handleChangePrice(newPrice, selectedId)
  }



  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {column.id === 'price_per_appointment' ? (
                      selectedId === row._id ? (
                        <TextField type="number"
                          value={newPrice}
                          onChange={(e)=>setNewPrice(e.target.value)}
                          size="small"
                        />
                      ) : (
                        row[column.id]
                      )
                    ) : column.id === 'action' ? (
                      <div style={{ display: 'flex', gap: '8px' }}>
                        {selectedId === row._id ? (
                          <>
                            <IconButton onClick={() => hanldePriceUpdate(row.id)} color="primary">
                              <SaveIcon />
                            </IconButton>
                            <IconButton onClick={handleEditCancel} color="default">
                              <CancelIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton onClick={() => handleEditClick(row)} color="secondary">
                              <EditIcon />
                            </IconButton>
                            {/* <IconButton onClick={() => handleDelete(row.id)} color="error">
                              <DeleteIcon />
                            </IconButton> */}
                          </>
                        )}
                      </div>
                    ) : (
                      row[column.id]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handlePriceChangeConfirm}
        messageText= "Are you sure you want to Change"
      />
    </Paper>


  );
};

export default ClinicChargesData;



