import axios from 'axios';
import { toast } from 'react-toastify';

const Base_Url = process.env.REACT_APP_SERVER_URL;
const mapUrl = process.env.REACT_APP_GOOGLE_MAP_URL;
// const ApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const ApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY_FOR_GEOCODE;
// axios.interceptors.request.use(
//     function (config) {
//         // if (token) { // if token avaiable


//             config.headers['Authorization'] = 'Bearer ' + token;
//         //   }
//       console.log('Request Interceptor:', config);
//       return config;
//     },
//     function (error) {
//       console.log('Request Error Interceptor:', error);
//       return Promise.reject(error);
//     }
//   );
// const navigate = useNavigate();
axios.interceptors.response.use(
  function (response) {

    return response;
  },
  function (error) {

    // console.log('Error Interceptor:', error);

    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');

    }
    return Promise.reject(error);
  }
);

export const makeHttpRequest = async (method, route, data = null, params = null) => {

  const url = `${Base_Url}admin/${route}`;

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  try {
    const response = await axios({
      method,
      url,
      data,
      params,
      headers
    });

    // console.log('Response:', response);
    return response.data;

  } catch (error) {
     
    console.log("error from server:",error)
    toast.error(error?.response?.data?.msg)
    toast.error(error?.response?.data?.errors?.msg)
    throw error;

  }

}

export const makeHttpDownloadRequest = async (Url) => {

  const url = `${process.env.REACT_APP_IMAGE_URL}userProfile/${Url}`;

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  try {
    const response = await axios.get(url, {
      headers,
      responseType: 'blob'
    });

    // console.log('Response:', response);
    return response.data;

  } catch (error) {

    toast.error(error?.response?.data?.errors?.msg)
    // console.log(error)
    // throw error;

  }

}

export const makeHttpGoogleMapRequest = async (address) => {

  const url = `${mapUrl}${address}&key=${ApiKey}`;

  try {
    const response = await axios.get(url);

    // console.log('Response:', response);
    return response.data;

  } catch (error) {

    toast.error(error?.response?.data?.errors?.msg)
    // console.log(error)
    // throw error;

  }
}

export async function generateImageFromName(
  name,
  size = 100,
  backgroundColor = "#ff6bb5",
  textColor = "#ffffff"
) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set canvas dimensions
    canvas.width = size;
    canvas.height = size;

    // Draw background
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, size, size);

    // Draw text
    context.font = `${size / 2}px Arial`;
    context.fillStyle = textColor;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(name[0].toUpperCase(), size / 2, size / 2);

    // Convert canvas to blob
    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error("Failed to convert canvas to blob");
        reject("Failed to convert canvas to blob");
        return;
      }

      // Create FormData object
      const formData = new FormData();
      formData.append("path", "userProfile");
      formData.append("media", blob);

      try {
        // Call the imageUpload function and await its response
        const resp = await imageUpload(formData);
        console.log("upload image response", resp);
        resolve(resp.path);
      } catch (error) {
        console.log("upload profile image error", error);
        reject(error);
      }
    });
  });
}


const imageUpload = async (formData) => {
  try {
    const resp = await makeHttpRequest("post", "uploadAdminMedia", formData);

    return resp;
  } catch (error) {
    console.log(error);
  }
};