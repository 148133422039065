import { Card, Grid, TablePagination } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SuiSelect from "components/SuiSelect/SuiSelect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import React, { useEffect, useState } from "react";
import UserTableData from "./data/UserTableData";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import { makeHttpRequest } from "utils/httpServices";
import SoftAvatar from "components/SoftAvatar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "components/DeleteModal/DeleteModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Pagination from "../pagination/index";
import Typography from "@mui/material/Typography";

import team2 from "assets/images/team-2.jpg";
import { useFormik } from "formik";

export default function ClinicManagement() {
  const navigate = useNavigate();
  const { columns } = UserTableData;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    console.log(`Selected page: ${value}`);
  };
  console.log(currentPage);
  const [clinicListing, setClinicListing] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  // const [month, setMonth] = useState(null);
  // const [year, setYear] = useState("");
  const [clinic_name, setClinicName] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingRow, setDeletingRow] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [inpText, setInpText] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const getclinicList = async (clinic_name) => {

    try {
      const offset = currentPage * rowsPerPage;
      console.log(
        offset,
        "=================================offset================================"
      );
      const resp = await makeHttpRequest("get", "getClinic", null, {
        name: clinic_name,
        sort: -1,
        limit: rowsPerPage,
        offset: offset,
      });

      const count1 = Math.ceil(resp?.count / 10);
      setTotalPage(count1);
      setTotalCount(resp.count);
      setClinicListing(resp?.data);
    } catch (error) {
      // console.log(error);
      toast.error("Network error.");
    }
  };
  //   const handleDeleteClick = (rowData) => {
  //     setDeletingRow(rowData);
  //     setDeleteModalOpen(true);
  // };
  const handleDeleteConfirm = async (id) => {
    try {
      const resp = await makeHttpRequest("delete", `deleteClinic/${id}`);
      toast.success("Deleted successfully");
      console.log(resp);
      getclinicList();
      setDeleteModalOpen(false);
    } catch (e) {
      toast.error("Network Error");
      console.log(e);
    }
  };

  const deleteHandler = (value) => {
    setDeleteId(value);
    setDeleteModalOpen(true);
  };

  function Author({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  function Function({ job, org }) {
    return (
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {org}
        </SoftTypography>
      </SoftBox>
    );
  }

  const getDate = (createdAt) => {
    const originalDate = new Date(createdAt);

    // Options for formatting the date
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };

    // Format the date
    return originalDate.toLocaleDateString("en-US", options);
  };
  console.log(clinicListing);
  //creating row from array
  const CreateRows = () => {
    console.log(clinicListing);
    const rows = clinicListing?.map((obj, index) => ({
      Clinic: (
        <Author
          image={
            obj.clinic_image
              ? process.env.REACT_APP_IMAGE_URL +
                "userProfile/" +
                obj?.clinic_image
              : team2
          }
          name={obj?.practice_name}
          email={obj?.email}
        />
      ),

      "Owner Name": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {obj?.full_name} {console.log(obj?.full_name, "owner name")}
        </SoftTypography>
      ),

      "Phone Number": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {obj?.phone_number}
        </SoftTypography>
      ),

      "Total Doctors": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {obj?.dentist_count}
        </SoftTypography>
      ),

      "Total Branches": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {obj?.branch_count}
        </SoftTypography>
      ),

      "Rating & Reviews": (
        <SoftTypography  component={Link}
           to={`../rating-and-reviews/${obj?._id}`}
            variant="caption" color="secondary" fontWeight="medium">
          {`${obj?.rating}/5`}
        </SoftTypography>
      ),

      "Joined Date": (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {getDate(obj?.createdAt)}
        </SoftTypography>
      ),

      action: (
        <SoftBox>
          <SoftTypography
            component={Link}
            to={`/viewClinic/${obj?._id}`}
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"
            // onClick={() => { navigate(`/viewClinic/${obj?._id}`); }}
          >
            View
          </SoftTypography>

          <SoftTypography
            component={Link}
            to={`/editClinic/${obj?._id}`}
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            marginRight="10px"
          >
            Edit
          </SoftTypography>

          <SoftTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
            onClick={() => deleteHandler(obj?._id)}
          >
            Delete
          </SoftTypography>
        </SoftBox>
      ),
    }));

    setRows(rows);
  };

  useEffect(() => {
    const x = setTimeout(() => {
      getclinicList(inpText);
    }, 500);

    return () => clearTimeout(x);
  }, [inpText, currentPage, rowsPerPage]);

  useEffect(() => {
    CreateRows();
  }, [clinicListing]);

  const searchHandle = async (text, date) => {
    // setSelectedDate(date);
    setInpText(text);
    // if (date !== null) {
    //   setMonth(date.getMonth() + 1);
    //   setYear(date.getFullYear());
    // }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <SoftBox
                  className="table_header"
                  display="flex"
                  alignItems="center"
                >
                  <SoftTypography variant="h6">Clinic List</SoftTypography>
                  <SoftBox
                    ml={2}
                    className="filter_box"
                    display="flex"
                    gap="15px"
                  >
                    <SoftInput
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      name="clinic_name"
                      value={inpText}
                      onChange={(e) =>
                        searchHandle(e.target.value, selectedDate)
                      }
                    />

                    {/* <DatePicker
                      selected={selectedDate}
                      renderMonthContent={renderMonthContent}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      onChange={(e) => searchHandle(inpText, e)}
                    /> */}
                    {/* <SuiSelect
                        className="filter"
                        placeholder="Select month"
                        options={[
                          { value: "1", label: "January" },
                          { value: "2", label: "February" },
                          { value: "3", label: "March" },
                          { value: "4", label: "April" },
                          { value: "5", label: "May" },
                          { value: "6", label: "June" },
                          { value: "7", label: "July" },
                          { value: "8", label: "August" },
                          { value: "9", label: "September" },
                          { value: "10", label: "October" },
                          { value: "11", label: "November" },
                          { value: "12", label: "December" },
                        ]}

                        value={month}
                        onChange={(e) => searching(clinic_name, e, year)}

                      />
                      <SuiSelect
                        className="filter"
                        placeholder="Select Year"
                        options={[
                          {
                            value: "2023",
                            label: "2023",
                          },
                          // { value: "Heloo Dental", label: "Heloo Dental" },
                          // { value: "Smile Dental", label: "Smile Dental" },
                          // {
                          //   value: "Care Smile Dental",
                          //   label: "Care Smile Dental",
                          // },
                          // ... other providers
                        ]}
                        defaultValue="2023"
                        value={year}
                        onChange={(e) => searching(clinic_name, month, e)}
                      //   onChange={(selectedValue) => {
                      //     console.log("Selected Month:", selectedValue);
                      //   }
                      // }
                      // name = 'year'
                      //   {...formik.getFieldProps("year")}
                      /> */}
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item md={4}>
                <SoftBox display="flex" alignItems="end" justifyContent="end">
                  <SoftButton
                    variant="contained"
                    component={Link}
                    to="/addClinic"
                    color="info"
                  >
                    Add New
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <Card>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
              <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={() => handleDeleteConfirm(deleteId)}
              />
              {/* <div className="pagi">
              <Stack spacing={2}>
               
                <Pagination count={10} page={page} onChange={handleChange} />
              </Stack>
              </div> */}
            </SoftBox>
            <SoftBox className="pgaCntner">
              {/* <Pagination
                count={totalCount}
                onRowsPerChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  // setCurrentPage(1);
                }}
                onChange={handlePageChange}
              /> */}
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 15, 20, 25]}
              />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
