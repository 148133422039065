/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";






// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components

import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";


import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Header from "./components/Header";
import branch01 from "assets/images/clinicImgs.jpg"
import branch02 from "assets/images/clininc.jpg"
import branch03 from "assets/images/clinic03.jpg"
import Slider from "react-slick";
import ImageSlider from "components/Slider/ImageSlider";


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";



function Overview() {
  const { id } = useParams();
  const [clinicData, setClinicData] = useState({
    fullName: '',
    mobile: '',
    email: '',
    location: '',
    bio: ''
  })


  const [providerData, setProviderData] = useState([]);
  const [clinicDocuments, setClinicDocuments] = useState([]);
  const [branchData, setBranchData] = useState([]);
  console.log("🚀 ~ Overview ~ branchData:", branchData)

  const [img, setImg] = useState({full_name:'', profile_image: '', clinic_image: '' })

  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL
  console.log("🚀 ~ Overview ~ imageBaseUrl:", imageBaseUrl)


  const getData = async () => {
    try {

      const resp = await makeHttpRequest('get', `getSingleClinic/${id}`);
      console.log(resp?.data[0])
      if (resp && resp?.data && resp?.data.length > 0) {
        // Assuming your API response has properties like 'full_name', 'phone_number', 'email', and 'address'
        const { full_name, phone_number, email, address, bio, profile_image, clinic_image, clinic_documents } = resp.data[0];

        // Update the state with the data from the API
        // console.log(clinic_documents)
        setClinicDocuments(clinic_documents)
        setImg({
          full_name:full_name|| '',
          profile_image: profile_image || '',
          clinic_image: clinic_image || ''
        })
        setClinicData({
          fullName: full_name || '',
          mobile: phone_number || '',
          email: email || '',
          location: address || '',
          bio: bio || ''
        });
      }

      const param = {
        branch_id: id,
        sort: 1
      }
      const Resp = await makeHttpRequest('get', 'getDentistListing', null, param);
      setProviderData(Resp?.data)
      console.log(Resp)

      const param1 = {
        clinic_id: id
      }

      const Resp1 = await makeHttpRequest('get', 'getBranchListing', null, param1)
      const branches = Resp1?.data
      const subBranches = branches?.filter(branch => branch?._id  !== id)
      setBranchData(subBranches)
    } catch (error) {
      console.log(error);
      toast.error('Network error.')
    }
  }

  useEffect(() => {
    if (id !== null) {
      getData();
    }
  }, [id])

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  return (
    <DashboardLayout>
      <Header data={img} />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="profile information"
              description={clinicData.bio}
              info={{
                fullName: clinicData.fullName,
                mobile: clinicData.mobile,
                email: clinicData.email,
                location: clinicData.location,
              }}
              // social={[
              //   {
              //     link: "https://www.facebook.com/CreativeTim/",
              //     icon: <FacebookIcon />,
              //     color: "facebook",
              //   },
              //   {
              //     link: "https://twitter.com/creativetim",
              //     icon: <TwitterIcon />,
              //     color: "twitter",
              //   },
              //   {
              //     link: "https://www.instagram.com/creativetimofficial/",
              //     icon: <InstagramIcon />,
              //     color: "instagram",
              //   },
              // ]}
              action={{ route: "", tooltip: "Edit Profile" }}
            />
          </Grid>
          {/* <Grid item xs={12} xl={4}>
            <ProfilesList title="conversations" profiles={profilesListData} />
          </Grid> */}

          <Grid item xs={12} xl={4}>
            <ImageSlider data={clinicDocuments} />
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <SoftTypography variant="h6" fontWeight="medium">
              Dentists
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
              Dentists List
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={3} pt={2}>



            <Slider {...settings}>
              {/* Your slide content goes here */}
              {providerData?.map((val) => (<SoftBox className="provider_slider" >
                <DefaultProjectCard
                  image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.profile_image}`}
                  label="Dentist"
                  title={val?.full_name}
                  description={val?.designation}
                  action={{
                    type: "internal",
                    route: `/providerProfile/${val?._id}`,
                    color: "info",
                    label: "view profile",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>))}
              {/* <SoftBox className="provider_slider" >
                <DefaultProjectCard
                  image={team2}
                  label="Dentist"
                  title="Mark Hay Smith"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/viewBranch",
                    color: "info",
                    label: "view profile",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>

              <SoftBox className="provider_slider">
                <DefaultProjectCard
                  image={team3}
                  label="Dentist"
                  title="Dr.Smith Bruklin"
                  description="Different people have different taste, and various types of music."
                  action={{
                    type: "internal",
                    route: "/providerProfile",
                    color: "info",
                    label: "view profile",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>

              <SoftBox className="provider_slider">
                <DefaultProjectCard
                  image={team4}
                  label="Branch #1"
                  title="Cristina Groves"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/providerProfile",
                    color: "info",
                    label: "view profile ",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>
              <SoftBox className="provider_slider" >
                <DefaultProjectCard
                  image={team4}
                  label="Branch #1"
                  title="Cristina Groves"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view profile ",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox>
              <SoftBox className="provider_slider" >
                <DefaultProjectCard
                  image={team4}
                  label="Branch #1"
                  title="Cristina Groves"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/providerProfile",
                    color: "info",
                    label: "view profile ",
                  }}
                  authors={[

                  ]}
                />
              </SoftBox> */}


            </Slider>

          </SoftBox>
        </Card>
      </SoftBox>



{
  
            branchData?.length > 0 &&
            <SoftBox mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <SoftTypography variant="h6" fontWeight="medium">
                Sub Locations 
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Locations List
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
        
            <Grid container spacing={3}>
              {branchData?.map((val) => (<Grid item xs={12} md={6} xl={3}>
                 
                <DefaultProjectCard
                  image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.clinic_image}`}
                  label={`Branch #${val?.branch_number}`}
                  title={val?.full_name}
                  description={val?.bio}
                  action={{
                    type: "internal",
                    route: `/viewBranch/${val?._id}`,
                    color: "info",
                    label: "view branch",
                  }}
                  // authors={[
                  //   { image: val?.dentistProfile?.team1, name: "Elena Morison" },
                  //   { image: team2, name: "Ryan Milly" },
                  //   { image: team3, name: "Nick Daniel" },
                  //   { image: team4, name: "Peterson" },
                  // ]} 
                  authors = {val?.dentistProfile?.map(dentist => ({
                 image: `${imageBaseUrl}userProfile/${dentist?.profile_image}`,
                //  image: team2,
                 name: dentist?.full_name || "Unknown" 
             })) || [] }              

 />
              </Grid>))}
              {/* <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={branch01}
                  label="Branch #1"
                  title="Sunshine Dental"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/viewBranch",
                    color: "info",
                    label: "view branch",
                  }}
                  authors={[
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={branch03}
                  label="Branch #3"
                  title="	Studio Smile"
                  description="Different people have different taste, and various types of music."
                  action={{
                    type: "internal",
                    route: "/viewBranch",
                    color: "info",
                    label: "view branch",
                  }}
                  authors={[
                    { image: team4, name: "Peterson" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team1, name: "Elena Morison" },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={branch01}
                  label="Branch #1"
                  title="Sunshine Dental"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view branch",
                  }}
                  authors={[
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                  ]}
                />
              </Grid> */}


            </Grid>
           
            
          </SoftBox>
        </Card>
      </SoftBox>
}
     

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
