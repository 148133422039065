// src/data.js
export const columns = [
  { id: 'practice_name', label: 'Clinic Name' },
  { id: 'full_name', label: 'Clinic Owner' },
  { id: 'price_per_appointment', label: 'Amount Per Patient' },
  { id: 'action', label: 'Action' }
];

export const rows = [
  { id: 1, clinicName: 'Healthy Life Clinic', clinicOwner: 'Dr. John Smith', amountPerPatient: 50, action: 'Edit' },
  { id: 2, clinicName: 'Wellness Center', clinicOwner: 'Dr. Jane Doe', amountPerPatient: 40, action: 'Edit' },
  { id: 3, clinicName: 'Care Clinic', clinicOwner: 'Dr. Alice Brown', amountPerPatient: 60, action: 'Edit' },
  { id: 4, clinicName: 'Family Health', clinicOwner: 'Dr. Chris Green', amountPerPatient: 55, action: 'Edit' },
  { id: 5, clinicName: 'City Clinic', clinicOwner: 'Dr. Michael Black', amountPerPatient: 45, action: 'Edit' },
  { id: 6, clinicName: 'Neighborhood Health', clinicOwner: 'Dr. Emily White', amountPerPatient: 50, action: 'Edit' },
  { id: 7, clinicName: 'Urban Health Center', clinicOwner: 'Dr. David Lee', amountPerPatient: 65, action: 'Edit' }
];
