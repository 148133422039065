import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./ChatSupport.css";
import { Card, Grid, Icon, Link } from "@mui/material";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
// import Icon from "@mui/material/Icon";
import { io } from "socket.io-client";
import SendIcon from "@mui/icons-material/Send";
import { makeHttpRequest } from "utils/httpServices";
import PlacholderImage from "../../assets/images/PlaceHolder.avif";

const ChatSupport = () => {
  const [socket, setSocket] = useState(null);
  const [roomId, setRoomId] = useState("");
  const [message, setMessages] = useState("");
  const [botchat, setIsBotChat] = useState(false);
  const [userList, setUsersList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [allmessages, setAllMessages] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [filteredUserlist, setFilteredUserlist] = useState([]);
  const [id, setId] = useState("");
  const chatContainerRef = useRef(null);
  const [firstRander, setfirstRander] = useState(true);

  const userId = useMemo(()=>{
    return JSON.parse(localStorage.getItem('user'))?._id || null;
},[])
  console.log("🚀 ~ userId ~ userId:", userId)


  useEffect(() => {
    if (userList?.length > 0 && firstRander) {
      setUserDetails(userList[0]);
      handleJoinRoom(userList[0]?.room_id);
      setfirstRander(false);
    }
  }, [userList]);


  const getAllMessage = async () => {
    let old_messages = [];

    if (roomId) {
      const response = await makeHttpRequest(
        "GET",
        `getSupportChats?room_id=${roomId}&limit=${limit}&offset=${offset}`
      );
      console.log(response, "response");
      old_messages = response?.data?.map((item) => ({
        _id: item._id,
        message: item.message,
        message_type: item.message_type,
        sender: item.sender,
        createdAt: item.createdAt,
        is_session_ended : item.is_session_ended
      }));
    }
    setAllMessages(old_messages);
  };

  const getAllMessageSeen = (room_id) => {
    socket?.emit("seen all message support", {
      room_id: room_id,
      user_id: userId,
    });
    console.log("seen all message support");
  };

  useEffect(() => {
    let filteredList = userList;

    if (searchInput) {
      filteredList = userList.filter((item) =>
        item?.user_details?.full_name
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    }
    setFilteredUserlist(filteredList);
  }, [searchInput, userList]);

  useEffect(() => {
    getAllMessage();
  }, [roomId, limit, offset]);

  const initSocket = () => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket?.on("connect", () => {
      console.log("server is connected");
    });

    socket?.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    socket?.emit("sidebar join admin", userId);

    socket?.on("sidebar admin", (list) => {
      console.log("user lists: ", list);
      setUsersList(list);
    });
    getAllMessageSeen();
    window?.addEventListener("beforeunload", () => {
      handleDisconnectChat();
    });

    setSocket(socket);
   
  };
  useEffect(() => {
    initSocket();

    return ()=>{
      handleDisconnectChat()
    }
  }, []);

  const handleDisconnectChat = ()=>{
    handleLeaveRoom(roomId);
    socket?.disconnect();
    console.log("socket disconnected")
  }

  useEffect(() => {
    if (userDetails) {
      setRoomId(userDetails?.room_id);
    }
  }, [userDetails]);

  const handleJoinRoom = (roomId) => {
    console.log("roomId :", roomId);
    socket?.emit("room join", {
      room_id: roomId,
    });
  
    socket?.off("room join")
    socket?.on("room join", (status) => {
      console.log("Room joined: ", status);
      if (status?.room_id === roomId) {
        getAllMessageSeen(status?.room_id);
        // setMessages((prevMessages) => [
        //   ...prevMessages,
        //   { message: "Connection Enabled.", sender: "dev" },
        // ]);

        setRoomId(roomId);
        initListers();
      }
    });
  };
  const handleLeaveRoom = (roomId) => {

  
    socket?.emit("leave room", { room_id: roomId });


    socket?.off("leave room")
    socket?.on("leave room", (status) => {
      console.log("room leaved: ", status)
      // console.log("Leave room", status);
      // if (newRoomId) {
      //   setAllMessages([]);
      //   handleJoinRoom(newRoomId);
      // } else {
      //   socket.disconnect();
      //   console.log("Diconnection closed", status);
      // }
    });
  };

  function initListers() {
    socket.off("chat_message_support");

    socket?.on("chat_message_support", (message) => {
      setAllMessages((prevMessages) => [...prevMessages, message[0]]);
      console.log("Chat message recieved", message);
    });
  }




  const handleSendMessage = () => {
    socket?.emit("chat_message_support", {
      room_id: roomId,
      primary_room_id: userDetails._id,
      sender_id: userId,
      receiver_id: userDetails?.user_details._id,
      message: newMessage,
      sender: "admin",
    });
    // initListers();
    setNewMessage("");
    scrollToBottom();
  };
  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = PlacholderImage;
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [allmessages]);

  const handleRoomChange = (oldRoomId, newRoomId) => {
    if(oldRoomId === newRoomId ){
      return;
    }
    handleLeaveRoom(oldRoomId);
    setAllMessages([]);
    handleJoinRoom(newRoomId);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        {/* <Grid container ></Grid> */}

        <Grid container spacing={2}>
          <Grid item md={4} xs={12} lg={4}>
            <Card className="blur shadow-blur max-height-vh-70 overflow-auto overflow-x-hidden mb-5 mb-lg-0">
              <SoftBox className="card-header" p={3}>
                <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="bold"
                >
                  Users
                </SoftTypography>
                <SoftInput
                  type="email"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="form-control"
                  placeholder="Search Contact"
                  aria-label="Email"
                  onfocus="focused(this)"
                  onfocusout="defocused(this)"
                ></SoftInput>
              </SoftBox>
              <SoftBox className="card-body" p={2}>
                {filteredUserlist?.map((item) => (
                  <Link
                    className={
                      item.room_id === roomId
                        ? "d-block p-2 border-radius-lg bg-gradient-primary"
                        : "d-block p-2 border-radius-lg"
                    }
                    onClick={() => {
                      // handleLeaveRoom(roomId)
                      setRoomId(item.room_id);
                      setUserDetails(item);
                      // handleJoinRoom(item.room_id);
                      handleRoomChange(roomId, item.room_id);
                    }}
                    href="#"
                  >
                    <SoftBox className="listingContner" display="flex" p={2}>
                      <img
                        onError={handleImageError}
                        src={`${process.env.REACT_APP_IMAGE_URL}userProfile/${item.user_details?.profile_image}`}
                        className="avatar shadow"
                        alt=""
                      />
                      <SoftBox ml={2}>
                        <div
                          style={{
                            display: "flex",
                            // justifyContent: "space-around",
                          }}
                        >
                          <h6 className="mb-0">
                            {item?.user_details?.full_name}
                          </h6>
                          {item?.chats?.count > 0 && (
                            <p className="countContner shadow-lg">
                              {item?.chats?.count}
                            </p>
                          )}
                        </div>

                        <span className="text-sm col-11 p-0 text-truncate d-block">
                          {item?.last_chat?.message}
                        </span>
                      </SoftBox>
                    </SoftBox>
                  </Link>
                ))}
              </SoftBox>
            </Card>
          </Grid>
          <Grid item md={8} xs={12} lg={8}>
            <Card className="card blur shadow-blur max-height-vh-70">
              <SoftBox className="card-header shadow-lg">
                <Grid container spacing={2}>
                  <Grid item md={10} lg={10}>
                    <SoftBox display="flex" p={2}>
                      <img
                        onError={handleImageError}
                        src={`${process.env.REACT_APP_IMAGE_URL}userProfile/${userDetails?.user_details?.profile_image}`}
                        className="avatar shadow"
                        alt=""
                      />
                      <SoftBox ml={2}>
                        <SoftBox
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <h6 className="text-muted mb-0">
                            {userDetails?.user_details?.full_name}
                            <span className="badge badge-success" />
                          </h6>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                  {/* <Grid item xs={1} my="auto" pr={0}>
                    <button
                      className="btn btn-icon-only shadow-none text-dark mb-0 me-3 me-sm-0"
                      type="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title="Video call"
                    >
                      <i className="ni ni-camera-compact" />
                    </button>
                  </Grid> */}
                </Grid>
              </SoftBox>

              <SoftBox
                ref={chatContainerRef}
                className="card-body overflow-auto overflow-x-hidden"
              >
                {allmessages?.map((item, index) => (
                  <div>

                
                  <SoftBox
                    display="flex"
                    justifyContent={item.sender === "admin" ? "end" : "start"}
                    mb={4}
                  >
                    <div className="col-auto">
                      <Card
                        className={
                          item.sender === "admin" ? "card bg-gray-200" : "card"
                        }
                      >
                        <div className="card-body" >
                          <p className="mb-1">{item.message}</p>{" "}
                          {/* Use item.message for message content */}
                          <SoftBox
                            display="flex"
                            alignItems="center"
                            className="text-sm opacity-6"
                          >
                            <i className="ni ni-check-bold text-sm me-1"></i>
                            <small></small>
                            {/* Assuming timestamp is available */}
                          </SoftBox>
                        </div>
                      </Card>
                      {/* { (item?.is_session_ended && index === allmessages?.length -1) && */}
                    
                    </div>
                    
                  </SoftBox>
                  { item?.is_session_ended && index === allmessages?.length -1  &&
                <SoftBox>
                  <div className="text-center" >
                     <p className="mb-1 text-center">Session is ended</p>{" "}
                  </div>

                </SoftBox>
                }
                  </div>
                ))}
              

                {/* <SoftBox display="flex" justifyContent="start" mb={4}>
                  <div className="col-auto">
                    <Card className="card">
                      <div className="card-body" py={3} px={3}>
                        <p className="mb-1">
                          It contains a lot of good lessons about effective
                          practices
                        </p>
                        <SoftBox
                          display="flex"
                          alignItems="center"
                          className="text-sm opacity-6"
                        >
                          <i className="ni ni-check-bold text-sm me-1"></i>
                          <small>3:14am</small>
                        </SoftBox>
                      </div>
                    </Card>
                  </div>
                </SoftBox>
                <SoftBox display="flex"  ml={4} mb={4}>
                  <div className="col-auto">
                    <Card className="bg-gray-200">
                      <div className="card-body" py={2} px={3}>
                        <p className="mb-1">
                          Can it generate daily design links that include essays
                          and data visualizations ?<br />
                        </p>
                        <SoftBox
                          display="flex"
                          alignItems="center"
                          className="text-sm opacity-6"
                        >
                          <i className="ni ni-check-bold text-sm me-1"></i>
                          <small>4:14am</small>
                        </SoftBox>
                      </div>
                    </Card>
                  </div>
                </SoftBox> */}
              </SoftBox>
              <SoftBox className="card-footer d-block">
                <form className="align-items-center">
                  <SoftBox display="flex">
                    <div className="input-group">
                      <SoftInput
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className="form-control"
                        placeholder="Type here"
                        aria-label="Message example input"
                        onfocus="focused(this)"
                        onfocusout="defocused(this)"
                      />
                    </div>
                    <button
                      onClick={handleSendMessage}
                      style={{
                        background:
                          newMessage === ""
                            ? "grey"
                            : "linear-gradient(to right, #00c6fb, #005bea)",
                        color: "grey",
                      }}
                      className="btn mb-0 ms-2"
                      disabled={newMessage === ""}
                    >
                      <Icon
                        disabled={newMessage === ""}
                        color="inherit"
                        fontSize="small"
                      >
                        <SendIcon />
                      </Icon>
                    </button>
                  </SoftBox>
                </form>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

export default ChatSupport;
