import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
} from "@mui/material";
import { Button } from "react-bootstrap";
import SoftBox from "components/SoftBox";
import { MdModeEdit } from "react-icons/md";
import user01 from "../../assets/images/team-1.jpg";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";
import { IoMdCloseCircle } from "react-icons/io";
import SuiSelect from "components/SuiSelect/SuiSelect";
import Services from "components/Services/Services";
import { FaCloudUploadAlt } from "react-icons/fa";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeHttpGoogleMapRequest } from "utils/httpServices";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-tabpanel-${index}`}
      aria-labelledby={`scrollable-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function EditDialogProviders({
  handleClose1,
  open1,
  providerId,
  setProviderData,
  getServicesData1
}) {
  const [value, setValue] = React.useState(0);
  const [services, setServices] = React.useState([]);
  const [getServicesData, setGetServicesData] = useState([]);

  const [serData, setSerData] = useState('');

  const [otherSer, setOtherSer] = useState(false);

  const [getImg, setGetImg] = useState(null);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const [checked, setChecked] = React.useState(true);
  // const [getSerData,setGetSerData] =useState([])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [val, setVal] = React.useState("");

  // ---steps---/

  const getServices = (services) => {
    setServices(services);
  };
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };


  const [dentistSlots, setDentistSlots] = useState([]);
  console.log("🚀 ~ dentistSlots:", dentistSlots);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    // email: Yup.string().email().required("Email is required"),
    experience: Yup.string()
      .required("Last Name is required")
      .matches(/^[0-9]+$/, "Experience must only contain numbers"),
    // email: Yup.string()
    //   .required("Email is required")
    //   .matches(
    //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    //     "Invalid email address"
    //   ),
    // address: Yup.string().required("Address is required"),
    designation: Yup.string().required("Designation is required"),
    profile_image: Yup.string().required("Profile Image is required"),
    // joining_date: Yup.string().required("Joining date is required"),
    branch_id: Yup.string().required("Branch is required"),
  });

  const formik = useFormik({
    initialValues: {
      //   clinic_id: clinicId,
      branch_id: "",
      first_name: "",
      last_name: "",
      email: "",
      profile_image: "",
      experience: "",
      joining_date: "",
      designation: "",
      address: "",
      // slots: [
      //   {
      //     day: "Monday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },
      //   {
      //     day: "Tuesday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },

      //   {
      //     day: "WednesDay",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },

      //   {
      //     day: "Thursday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },

      //   {
      //     day: "Friday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },

      //   {
      //     day: "Saturday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },
      //   {
      //     day: "Sunday",
      //     slot: [
      //       {
      //         start: "",
      //         end: "",
      //       },
      //     ],
      //   },
      // ],
      services: [],
      branch_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
  });

  const handleformikCheck = () => {
    console.log(formik.values);
    formik.validateForm(formik.values).then((errors) => {
      // Set touched for all fields
      formik.setTouched({
        first_name: true,
        last_name: true,
        experience: true,
        email: true,
        designation: true,
        address: true,
        profile_image: true,
        joining_date: true,
        branch_id: true,
      });
      if (Object.keys(errors).length === 0) {
        handleNext();
      }
    });
  };

  const formatDateTime = (joiningDateUTC) => {
    if (!joiningDateUTC) {
      return { formattedDate: "" };
    }
    const utcDate = new Date(joiningDateUTC);
    const day = utcDate.getDate().toString().padStart(2, "0");
    const month = (utcDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = utcDate.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const getData = async () => {
    try {
      const resp = await makeHttpRequest(
        "get",
        `getSingleDentist/${providerId}`
      );
      console.log("resp------------------>", resp);
      let joiningDateUTC = resp?.data[0]?.joining_date || "";

      const slotsData = resp?.data[0]?.slots || [];
      // const formattedSlotsData = slotsData.map((slot) => {

      //     const startUtc = new Date(slot?.slot[0].start);
      //     const endUtc = new Date(slot?.slot[0].end);

      //     startUtc.setHours(startUtc.getHours() + 5);
      //     startUtc.setMinutes(startUtc.getMinutes() + 30);

      //     endUtc.setHours(endUtc.getHours() + 5);
      //     endUtc.setMinutes(endUtc.getMinutes() + 30);
      //     return {
      //         day: slot.day || "",
      //         slot: [
      //             {
      //                 start: startUtc.toLocaleTimeString('en-US', {
      //                     hour12: false,
      //                     hour: '2-digit',
      //                     minute: '2-digit',
      //                     second: '2-digit',
      //                     timeZone: 'UTC',
      //                 }),
      //                 end: endUtc.toLocaleTimeString('en-US', {
      //                     hour12: false,
      //                     hour: '2-digit',
      //                     minute: '2-digit',
      //                     second: '2-digit',
      //                     timeZone: 'UTC',
      //                 }),
      //             }
      //         ],
      //     };
      // });

      // Array of all days

      // console.log(formatDateTime, "-----------------------");
      const formattedSlotsData = slotsData.map((slot) => {
        const day = slot.day || "";
        const slots = slot.slot.map((timeSlot) => {
          // const startUtc = new Date(timeSlot.start);
          // const endUtc = new Date(timeSlot.end);
          
          // startUtc.setHours(startUtc.getHours() + 5);
          // startUtc.setMinutes(startUtc.getMinutes() + 30);

          // endUtc.setHours(endUtc.getHours() + 5);
          // endUtc.setMinutes(endUtc.getMinutes() + 30);

          return {
            start: new Date(timeSlot.start)?.toLocaleString().split(", ")[1],
            //     hour12: false,
            //     hour: '2-digit',
            //     minute: '2-digit',
            //     second: '2-digit',
            //     timeZone: 'UTC',
            // }),
            end: new Date(timeSlot.end)?.toLocaleString().split(", ")[1],
            //     hour12: false,
            //     hour: '2-digit',
            //     minute: '2-digit',
            //     second: '2-digit',
            //     timeZone: 'UTC',
            // }),
          };
        });

        return {
          day,
          slot: slots,
        };
      });

      const allDays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      // Generate default slots for all days

      // Merge default slots with formatted slots, avoiding duplicates
      const uniqueDays = {};

      // Merge default slots with formatted slots, avoiding duplicates
      const mergedSlotsData = [];

      formattedSlotsData.forEach((slot) => {
        const { day } = slot;
        if (!uniqueDays[day]) {
          uniqueDays[day] = true;
          mergedSlotsData.push(slot);
        }
      });

      // Add default slots for any missing days
      allDays.forEach((day) => {
        if (!uniqueDays[day]) {
          mergedSlotsData.push({
            day,
            slot: [
              {
                start: "",
                end: "",
              },
            ],
          });
        }
      });

      // Sort the mergedSlotsData based on the order of allDays
      mergedSlotsData.sort(
        (a, b) => allDays.indexOf(a.day) - allDays.indexOf(b.day)
      );

      // console.log(mergedSlotsData)

      formik.setValues({
        branch_id: resp?.data[0]?.branch_id || "",
        branch_name: resp?.data[0]?.branch?.practice_name || "",
        first_name: resp?.data[0]?.first_name || "",
        last_name: resp?.data[0]?.last_name || "",
        email: resp?.data[0]?.email || "",
        profile_image: resp?.data[0]?.profile_image || "",
        experience: resp?.data[0]?.experience || "",
        joining_date:joiningDateUTC ? formatDateTime(joiningDateUTC) : "",
        designation: resp?.data[0]?.designation || "",
        address: resp?.data[0]?.address || "",
        // slots: mergedSlotsData,
        services: resp?.data[0]?.services,
      });
      setDentistSlots(resp?.data[0]?.slots)
      // setGetSerData(resp?.data[0]?.services)
      // getServices(resp?.data[0]?.services)
      // setServices(resp?.data[0]?.services)
      // setSerData(resp?.data[0]?.services);
      setGetServicesData(resp?.data[0]?.services)

    } catch (error) {
      // console.log('error----------------->', error)
      // toast.error('Network error.')
    }
  };


  const handleAddSlot = (dayIndex) => {
    // Add a new slot to the specified day's slots array
    formik.setValues((prevValues) => ({
      ...prevValues,
      slots: prevValues.slots.map((day, index) => {
        if (index === dayIndex) {
          return {
            ...day,
            slot: [
              ...day.slot,
              {
                start: "",
                end: "",
              },
            ],
          };
        }
        return day;
      }),
    }));
  };

  useEffect(() => {
    if (providerId) {
      getData();
    }
  }, [providerId]);

  const hanldemediaUpload = async (e, media) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("path", "userProfile");
      formData.append("media", file);

      try {
        const resp = await makeHttpRequest(
          "post",
          "uploadAdminMedia",
          formData
        );
        console.log("resp", resp);
        if (media === "profile_image") {
          formik.setFieldValue("profile_image", resp?.path);
        }
      } catch (error) {
        console.log(error);
        // toast.error("Something Went Wrong");
      }
    }
  };

  const handleSlotTimeDelete = (dayIndex, slotIndex) => {
    // Remove the specified slot from the specified day's slots array
    formik.setValues((prevValues) => ({
      ...prevValues,
      slots: prevValues.slots.map((day, index) => {
        if (index === dayIndex) {
          return {
            ...day,
            slot: day.slot.filter((_, i) => i !== slotIndex),
          };
        }
        return day;
      }),
    }));
  };

  const convertToDateString = (time) => {
    // const [hours, minutes] = time.split(':');
    // const currentDate = new Date('2023-12-01');
    // currentDate.setHours(Number(hours), Number(minutes), 0, 0);
    // return currentDate.toISOString();

    const [starhour, startminute] = time.split(":");
    // const [endHour, endMinute] = endTime.split(':');

    // if (starhour > endHour || (starhour === endHour && startminute >= endMinute)) {
    //     toast.error('Invalid working hours. Start time must be before end time.');
    //     return;
    //   }

    const startInputTime = new Date('2023-01-01');
    // const endInputTime = new Date();

    startInputTime.setHours(starhour, 0, 0, 0); // Set the hours and minutes
    // endInputTime.setHours(endHour, endMinute, 0, 0); // Set the hours and minutes

    // const timeZoneOffset = startInputTime.getTimezoneOffset();

    // Adjust the start time to UTC by subtracting the offset
    const startUtcTime = startInputTime.toISOString();
    // const endUtcTime = new Date(endInputTime.getTime() - timeZoneOffset * 60000);
    return startUtcTime;
  };

  const convertTimeArray = (originalArray) => {
    return originalArray
      .map((day) => ({
        day: day.day,
        slot: day.slot
          .filter((timeSlot) => timeSlot.start !== "" || timeSlot.end !== "")
          .map((timeSlot) => ({
            start: timeSlot.start ? convertToDateString(timeSlot.start) : "",
            end: timeSlot.end ? convertToDateString(timeSlot.end) : "",
          })),
      }))
      .filter((day) => day.slot.length > 0);
  };

  //removed part beoz of flow change
  // let checker = false;
  //     body1.slots.forEach((val) => {
  //       val.slot.some((times) => {
  //         if (times.start && times.end) {
  //           const startTime = times.start;
  //           const endTime = times.end;

  //           const [starthour, startminute] = startTime.split(":");
  //           const [endHour, endMinute] = endTime.split(":");
  //           let time = 0;
  //           starthour === "00" ? (time = 24) : (time = starthour);

  //           if (time > endHour) {
  //             if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
  //               toast.error(
  //                 "Invalid working hours. Start time must be before end time."
  //               );

  //               checker = true;
  //               return true;
  //             } else if (
  //               time >= 13 &&
  //               time <= 24 &&
  //               endHour >= 13 &&
  //               endHour <= 24
  //             ) {
  //               toast.error(
  //                 "Invalid working hours. Start time must be before end time."
  //               );

  //               checker = true;
  //               return true;
  //             }
  //           } else if (time === endHour && startminute >= endMinute) {
  //             toast.error(
  //               "Invalid working hours. Start time must be before end time."
  //             );
  //             checker = true;
  //             return true;
  //           }

  //           if (checker == true) {
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         }

  //         if (checker == true) {
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       });
  //     });

  //     if (checker === true) return;

  //     const slots = convertTimeArray(body1.slots);
  //     body1.slots = slots;
  //     console.log(body.slots);

  //     // console.log("body*******",body)

  //     if (slots.length == 0) {
  //       toast.error("All fields are mandatory.");
  //       return;
  //     }
  //     if (services.length == 0) {
  //       toast.error("All fields are mandatory.");
  //       return;
  //     }

  const handleAddProvider = async () => {
    try {
      
      const body = { ...formik.values };
      const body1 = JSON.parse(JSON.stringify(body));
      body1.services = services;
      body1.slots = dentistSlots
      
      

      const encodedAddress = encodeURIComponent(body.address);
      const Resp2 = await makeHttpGoogleMapRequest(encodedAddress);
      console.log("Resp1------------->", Resp2);

      if (Resp2?.status == "ZERO_RESULTS") {
        toast.error("Please add a valid address.");
        return;
      }
      const longitude = Resp2?.results[0].geometry.location.lng;
      const latitude = Resp2?.results[0].geometry.location.lat;
      const location = {
        type: "Point",
        coordinates: [longitude, latitude],
      };

      body1.location = location;

        try {
          const resp = await makeHttpRequest(
            "patch",
            `editDentist/${providerId}`,
            body1
          );
          console.log(resp);
          toast.success(resp?.message);
          const param = {
            branch_id: formik.values.branch_id,
            sort: 1,
          };
          const Resp = await makeHttpRequest(
            "get",
            "getDentistListing",
            null,
            param
          );
          setProviderData(Resp?.data);
          handleClose1();
          setCurrentStep(1);
        } catch (error) {
          console.log(error);
          toast.error(error?.response?.data?.errors?.msg);
        }
     
    } catch (e) {
      // toast.error('Network Error.')
      console.log(e);
    }
  };


  const serviceHandler = (val) => {
    // if (serData?.includes(val)) {
    //   return;
    // }
    setSerData(val);
    setVal(val);
  };

  const convertDateToTimeString = (slot) => {
    const start = new Date(slot?.start)?.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const end = new Date(slot?.end)?.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return `${start} to ${end}`;
  };

  const toggleSlot = (dayIndex, slotIndex) => {
    console.log({ dayIndex, slotIndex });
    setDentistSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];
      updatedSlots[dayIndex].slot[slotIndex] = {
        ...updatedSlots[dayIndex].slot[slotIndex],
        enable: !updatedSlots[dayIndex].slot[slotIndex].enable,
      };
      return updatedSlots;
    });
  };


  return (
    <div>
      <form>
        <Dialog
          open={open1}
          onClose={() => handleClose1()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              minWidth: "600px",
              minHeight: "550px",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">{"Edit Dentist"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="provider_container">
              {currentStep === 1 && (
                <SoftBox className="first_Step">
                  <SoftBox mb={3} className=" upload-image">
                    <SoftBox className="image-container">
                      <img
                        src={
                          formik.values.profile_image
                            ? process.env.REACT_APP_IMAGE_URL +
                              "userProfile/" +
                              formik.values.profile_image
                            : user01
                        }
                        alt=""
                      />
                    </SoftBox>
                    <label htmlFor="uploadd-image">
                      <FaCloudUploadAlt />
                    </label>
                    <input
                      type="file"
                      onChange={(e) => hanldemediaUpload(e, "profile_image")}
                      id="uploadd-image"
                    />
                  </SoftBox>
                  {formik.touched.profile_image &&
                  formik.errors.profile_image ? (
                    <div className="form-error1">
                      {formik.errors.profile_image}
                    </div>
                  ) : null}

                  <SoftBox>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              First Name <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            {...formik.getFieldProps("first_name")}
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div className="form-error">
                              {formik.errors.first_name}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Last Name <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Last Name"
                            {...formik.getFieldProps("last_name")}
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div className="form-error">
                              {formik.errors.last_name}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Email
                               {/* <span className="text-danger">*</span> */}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="form-error">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Experience <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="Experience"
                            placeholder="Experience"
                            {...formik.getFieldProps("experience")}
                          />
                          {formik.touched.experience &&
                          formik.errors.experience ? (
                            <div className="form-error">
                              {formik.errors.experience}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Joining Date{" "}
                              {/* <span className="text-danger">*</span> */}
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="date"
                            placeholder="Joining Date"
                            {...formik.getFieldProps("joining_date")}
                          />
                          {formik.touched.joining_date &&
                          formik.errors.joining_date ? (
                            <div className="form-error">
                              {formik.errors.joining_date}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Designation <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="Designation"
                            placeholder="Designation"
                            {...formik.getFieldProps("designation")}
                          />
                          {formik.touched.designation &&
                          formik.errors.designation ? (
                            <div className="form-error">
                              {formik.errors.designation}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>

                      {/* <Grid item xs={12}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Branch <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SuiSelect
                            className="filter"
                            placeholder="Select Branch"
                            options={[
                              {
                                value: formik.values.branch_name,
                                label: formik.values.branch_name,
                              },
      
                            ]}
                         
                            defaultValue={formik.values.branch_name}
                          />
                        </SoftBox>
                      </Grid> */}

                      {/* <Grid item md={12} xs={12} lg={12} xl={12}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Add Address <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftTextArea
                            placeholder={"Enter Your Full Address"}
                            rows={4}
                            cols={4}
                            {...formik.getFieldProps("address")}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className="form-error">
                              {formik.errors.address}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid> */}
                    </Grid>
                  </SoftBox>
                </SoftBox>
              )}

              {currentStep === 2 && (
                // <SoftBox className="second_Step">
                //     <SoftBox mt={2} component="form" role="form" className="working_hours" >
                //         <SoftBox display="flex" mb={2} spacing={2} alignItems="center" justifyContent="space-between" >
                //             <SoftTypography variant="h6">Add Working Hours Details</SoftTypography>
                //         </SoftBox>
                //         <Box
                //             sx={{
                //                 flexGrow: 1,
                //                 maxWidth: { xs: 320, sm: 480, md: '100%' },
                //                 bgcolor: 'background.paper',
                //             }}
                //         >
                //             <Tabs
                //                 value={value}
                //                 onChange={handleChange1}
                //                 variant="scrollable"
                //                 scrollButtons
                //                 aria-label="visible arrows tabs example"
                //                 sx={{
                //                     [`& .${tabsClasses.scrollButtons}`]: {
                //                         '&.Mui-disabled': { opacity: 0.3 },
                //                     },
                //                 }}
                //             >
                //                 {formik.values.slots.map((obj, index) => (
                //                     <Tab label={obj.day} key={index} />
                //                 ))}
                //             </Tabs>

                //             {formik.values.slots?.map((obj, index) => (
                //                 <TabPanel value={value} index={index} key={index}>
                //                     {obj?.slot?.map((time, timeIndex) => (
                //                         <Grid container spacing={2} key={timeIndex}>
                //                             <Grid item md={4} xs={12}>
                //                                 <SoftBox>
                //                                     <SoftBox mb={1} ml={0.5}>
                //                                         <SoftTypography
                //                                             component="label"
                //                                             variant="caption"
                //                                             fontWeight="bold"
                //                                         >
                //                                             From <span className="text-danger">*</span>
                //                                         </SoftTypography>
                //                                     </SoftBox>
                //                                     <SoftInput
                //                                         type="time"
                //                                         value="Brack"
                //                                         placeholder="Last Name"
                //                                         {...formik.getFieldProps(`slots.${index}.slot.${timeIndex}.start`)}
                //                                     />
                //                                 </SoftBox>
                //                             </Grid>
                //                             <Grid item md={4} xs={12}>
                //                                 <SoftBox>
                //                                     <SoftBox mb={1} ml={0.5}>
                //                                         <SoftTypography
                //                                             component="label"
                //                                             variant="caption"
                //                                             fontWeight="bold"
                //                                         >
                //                                             To <span className="text-danger">*</span>
                //                                         </SoftTypography>
                //                                     </SoftBox>
                //                                     <SoftInput
                //                                         type="time"
                //                                         {...formik.getFieldProps(`slots.${index}.slot.${timeIndex}.end`)}
                //                                     />
                //                                 </SoftBox>
                //                             </Grid>
                //                             <Grid item md={2} xs={12}>
                //                                 <SoftBox>
                //                                     <SoftBox mb={1} ml={0.5}>
                //                                         <SoftTypography
                //                                             component="label"
                //                                             variant="caption"
                //                                             fontWeight="bold"
                //                                         >
                //                                             Enable{" "}
                //                                             <span className="text-danger">*</span>
                //                                         </SoftTypography>
                //                                     </SoftBox>
                //                                     <Switch
                //                                         checked={checked}
                //                                         onChange={handleChange}
                //                                         inputProps={{ "aria-label": "controlled" }}
                //                                     />
                //                                 </SoftBox>
                //                             </Grid>
                //                             <Grid item md={1} xs={12}>
                //                                 <SoftBox className="icon-cntner">
                //                                     <SoftBox mb={1} ml={0.5}>
                //                                         <SoftTypography
                //                                             component="label"
                //                                             variant="caption"
                //                                             fontWeight="bold"
                //                                         >
                //                                             Clear
                //                                         </SoftTypography>
                //                                     </SoftBox>
                //                                     <IoMdCloseCircle onClick={() => handleSlotTimeDelete(index, timeIndex)} />
                //                                 </SoftBox>
                //                             </Grid>

                //                         </Grid>

                //                     ))}
                //                     <SoftBox
                //                         display="flex"
                //                         my={4}
                //                         alignItems="end"
                //                         style={{ width: "100%" }}
                //                         justifyContent="end"
                //                     >
                //                         <SoftButton
                //                             variant="contained"
                //                             size="small"
                //                             color="info"
                //                             onClick={() => handleAddSlot(index)}
                //                         >
                //                             Add New
                //                         </SoftButton>
                //                     </SoftBox>

                //                 </TabPanel>
                //             ))}
                //             {/* <TabPanel value={value} index={1}>
                //                 <Grid container spacing={2}>

                //                     <Grid item md={4} xs={12}  >
                //                         <SoftBox >
                //                             <SoftBox mb={1} ml={0.5}>
                //                                 <SoftTypography component="label" variant="caption" fontWeight="bold">
                //                                     From <span className='text-danger'>*</span>
                //                                 </SoftTypography>
                //                             </SoftBox>
                //                             <SoftInput type="time" value="Brack" placeholder="Last Name" />
                //                         </SoftBox>
                //                     </Grid>
                //                     <Grid item md={4} xs={12}  >
                //                         <SoftBox>
                //                             <SoftBox mb={1} ml={0.5}>
                //                                 <SoftTypography component="label" variant="caption" fontWeight="bold">
                //                                     To <span className='text-danger'>*</span>
                //                                 </SoftTypography>
                //                             </SoftBox>
                //                             <SoftInput type="time" value="+91812454412" placeholder="Number" />
                //                         </SoftBox>
                //                     </Grid>
                //                     <Grid item md={2} xs={12} >
                //                         <SoftBox>
                //                             <SoftBox mb={1} ml={0.5}>
                //                                 <SoftTypography component="label" variant="caption" fontWeight="bold">
                //                                     Enable <span className='text-danger'>*</span>
                //                                 </SoftTypography>
                //                             </SoftBox>
                //                             <Switch
                //                                 checked={checked}
                //                                 onChange={handleChange}
                //                                 inputProps={{ 'aria-label': 'controlled' }}
                //                             />
                //                         </SoftBox>
                //                     </Grid>
                //                     <Grid item md={1} xs={12} >
                //                         <SoftBox className="icon-cntner">
                //                             <SoftBox mb={1} ml={0.5}>
                //                                 <SoftTypography component="label" variant="caption" fontWeight="bold">
                //                                     Clear
                //                                 </SoftTypography>
                //                             </SoftBox>
                //                             <IoMdCloseCircle />
                //                         </SoftBox>
                //                     </Grid>

                //                     <SoftBox display="flex" my={4} alignItems="end" style={{ width: '100%' }} justifyContent="end">
                //                         <SoftButton variant="contained" size="small" color="info">Add New</SoftButton>

                //                     </SoftBox>

                //                 </Grid>
                //             </TabPanel>
                //             <TabPanel value={value} index={2}>
                //                 <Grid container spacing={2}>

                //                     <Grid item md={4} xs={12}  >
                //                         <SoftBox >
                //                             <SoftBox mb={1} ml={0.5}>
                //                                 <SoftTypography component="label" variant="caption" fontWeight="bold">
                //                                     From <span className='text-danger'>*</span>
                //                                 </SoftTypography>
                //                             </SoftBox>
                //                             <SoftInput type="time" value="Brack" placeholder="Last Name" />
                //                         </SoftBox>
                //                     </Grid>
                //                     <Grid item md={4} xs={12}  >
                //                         <SoftBox>
                //                             <SoftBox mb={1} ml={0.5}>
                //                                 <SoftTypography component="label" variant="caption" fontWeight="bold">
                //                                     To <span className='text-danger'>*</span>
                //                                 </SoftTypography>
                //                             </SoftBox>
                //                             <SoftInput type="time" value="+91812454412" placeholder="Number" />
                //                         </SoftBox>
                //                     </Grid>
                //                     <Grid item md={2} xs={12} >
                //                         <SoftBox>
                //                             <SoftBox mb={1} ml={0.5}>
                //                                 <SoftTypography component="label" variant="caption" fontWeight="bold">
                //                                     Enable <span className='text-danger'>*</span>
                //                                 </SoftTypography>
                //                             </SoftBox>
                //                             <Switch
                //                                 checked={checked}
                //                                 onChange={handleChange}
                //                                 inputProps={{ 'aria-label': 'controlled' }}
                //                             />
                //                         </SoftBox>
                //                     </Grid>
                //                     <Grid item md={1} xs={12} >
                //                         <SoftBox className="icon-cntner">
                //                             <SoftBox mb={1} ml={0.5}>
                //                                 <SoftTypography component="label" variant="caption" fontWeight="bold">
                //                                     Clear
                //                                 </SoftTypography>
                //                             </SoftBox>
                //                             <IoMdCloseCircle />
                //                         </SoftBox>
                //                     </Grid>

                //                     <SoftBox display="flex" my={4} alignItems="end" style={{ width: '100%' }} justifyContent="end">
                //                         <SoftButton variant="contained" size="small" color="info">Add New</SoftButton>

                //                     </SoftBox>

                //                 </Grid>
                //             </TabPanel> */}

                //         </Box>

                //     </SoftBox>

                // </SoftBox>

                <SoftBox className="second_Step">
                  <SoftBox
                    mt={2}
                    component="form"
                    role="form"
                    className="working_hours"
                  >
                    <SoftBox
                      display="flex"
                      mb={2}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">
                        Add Working Hours Details
                      </SoftTypography>
                    </SoftBox>
                    <Box
                      sx={{
                        flexGrow: 1,
                        maxWidth: { xs: 320, sm: 480, md: "100%" },
                        bgcolor: "background.paper",
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange1}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                          },
                        }}
                      >
                        {/* {formik.values.slots.map((obj, index) => (
                          <Tab label={obj.day} key={index} />
                        ))} */}
                        <Tab label="Monday" />
                        <Tab label="Tuesday" />
                        <Tab label="Wednesday" />
                        <Tab label="Thursday" />
                        <Tab label="Friday" />
                        <Tab label="Saturday" />
                        <Tab label="Sunday" />
                      </Tabs>

                      {/* {formik.values.slots.map((obj, index) => (
                        <TabPanel value={value} index={index} key={index}>
                          {obj?.slot.map((time, timeIndex) => (
                            <Grid container spacing={2} key={timeIndex}>
                              <Grid item md={4} xs={12}>
                                <SoftBox>
                                  <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                    >
                                      From{" "}
                                      <span className="text-danger">*</span>
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftInput
                                    type="time"
                                    placeholder="Last Name"
                                    {...formik.getFieldProps(
                                      `slots.${index}.slot.${timeIndex}.start`
                                    )}
                                    format="HH:mm"
                                  />
                                </SoftBox>
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <SoftBox>
                                  <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                    >
                                      To <span className="text-danger">*</span>
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftInput
                                    type="time"
                                    {...formik.getFieldProps(
                                      `slots.${index}.slot.${timeIndex}.end`
                                    )}
                                    format="HH:mm"
                                  />
                                </SoftBox>
                              </Grid>
                              <Grid item md={2} xs={12}>
                                <SoftBox>
                                  <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography
                                      component="label"
                                      variant="caption"
                                      fontWeight="bold"
                                    >
                                      Enable{" "}
                                      <span className="text-danger">*</span>
                                    </SoftTypography>
                                  </SoftBox>
                                  <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </SoftBox>
                              </Grid>
                              {timeIndex > 0 ? (
                                <Grid item md={1} xs={12}>
                                  <SoftBox className="icon-cntner">
                                    <SoftBox mb={1} ml={0.5}>
                                      <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                      >
                                        Clear
                                      </SoftTypography>
                                    </SoftBox>
                                    <IoMdCloseCircle
                                      onClick={() =>
                                        handleSlotTimeDelete(index, timeIndex)
                                      }
                                    />
                                  </SoftBox>
                                </Grid>
                              ) : null}
                            </Grid>
                          ))}
                          <SoftBox
                            display="flex"
                            my={4}
                            alignItems="end"
                            style={{ width: "100%" }}
                            justifyContent="end"
                          >
                            <SoftButton
                              variant="contained"
                              size="small"
                              color="info"
                              onClick={() => handleAddSlot(index)}
                            >
                              Add New
                            </SoftButton>
                          </SoftBox>
                        </TabPanel>
                      ))} */}

                      {daysOfWeek.map((day, dayIndex) => {
                 
                 const obj = dentistSlots?.find((slot) => slot.day === day );
                 const objIndex = dentistSlots.findIndex((slot) => slot.day === day);
                   
                 return (
                   <TabPanel
                     key={dayIndex}
                     value={value}
                     index={dayIndex}
                   >
                     <SoftBox className="timeSlotsContainer">
                       {obj ? (
                         obj.slot.map((slot, slotIndex) => (
                           <SoftBox
                             key={slotIndex}
                             className="time-slot"
                           >
                             <input
                               type="checkbox"
                               checked={slot.enable}
                               id={`time-slot-${dayIndex}-${slotIndex}`}
                             />
                             <label
                               htmlFor={`time-slot-${dayIndex}-${slotIndex}`}
                               onClick={() =>
                                 toggleSlot(objIndex, slotIndex)
                               }
                             >
                               {convertDateToTimeString(slot)}
                             </label>
                           </SoftBox>
                         ))
                       ) : (
                         <p>No slots available for {day}.</p>
                       )}
                     </SoftBox>
                   </TabPanel>
                 );
               })}

                    </Box>
                  </SoftBox>
                </SoftBox>
              )}

              {currentStep === 3 && (
                <SoftBox className="third_Step">
                  <SoftBox
                    mt={3}
                    component="form"
                    role="form"
                    className="working_hours"
                  >
                    <SoftBox
                      display="flex"
                      mb={2}
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftTypography variant="h6">
                        Add Services Details
                      </SoftTypography>
                      {/* <SoftButton variant="contained" size="small" color="info">
                        Add New
                      </SoftButton> */}
                    </SoftBox>
                  </SoftBox>
                  <Services
                    setServicesToParent={getServices}
                    serData={serData}
                    getServicesData = {getServicesData}
                  />

                  <div
                    className="selectWrpSm"
                    style={{ width: "86%", margin: "1rem 0" }}
                  >
                    <SuiSelect
                      className="filter selctServc mb-4"
                      placeholder="Select Day"
                      options={
                        getServicesData1?.map((val)=>({ value: val, label: val }))
                      //   [
                      //   { value: "Fillings", label: "Fillings" },
                      //   { value: "Crowns", label: "Crowns" },
                      //   { value: "Root Canal", label: "Root Canal" },
                      //   {
                      //     value: "Wisdom teeth Removal",
                      //     label: "Wisdom teeth Removal",
                      //   },
                      //   { value: "Teeth Whitening", label: "Teeth Whitening" },
                      //   { value: "Dental Bridge", label: "Dental Bridge" },
                      //   {
                      //     value: "Tooth Extraction",
                      //     label: "Tooth Extraction",
                      //   },
                      //   {
                      //     value: "Checkup $ Cleaning",
                      //     label: "Checkup $ Cleaning",
                      //   },
                      // ]
                    }
                      value={val}
                      defaultValue="Filling"
                      onChange={serviceHandler}
                    />
                  </div>
                </SoftBox>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <SoftButton variant='outlined' onClick={() => handleClose1()}>Cancel</SoftButton>
                    <SoftButton variant='contained' onClick={() => handleClose1()} autoFocus>
                        Done
                    </SoftButton> */}

            <SoftBox
              display="flex"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {currentStep === 1 && (
                <SoftButton
                  variant="outlined"
                  style={{ marginRight: "10px" }}
                  size="small"
                  color="info"
                  onClick={() => {
                    handleformikCheck();
                  }}
                >
                  Next
                </SoftButton>
              )}
              {currentStep === 2 && (
                <SoftBox>
                  <SoftButton
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    size="small"
                    color="info"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>

                  <SoftButton
                    variant="outlined"
                    size="small"
                    color="info"
                    onClick={handleNext}
                  >
                    Next
                  </SoftButton>
                </SoftBox>
              )}

              {currentStep === 3 && (
                <SoftBox>
                  <SoftButton
                    variant="contained"
                    size="small"
                    style={{ marginRight: "10px" }}
                    color="info"
                    onClick={handleBack}
                  >
                    Back
                  </SoftButton>

                  <SoftButton
                    variant="outlined"
                    size="small"
                    color="info"
                    onClick={() => handleAddProvider()}
                    type="submit"
                  >
                    Finish
                  </SoftButton>
                </SoftBox>
              )}
            </SoftBox>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
