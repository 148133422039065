// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Edit, Star, StarBorder } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SoftInput from "components/SoftInput";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";
import { useFormik } from "formik";
import { makeHttpRequest } from "utils/httpServices";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { toast } from "react-toastify";
import SuiSelect from "components/SuiSelect/SuiSelect";
import CloseIcon from '@mui/icons-material/Close';
import { generateImageFromName } from "utils/httpServices";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ReviewDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id, mode: initialMode, branchId  } = params;

  const [dentistList, setDentistList] = useState([]);

  const [mode, setMode] = useState(initialMode);

  const [ isEditable , setIsEditable] = useState(false)


  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL + "userProfile/";

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  const validationSchema = Yup.object({
    full_name: Yup.string().required("User Name is required"),
    // profile_image: Yup.string().required("User Profile image is required"),
    rating: Yup.number().min(1).max(5).required("Rating is required"),
    dentist_id: Yup.string().required("Dentist is required"),
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      rating: "",
      reviews: "",
      profile_image: "",
      dentist_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      const { full_name, profile_image, ...rest } = values;
      const body = {
        user: {
          full_name,
          profile_image,
        },
        ...rest,
        branch_id: branchId,
      };
      if (mode === "add") {
        handleAddReview(body);
      } else if (mode === "edit") {
        handleEditReview(body);
      }
    },
  });

  useEffect(() => {
    fetchDentistList();

    if (id) {
      fetchReviewDetail(id);
    }
  }, [id]);

  const fetchReviewDetail = async (review_id) => {
    try {
      const resp = await makeHttpRequest("get", `getSingleRating/${review_id}`);
      console.log("🚀 ~ fetchReviewDetail ~ resp:", resp);
      const data = resp?.data;

      formik.setValues({
        full_name: data.user?.full_name || "",
        rating: data.rating || "",
        reviews: data.reviews || "",
        profile_image: data.user?.profile_image || "",
        dentist_id: data.dentist_id || "",
      });

      setIsEditable(resp?.data?.rating_by === "admin")
    } catch (error) {
      console.log(error);
    }
  };



  const handleRatingChange = (rating) => {
    formik.setFieldValue("rating", rating);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    // if (file) {
    //   setImage(URL.createObjectURL(file));
    // }
    const formData = new FormData();
    formData.append("path", "userProfile");
    formData.append("media", file);

    try {
      const resp = await makeHttpRequest("post", "uploadAdminMedia", formData);

      formik.setFieldValue("profile_image", resp.path);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddReview = async (body) => {
    if(!body?.user.profile_image){
      const resp = await generateImageFromName(body?.user?.full_name)
      body.user.profile_image = resp
    }

    
    try {
      const resp = await makeHttpRequest("post", "giveRating", body);
      toast.success("Review added successfully");
      navigate(-1);
    } catch (error) {
      //handle error
    }
  };

  const handleEditReview = async (body) => {
    if (!id) {
      console.log("Rating is not found");
      return;
    }
    try {
      const resp = await makeHttpRequest("patch", `editRating/${id}`, body);
      toast.success("Review updated successfully");
      navigate(-1);
    } catch (error) {
      //handle error
    }
  };

  const fetchDentistList = async () => {
    const param = {
      branch_id: branchId,
      sort: 1,
    };
    try {
      const resp = await makeHttpRequest(
        "get",
        "getDentistListing",
        null,
        param
      );
      const formattedList = resp?.data?.map((item) => ({
        value: item?._id,
        label: item?.full_name,
      }));
      setDentistList(formattedList);
    } catch (error) {}
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <form onSubmit={formik.handleSubmit}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="h6">
                  {mode === "view"
                    ? "View Review"
                    : mode === "edit"
                    ? "Edit Review"
                    : "Add Review"}
                </SoftTypography>
                 {
                   (mode === "view" && isEditable) && <Edit onClick={() => handleModeChange('edit')}/> 
                 }   
                 {
                   (mode === "edit" && initialMode === "view" ) && <CloseIcon onClick={() => handleModeChange('view')} /> 
                 }   
              </SoftBox>

              <SoftBox>
                <Card style={{ padding: 20, margin: "0 auto" }}>
                  <Box component="form" noValidate autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4}>
                        <Item>
                          {/*--------------------------------- Image Upload-------------------------------------- */}
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              upload image of the user{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <Box display="flex" alignItems="center" marginY={2}>
                            {mode !== "view" && (
                              <label htmlFor="upload-image">
                                <InputAdornment position="start">
                                  <IconButton color="primary" component="span">
                                    {formik.values.profile_image ? (
                                      <Edit />
                                    ) : (
                                      <PhotoCamera />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              </label>
                            )}
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="upload-image"
                              type="file"
                              onChange={handleImageUpload}
                            />
                            {formik.values.profile_image && (
                              <img
                                src={imageBaseUrl + formik.values.profile_image}
                                alt="Uploaded User"
                                style={{
                                  width: 80,
                                  height: 80,
                                  marginLeft: 16,
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </Box>
                          {formik.errors.profile_image &&
                          formik.touched.profile_image ? (
                            <p className="form-error">
                              {formik.errors.profile_image}
                            </p>
                          ) : null}
                        </Item>
                      </Grid>
                      <Grid item xs={6} md={8}>
                        <Item>
                          {/*------------------------- User Name Input--------------------------------- */}
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Enter the name of the user on behalf of whom you
                              are entering the review{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            fullWidth
                            type="text"
                            placeholder="Enter name"
                            {...formik.getFieldProps("full_name")}
                            disabled={mode === "view"}
                          />
                          {formik.errors.full_name &&
                          formik.touched.full_name ? (
                            <p className="form-error">
                              {formik.errors.full_name}
                            </p>
                          ) : null}

                          {/*--------------------------- select Dentist------------------------- */}
                          <Box marginY={2}>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Select Dentist{" "}
                                <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>

                            <SuiSelect
                              className="filter selctServc mb-4"
                              placeholder="Select Services"
                              options={dentistList}
                              value={formik.values?.dentist_id}
                              defaultValue=""
                              onChange={(selected) =>
                                formik.setFieldValue("dentist_id", selected)
                              }
                              disabled={mode === 'view'}
                            />
                            {formik.errors.dentist_id &&
                            formik.touched.dentist_id ? (
                              <p className="form-error">
                                {formik.errors.dentist_id}
                              </p>
                            ) : null}
                          </Box>

                          {/*------------------ Star Rating ----------------------------*/}
                          <Box marginY={2}>
                            <Box display="flex" alignItems="center">
                              <SoftTypography variant="h6">
                                Rating:{" "}
                              </SoftTypography>
                              <Box display="flex" marginLeft={2}>
                                {[...Array(5)].map((_, index) => (
                                  <IconButton
                                    key={index}
                                    onClick={() =>
                                      handleRatingChange(index + 1)
                                    }
                                    color="primary"
                                    aria-label={`star-${index}`}
                                    disabled={mode === "view"}
                                  >
                                    {index < formik.values.rating ? (
                                      <Star />
                                    ) : (
                                      <StarBorder />
                                    )}
                                  </IconButton>
                                ))}
                              </Box>
                            </Box>
                            {formik.errors.rating && formik.touched.rating ? (
                              <p className="form-error">
                                {formik.errors.rating}
                              </p>
                            ) : null}
                          </Box>

                          {/*--------------------- Review Text----------------------------- */}
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Enter review (optional)
                              {/* <span className="text-danger">*</span> */}
                            </SoftTypography>
                          </SoftBox>
                          <SoftTextArea
                            label="Review"
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            {...formik.getFieldProps("reviews")}
                            disabled={mode === "view"}
                          />
                        </Item>
                      </Grid>
                    </Grid>

                    {/*-------------------------------- Submit Button-------------------------- */}
                    {mode !== "view" && (
                      <SoftButton
                        variant="contained"
                        color="info"
                        style={{ marginTop: 20 }}
                        // type="submit"
                        onClick={formik.submitForm}
                      >
                        {mode === "edit" ? "Update" : "Add"} Review
                      </SoftButton>
                    )}
                    <SoftButton
                      variant="contained"
                      component={Link}
                      onClick={() => navigate(-1)}
                      color="info"
                      style={{ marginTop: 20, marginLeft: 10 }}
                    >
                      Back
                    </SoftButton>
                  </Box>
                </Card>
              </SoftBox>
            </form>
          </Card>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default ReviewDetail;
