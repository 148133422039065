import SoftBox from 'components/SoftBox'
import React from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa'
import user01 from "assets/images/clinicImgs.jpg"
import { toast } from 'react-toastify';


export default function Banner({getBackImg, backSelectdImg, setBackSelectdImg, backUrlImg, setBackUrlImg}) {
    const imageHandler = (e) => {

        try {
            const allowedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg'];
            const file = e.target.files[0];
            console.log(file)
            const fileName = file.name;
            const allowedExt = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;

            // Checking for image extension
            if (allowedExt.test(fileName) === false) {
                toast.info(`Invalid file extension. Please select a file with one of the following extensions: ${allowedImageExtensions.join(', ')}`);
                return;

            }

            if (file) {

                setBackSelectdImg(file);
                const reader = new FileReader()

                reader.onload = (event) => {
                    setBackUrlImg(event.target.result)
                    console.log(event.target.result)
                }
                reader.readAsDataURL(file)
            }
        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }
    }
    console.log(backSelectdImg)
    return (
        <SoftBox className='banner_cntainer'>
            <SoftBox className="Image_Wrappr">
                <SoftBox mb={3} className="Image_Wrappr_inner">
                    <SoftBox className="image-container" >
                        
                        {backSelectdImg ? <img src={backUrlImg} width={100} height={100} /> :
                            getBackImg ? <img src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${getBackImg}`} width={100} height={100} /> :
                                <img src='https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png'
                                    width={100} height={100} alt='' />
                        }
                    </SoftBox>
                    <label htmlFor="uploaddd-image4"><FaCloudUploadAlt /></label>
                    <input type="file" id="uploaddd-image4" onChange={imageHandler}/>

                </SoftBox>


            </SoftBox>

        </SoftBox>
    )
}
