import { Card, Grid } from '@mui/material'
import DefaultInfoCard from 'components/DefaultInfoCard'
import DeleteModal from 'components/DeleteModal/DeleteModal'
// import DialogBoxFAQ from 'components/DialogBox/DialogBoxFAQ'
import Header from 'components/Header/Banner'

import SoftBox from 'components/SoftBox'
import SoftButton from 'components/SoftButton'
import SoftInput from 'components/SoftInput'
import SoftTextArea from 'components/SoftTextArea/SoftTextArea'
import SoftTypography from 'components/SoftTypography'
import Footer from 'examples/Footer'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Table from 'examples/Tables/Table'
import React, { useState, useEffect } from 'react'
import { FaSearchPlus } from 'react-icons/fa'
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { makeHttpRequest } from 'utils/httpServices'
import DialogBoxFAQ2 from 'components/DialogBox/DialogBoxFAQ2'

export default function SupportManagement() {



    const [open, setOpen] = React.useState(false);
    const [update, setUpdate] = useState(false)
    const [id, setId] = useState('');

    const [getBackImg, setGetBackImg] = useState(null);
    const [backSelectdImg, setBackSelectdImg] = useState(null);
    const [backUrlImg, setBackUrlImg] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletingRow, setDeletingRow] = useState(null);
    const [boxId, setBoxId] = useState('');
    const [userTableData, setUserTableData] = useState({
        columns: [
            { name: "questions", align: "left" },
            { name: "answer", align: "left" },
            { name: "action", align: "center" },
        ],
        rows: []    
    });


    const [cardData, setCardData] = useState([
        {
            icon: '',
            title: '',
            description: ''
        },
        {
            icon: '',
            title: '',
            description: ''
        },
        {
            icon: '',
            title: '',
            description: ''
        }
    ]);

    const formik = useFormik({

        initialValues: {
            first_title: '',
            description: '',
            second_title: '',
            second_description: '',
            third_title: '',
            third_description: ''
        }
    });

    const submitHandler = async (e) => {
        e.preventDefault();
        try {

            if (!formik.values.first_title || !formik.values.description || !backSelectdImg || !formik.values.second_title
                || !formik.values.second_description || !cardData[0].icon || !cardData[0].title || !cardData[0].description
                || !cardData[1].icon || !cardData[1].title || !cardData[1].description || !cardData[2].icon ||
                !cardData[2].title || !cardData[2].description || !formik.values.third_title || !formik.values.third_description) {

                toast.info('All fields are mandatory.');
                return;
            }


            let firstImg = '';

            if (backSelectdImg) {
                const formDataObject1 = new FormData();
                formDataObject1.append('path', 'pageImages');
                formDataObject1.append('media', backSelectdImg);

                const resp = await makeHttpRequest('post', 'uploadAdminMedia', formDataObject1);
                firstImg = resp.path;
            }

            const objData = {
                first_section: {
                    title: formik.values.first_title,
                    description: formik.values.description,
                    background_image: firstImg
                },
                second_section: {
                    title: formik.values.second_title,
                    description: formik.values.second_description
                },
                card_section: [
                    {
                        icon: cardData[0].icon,
                        title: cardData[0].title,
                        description: cardData[0].description
                    },
                    {
                        icon: cardData[1].icon,
                        title: cardData[1].title,
                        description: cardData[1].description
                    },
                    {
                        icon: cardData[2].icon,
                        title: cardData[2].title,
                        description: cardData[2].description
                    }
                ],
               faq_section: {
                    title: formik.values.third_title,
                    description: formik.values.third_description
                }
            }


            const resp = await makeHttpRequest('post', 'addSupportContent', objData);
            console.log(resp);
            toast.success('Added Successfully.')
        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }
    }

    const updateHandler = async (e) => {

        e.preventDefault();
        try {
            const firstIMG = backSelectdImg ? backSelectdImg : getBackImg;

            if (!formik.values.first_title || !formik.values.description || !firstIMG || !formik.values.second_title
                || !formik.values.second_description || !cardData[0].icon || !cardData[0].title || !cardData[0].description
                || !cardData[1].icon || !cardData[1].title || !cardData[1].description || !cardData[2].icon ||
                !cardData[2].title || !cardData[2].description || !formik.values.third_title || !formik.values.third_description) {

                toast.info('All fields are mandatory.');
                return;
            }


            let firstImg = null;
            // let secondImg = null;

            if (backSelectdImg) {
                const formDataObject = new FormData();
                formDataObject.append('path', 'pageImages');
                formDataObject.append('media', backSelectdImg);

                const resp = await makeHttpRequest('post', 'uploadAdminMultipleMedia', formDataObject);
                firstImg = resp.path[0];
            } else {
                firstImg = getBackImg;
            }

            const objData = {
                first_section: {
                    title: formik.values.first_title,
                    description: formik.values.description,
                    background_image: firstImg
                },
                second_section: {
                    title: formik.values.second_title,
                    description: formik.values.second_description
                },
                faq_section: {
                    title: formik.values.third_title,
                    description: formik.values.third_description
                },
                card_section: [
                    {
                        icon: cardData[0].icon,
                        title: cardData[0].title,
                        description: cardData[0].description
                    },
                    {
                        icon: cardData[1].icon,
                        title: cardData[1].title,
                        description: cardData[1].description
                    },
                    {
                        icon: cardData[2].icon,
                        title: cardData[2].title,
                        description: cardData[2].description
                    }
                ]
                
            }


            const resp = await makeHttpRequest('patch', `editSupportContent/${id}`, objData);
            console.log(resp);
            toast.success('Updated Successfully.')
        } catch (e) {
            toast.error('Network Error');
            console.log(e);
        }
    }

    const getData = async () => {

        try {
            const resp = await makeHttpRequest('get', 'getSupportContent');
            console.log(resp);

            if (resp) {
                setUpdate(true);
                console.log(resp?.data?._id)
                setId(resp?.data?._id)
            }


            

            formik.setValues({

                first_title: resp?.data?.first_section?.title || '',
                description: resp?.data?.first_section?.description || '',
                second_title: resp?.data?.second_section?.title || '',
                second_description: resp?.data?.second_section?.description || '',
                third_title: resp?.data?.faq_section?.title || '',
                third_description: resp?.data?.faq_section?.description || ''
            });

            setGetBackImg(resp?.data?.first_section?.background_image || '');
            setCardData(resp?.data?.card_section || cardData);


            const Resp = await makeHttpRequest('get', 'getFaqs', null, { type: 'support', sort: 1 });
            console.log(Resp?.item)

            setUserTableData({
                ...userTableData,  // Copy the existing state properties
                rows: Resp?.item.map((val) => ({
                    // Image: (
                    //     <SoftBox>
                    //         <img src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${val?.image}`} width={100} height={100} style={{ objectFit: "cover" }} alt="user" />
                    //     </SoftBox>
                    // ),
                    questions: (
                        <SoftTypography className="Question data" variant="h6">{val?.question}</SoftTypography>
                    ),
                    answer: (
                        <SoftTypography className="Answer data" variant="h6">{val?.answer}</SoftTypography>
                    ),
                    action: (<SoftBox>
                        {/* <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                        //   onClick={handleOpenModal}
                        >
                            View
                        </SoftTypography> */}
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                            onClick={() => { handleClickOpen();setBoxId(val?._id)}}
                        >
                            Edit
                        </SoftTypography>
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            onClick={() => handleDeleteClick(val)}
                        >
                            Delete
                        </SoftTypography>
                    </SoftBox>)
                }))
            });


        } catch (error) {
            console.log(error)
            toast.error("Network Error.")
        }
    }

    useEffect(() => {

        getData();

    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteClick = (rowData) => {
        setDeletingRow(rowData);
        setDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async() => {
        try {
            const resp = await makeHttpRequest('delete', `deleteFaq/${deletingRow?._id}`)
            const Resp = await makeHttpRequest('get', 'getFaqs', null, { type: 'support', sort: 1 });
            console.log(Resp?.item)

            setUserTableData({
                ...userTableData,  // Copy the existing state properties
                rows: Resp?.item.map((val) => ({
                    Image: (
                        <SoftBox>
                            <img src={`${process.env.REACT_APP_IMAGE_URL}pageImages/${val?.image}`} width={100} height={100} style={{ objectFit: "cover" }} alt="user" />
                        </SoftBox>
                    ),
                    questions: (
                        <SoftTypography className="Question data" variant="h6">{val?.question}</SoftTypography>
                    ),
                    answer: (
                        <SoftTypography className="Answer data" variant="h6">{val?.answer}</SoftTypography>
                    ),
                    action: (<SoftBox>
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                        //   onClick={handleOpenModal}
                        >
                            View
                        </SoftTypography>
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                            onClick={() => { handleClickOpen(); setBoxId(val?._id); }}
                        >
                            Edit
                        </SoftTypography>
                        <SoftTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            onClick={() => handleDeleteClick(val)}
                        >
                            Delete
                        </SoftTypography>
                    </SoftBox>)
                }))
            });
            // const updatedRows = userTableData.rows.filter((row) => row !== deletingRow);
            // setUserTableData({ ...userTableData, rows: updatedRows });
            setDeleteModalOpen(false);
            toast.success('Deleted successfully')
        } catch (e) {
            toast.error('Network Error');
            console.log(e)
        }
    };

    // Function to render action buttons for each row
    // const renderActionButtons = (row) => (
    //     <SoftBox>
    //         <SoftTypography
    //             component="a"
    //             href="#"
    //             variant="caption"
    //             color="secondary"
    //             fontWeight="medium"
    //             marginRight="10px"
    //         //   onClick={handleOpenModal}
    //         >
    //             View
    //         </SoftTypography>
    //         <SoftTypography
    //             component="a"
    //             href="#"
    //             variant="caption"
    //             color="secondary"
    //             fontWeight="medium"
    //             marginRight="10px"
    //             onClick={() => () => { handleClickOpen(); setBoxId(val?._id); }}
    //         >
    //             Edit
    //         </SoftTypography>
    //         <SoftTypography
    //             component="a"
    //             href="#"
    //             variant="caption"
    //             color="secondary"
    //             fontWeight="medium"
    //             onClick={() => handleDeleteClick(row)}
    //         >
    //             Delete
    //         </SoftTypography>
    //     </SoftBox>
    // );



    return (
        <DashboardLayout >
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        {/* ---Home--Page--First__section */}
                        <SoftBox p={3}>

                            <SoftBox mb={3}>
                                <SoftBox className="main-title" mb={3}>
                                    <SoftTypography variant="h6">First Section</SoftTypography>
                                </SoftBox>

                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        First Title
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="text" value="Find Your Best Dentist In Your City" placeholder="Last Name" name='first_title'
                                    {...formik.getFieldProps('first_title')} 
                                    />
                            </SoftBox>

                            <SoftBox mb={2} >
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Description <span className='text-danger'>*</span>
                                    </SoftTypography>
                                </SoftBox>
                                <SoftTextArea placeholder={"Add some description"}
                                    rows={3}
                                    cols={3} 
                                    name='description'
                                    {...formik.getFieldProps('description')} 
                                    
                                    />
                            </SoftBox>
                            <Header backSelectdImg={backSelectdImg} setBackSelectdImg={setBackSelectdImg}
                            backUrlImg={backUrlImg} setBackUrlImg={setBackUrlImg} getBackImg={getBackImg} setGetBackImg={setGetBackImg}
                            />

                        </SoftBox>

                        {/* ---Home--Page--Second__section */}
                        <SoftBox p={3}>
                            <SoftBox className="main-title" mb={3}>
                                <SoftTypography variant="h6">Second Section</SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        First Title
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="text" value="Find Your Best Dentist In Your City" placeholder="Last Name"
                                 name='second_title'
                                 {...formik.getFieldProps('second_title')} 
                                />
                            </SoftBox>

                            <SoftBox mb={3}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Description <span className='text-danger'>*</span>
                                    </SoftTypography>
                                </SoftBox>
                                <SoftTextArea placeholder={"Add some description"}
                                    rows={3}
                                    cols={3} 
                                    name='second_description'
                                    {...formik.getFieldProps('second_description')}
                                    />
                            </SoftBox>

                            <Grid container spacing={4}>

                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCard

                                        icon={<FaSearchPlus />}
                                        title="Your Title"
                                        description="Your Description"

                                        setCardData={setCardData}
                                        cardData={cardData}
                                        index={0}
                                    />

                                </Grid>
                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCard

                                        icon={<FaSearchPlus />}
                                        title="Your Title"
                                        description="Your Description"

                                        setCardData={setCardData}
                                        cardData={cardData}
                                        index={1}
                                    />

                                </Grid>
                                <Grid item md={4} xs={4} lg={4} xl={4}>
                                    <DefaultInfoCard

                                        icon={<FaSearchPlus />}
                                        title="Your Title"
                                        description="Your Description"

                                        setCardData={setCardData}
                                        cardData={cardData}
                                        index={2}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid item md={12}>
                                <SoftBox my={2} display="flex" alignItems="end" justifyContent="end">
                                    <SoftButton variant="contained" color="info">Add New</SoftButton>
                                </SoftBox>
                            </Grid> */}
                        </SoftBox>

                        {/* ---Home--Page--Third__section */}
                        <SoftBox p={3}>
                            <SoftBox className="main-title" mb={3}>
                                <SoftTypography variant="h6">Third Section</SoftTypography>
                            </SoftBox>
                            <SoftBox mb={1}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        First Title
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="text" value="Find Your Best Dentist In Your City" placeholder="Last Name" 
                                 name='third_title'
                                 {...formik.getFieldProps('third_title')}
                                />
                            </SoftBox>

                            <SoftBox mb={3}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Description <span className='text-danger'>*</span>
                                    </SoftTypography>
                                </SoftBox>
                                <SoftTextArea placeholder={"Add some description"}
                                    rows={3}
                                    cols={3} 
                                    name='third_description'
                                    {...formik.getFieldProps('third_description')}
                                    />
                            </SoftBox>

                            <Card className="question_cntainer">
                                <SoftBox p={2}>
                                    <Grid item md={4}>
                                        <SoftBox display="flex" alignItems="end" justifyContent="end">
                                            <SoftButton variant="contained" onClick={() => handleClickOpen()} color="info">Add New</SoftButton>
                                        </SoftBox>
                                    </Grid>

                                    <Table
                                        columns={userTableData.columns}
                                        rows={userTableData.rows}
                                    />
                                    <DeleteModal
                                        isOpen={isDeleteModalOpen}
                                        onClose={() => setDeleteModalOpen(false)}
                                        onConfirm={handleDeleteConfirm}
                                    />
                                    {/* <DialogBoxFAQ2 handleClose={handleClose} open={open} /> */}
                                    <DialogBoxFAQ2 handleClose={() => {handleClose(); setBoxId();}} open={open} boxId={boxId} setUserTableData={setUserTableData} userTableData={userTableData}
                                        handleClickOpen={handleClickOpen} setBoxId={setBoxId} handleDeleteClick={handleDeleteClick} />

                                </SoftBox>

                            </Card>
                        </SoftBox>
                    </Card>
                </SoftBox>
                <SoftBox display="flex" justifyContent="center" alignItems="center" pb={3}>
                    {update?<SoftButton variant="contained" color="info" onClick={updateHandler}>Update</SoftButton>
                    :<SoftButton variant="contained" color="info" onClick={submitHandler}>Save</SoftButton>}
                    
                </SoftBox>
            </SoftBox>
            {/* <Footer /> */}
        </DashboardLayout>
    )
}
