// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { Link } from "react-router-dom";

// Data
import UserTableData from "./data/UserTableData";
import { Author } from "./data/UserTableData";
import SoftInput from "components/SoftInput";
import { Grid, TablePagination } from "@mui/material";
import SuiSelect from "components/SuiSelect/SuiSelect";
import { useEffect } from "react";
import { makeHttpRequest } from "utils/httpServices";
import SoftBadge from "components/SoftBadge";

import { toast } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "components/DeleteModal/DeleteModal";
import Pagination from "../pagination/index";

// import projectsTableData from "layouts/tables/data/projectsTableData";

function UserManagement() {
  const [rows, setRows] = useState([]);
  const { columns } = UserTableData;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const handleDeleteConfirm = async (id) => {
    try {
      const resp = await makeHttpRequest("delete", `deleteUser/${id}`);
      toast.success("Deleted successfully");
      console.log(resp);
      getData();
      setDeleteModalOpen(false);
    } catch (e) {
      toast.error("Network Error");
      console.log(e);
    }
  };

  const deleteHandler = (value) => {
    setDeleteId(value);
    setDeleteModalOpen(true);
  };
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    console.log(`Selected page: ${value}`);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const getData = async (val) => {
    try {
      const offset = currentPage* rowsPerPage;
  
      const param = {
        name: val,
        sort: -1,
        offset: offset,
        limit: rowsPerPage,
      };
      const resp = await makeHttpRequest("get", "getUserListing", null, param);
      setTotalCount(resp?.count);
      const count1 = Math.ceil(resp?.count / 10);
      setTotalPage(count1);
      let row_data = resp?.data.map((val) => ({
        author: (
          <Author
            image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.profile_image}`}
            name={val?.full_name}
            email={val?.email}
          />
        ),
        Number: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {val?.phone_number}
          </SoftTypography>
        ),
        status: (
          <SoftBadge
            variant="gradient"
            badgeContent={val?.status}
            color={val?.status === "active" ? "success" : "error"}
            size="xs"
            container
          />
        ),
        Joined: (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {new Date(val?.createdAt).toLocaleString()}
          </SoftTypography>
        ),
        action: (
          <SoftBox>
            <SoftTypography
              component={Link}
              to={`/viewUserManagement/${val?._id}`}
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              marginRight="10px"
            >
              View
            </SoftTypography>
            <SoftTypography
              component={Link}
              to={`/editUserManagement/${val?._id}`}
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              marginRight="10px"
              // onClick={()=>navigate(`/editUserManagement/${val?._id}`)}
            >
              Edit
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              onClick={() => deleteHandler(val?._id)}
            >
              Delete
            </SoftTypography>
          </SoftBox>
        ),
      }));
      setRows(row_data);
      console.log(resp);
    } catch (error) {
      console.log(error);
      toast.error("Network Error.");
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getData(search);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [search, currentPage, rowsPerPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <Grid item xs={12} md={8}>
                <SoftBox
                  className="table_header"
                  display="flex"
                  alignItems="center"
                >
                  <SoftTypography variant="h6">Users List</SoftTypography>
                  <SoftBox
                    ml={2}
                    className="filter_box"
                    display="flex"
                    gap="15px"
                  >
                    <SoftInput
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onChange={(e) => setSearch(e.target.value)}
                    />

                    {/* <SuiSelect className="filter"
                      placeholder="Select month"
                      options={[
                        { value: "Month", label: "Month" },
                        { value: "january", label: "January" },
                        { value: "february", label: "February" },
                        { value: "march", label: "March" },
                        // ... other providers
                      ]}
                      defaultValue="Month"
                      onChange={(selectedValue) => {
                        console.log("Selected Month:", selectedValue);
                      }}
                    /> */}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />

              <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={() => handleDeleteConfirm(deleteId)}
              />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
export default UserManagement;
