import SoftBox from 'components/SoftBox'
import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import SoftTypography from 'components/SoftTypography';


// images---


import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Card } from '@mui/material';

export default function ImageSlider({data}) {
// console.log(data,clinicDocument)
// console.log(data)

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    return (

        <Card  sx={{ height: "100%" }}>
            <SoftBox pt={2} px={2}>
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Clinic Images
                </SoftTypography>
            </SoftBox>
            <SoftBox p={4}>
                <Slider {...settings}>
                    {/* Your slide content goes here */}
                   {data?data.map((val)=>(<SoftBox>
                       <img src={`${process.env.REACT_APP_IMAGE_URL}clinicDocument/${val}`} className='img-responsive' alt="clinic " />
                    </SoftBox>)):null}
                    {/* <SoftBox>
                       <img src={team2} className='img-responsive' alt="clinic " />
                    </SoftBox>
                  
                    <SoftBox>
                       <img src={team3} className='img-responsive' alt="clinic " />
                    </SoftBox>
                  
                    <SoftBox>
                       <img src={team4} className='img-responsive' alt="clinic " />
                    </SoftBox> */}
                  
                  
                </Slider>
            </SoftBox>
        </Card>

    )
}
