import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { Grid } from '@mui/material';
import SoftInput from 'components/SoftInput';
import { toast } from 'react-toastify';
import { makeHttpRequest } from 'utils/httpServices';
import { useFormik } from 'formik';


const ViewEnquiryModal = ({ isOpen, onClose, id }) => {




    const formik = useFormik({
        initialValues: {
            email: '',
            number: '',
            practic_name: ''
        }
    })
    const getData = async () => {
        try {
            const resp = await makeHttpRequest('get', `getEnquiry/${id}`)
            console.log(resp)
            formik.setValues({
                email: resp?.data?.email || '',
                number: resp?.data?.number || '',
                practic_name: resp?.data?.practic_name || ''
            })
        } catch (error) {
            toast.error('Network error.')
        }
    }
    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id])
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogContent>
                <DialogContentText>
                    <SoftBox mb={3} component="form" role="form" className="appointment_form" >
                        <SoftTypography variant="h6">Practic Details</SoftTypography>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} lg={12} xl={12}>
                                <SoftBox >
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Practic Name <span className='text-danger'>*</span>
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="text" value={formik?.values?.practic_name} placeholder="Last Name" />
                                </SoftBox>
                            </Grid>
                            <Grid item md={6} xs={12} lg={6} xl={6}>
                                <SoftBox>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Mobile Number <span className='text-danger'>*</span>
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="text" value={formik?.values?.number} placeholder="Number" />
                                </SoftBox>
                            </Grid>
                            <Grid item md={6} xs={12} lg={6} xl={6}>
                                <SoftBox >
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Email ID <span className='text-danger'>*</span>
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="email" value={formik?.values?.email} placeholder="email" />
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </SoftBox>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

export default ViewEnquiryModal;
