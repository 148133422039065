import { useNavigate } from "react-router-dom";
export const PrivateRoute = ({ children}) => {
  const navigate = useNavigate()
    const isAuthenticated = localStorage.getItem("token");
        
    if (isAuthenticated ) {
      return children
    }
      
    return navigate("/authentication/sign-in") 
  }