import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { Grid } from '@mui/material';
import SoftInput from 'components/SoftInput';
import { toast } from 'react-toastify';
import { makeHttpRequest } from 'utils/httpServices';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useEffect } from 'react';
import SoftButton from "components/SoftButton";

const EditEnquiryModal = ({ isOpen, onClose, id, setRows, setId }) => {

    console.log('dfsdfid')
    const handleSubmit = async(values)=>{
        console.log(values)
        try {
            const resp = await makeHttpRequest('patch', `editEnquiry/${id}`, values);
            const param = {
                sort:-1
            }
            const resp1 = await makeHttpRequest('get', 'enquiryListing', null, param)
            console.log(resp)
            setRows(resp1?.data.map((val)=>{
                return  {
                    email: val?.email,
                    number: val?.number,
                    "practic Name": val?.practic_name,
                    id:val?._id
                  }
            }))
            onClose();
            toast.success('Updated successfully.')
        } catch (error) {
            toast.error('Network error.');
            console.log(error)
        }
    }
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Enter valid email.').required("Email is required"),
        number: Yup.string()
            .required("Phone Number is required")
            .matches(/^[0-9]+$/, "Phone Number must only contain numbers"),
        practic_name: Yup.string().required("Practice name is required.")
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            number: '',
            practic_name: ''
        },
        validationSchema:validationSchema,
        onSubmit:handleSubmit
    })

    const getData = async () => {
        console.log('start')
        try {
            const resp = await makeHttpRequest('get', `getEnquiry/${id}`)
            console.log('dhfdhh5251')
            formik.setValues({
                email: resp?.data?.email || '',
                number: resp?.data?.number || '',
                practic_name: resp?.data?.practic_name || ''
            })
        } catch (error) {
            toast.error('Network error.')
        }
        console.log("end")
    }

    useEffect(() => {
        if (id) {
            console.log('jhbdasjkabkjbdjkasdbjasdbjbdjkjkasdbjaksdb')
            getData();
        }
    }, [id])
    // const handleBack = ()={

    // }
    return (
        <Dialog open={isOpen} onClose={onClose}>
        <form onSubmit={formik.handleSubmit}>
            <DialogContent>
                <DialogContentText>
                    <SoftBox mb={3} component="form" role="form" className="appointment_form" >
                        <SoftTypography variant="h6">Practic Details</SoftTypography>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} lg={12} xl={12}>
                                <SoftBox >
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Practic Name <span className='text-danger'>*</span>
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="text" placeholder="Last Name"   {...formik.getFieldProps("practic_name")}
                                    />
                                    {formik.touched.practic_name &&
                                        formik.errors.practic_name ? (
                                        <div className="form-error">
                                            {formik.errors.practic_name}
                                        </div>
                                    ) : null}
                                </SoftBox>
                            </Grid>
                            <Grid item md={6} xs={12} lg={6} xl={6}>
                                <SoftBox>
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Mobile Number <span className='text-danger'>*</span>
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="tel" placeholder="Number" {...formik.getFieldProps("number")}
                                    />
                                    {formik.touched.number &&
                                        formik.errors.number ? (
                                        <div className="form-error">
                                            {formik.errors.number}
                                        </div>
                                    ) : null}
                                </SoftBox>
                            </Grid>
                            <Grid item md={6} xs={12} lg={6} xl={6}>
                                <SoftBox >
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Email ID <span className='text-danger'>*</span>
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="email" placeholder="email" {...formik.getFieldProps("email")}
                                    />
                                    {formik.touched.email &&
                                        formik.errors.email ? (
                                        <div className="form-error">
                                            {formik.errors.email}
                                        </div>
                                    ) : null}
                                </SoftBox>
                            </Grid>

                        </Grid>
                       
                    </SoftBox>
                   
                </DialogContentText>
                <SoftBox
                            display="flex"
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >
                          
                                <SoftButton
                                    variant="outlined"
                                    style={{ marginRight: "10px" }}
                                    size="small"
                                    color="info"
                                    type='submit'
                                    // onClick={handleBack}
                                >
                                    UPDATE
                                </SoftButton>
                   </SoftBox>
            </DialogContent>
            </form>
            <DialogActions>

                <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditEnquiryModal;
