import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput'
import SoftTextArea from 'components/SoftTextArea/SoftTextArea';
import { toast } from 'react-toastify';
import { makeHttpRequest } from 'utils/httpServices'



function DefaultInfoCard({ color, icon, title, description, setCardData, cardData, index }) {

  const [selectedImageUrl, setSelectedImageUrl] = useState(null)

  const imgHandler = async(e) => {

    try{

    const allowedImageExtensions = [ '.svg'];
    const file = e.target.files[0];
    console.log(file)
    const fileName = file.name;
    const allowedExt = /\.(svg)$/i;

    // Checking for image extension
    if (allowedExt.test(fileName) === false) {
      toast.info(`Invalid file extension. Please select a file with given extension: ${allowedImageExtensions.join(', ')}`);
      return;
      
    }
    const updatedCardData = [...cardData];
    updatedCardData[index] = { ...updatedCardData[index], icon: file || updatedCardData[index].icon };
    setCardData(updatedCardData);
    if (file) {
      
      const formDataObject = new FormData();
            
      formDataObject.append('path', 'pageImages');
      formDataObject.append('media', file);

    const resp = await makeHttpRequest('post', 'uploadAdminMedia', formDataObject);
    console.log(resp)
    const updatedCardData = [...cardData];
    updatedCardData[index] = { ...updatedCardData[index], icon: resp.path};
    setCardData(updatedCardData);

      const reader = new FileReader()

      reader.onload = (event) => {
        setSelectedImageUrl(event.target.result)
      }
      reader.readAsDataURL(file)
    }
  }catch(e)
  {
    toast.error(e);
  }
   
  }
  console.log(cardData[index]?.icon)

  return (
    <Card>
      <SoftBox p={2} mx={3} display="flex" justifyContent="center">
        <SoftBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
          position="relative"
          overflow="hidden"
        >
          {console.log(cardData[index]?.icon)}

          <img style={{height: "100%", width: '100%', objectFit: 'cover'}} src={selectedImageUrl ? selectedImageUrl :
            `${process.env.REACT_APP_IMAGE_URL}pageImages/${cardData[index]?.icon}`} alt='Upload image' />
          <input id="upload-icon" type='file' style={{
            position: "absolute",
            opacity: 0,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }} accept="image/*"
            onChange={imgHandler}
          />
        </SoftBox>

      </SoftBox>
      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <SoftBox mb={0.5}>
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SoftTypography>
          <SoftInput type="text"
            value={cardData[index]?.title || ''} name='title' onChange={(e) => {
              const updatedCardData = [...cardData];
              updatedCardData[index] = { ...updatedCardData[index], title: e.target.value };
              setCardData(updatedCardData);
            }}

          />
        </SoftBox>

        <SoftBox mb={3}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              {description}
            </SoftTypography>
          </SoftBox>
          <SoftTextArea placeholder={"Add some description"}
            rows={3}
            cols={3}
            name='description' value={cardData[index]?.description || ''}

            onChange={(e) => {
              const updatedCardData = [...cardData];
              updatedCardData[index] = { ...updatedCardData[index], description: e.target.value };
              setCardData(updatedCardData);
            }}
          />
        </SoftBox>


      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};



export default DefaultInfoCard;
