import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmModal = ({ isOpen, onClose, onConfirm , messageText}) => {


  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Confirm </DialogTitle>
      <DialogContent>
        <DialogContentText>{messageText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <IconButton edge="start" color="inherit" onClick={onConfirm} aria-label="check">
          <CheckIcon />
        </IconButton>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
