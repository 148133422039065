import React from "react";
import { Chip } from "@mui/material";

const SuiTagInput = ({ tags }) => {
  return (
    <div className="sui-tag-container">
      {tags?.map((tag, index) => (
        <Chip key={index} label={tag} className="sui-tag-chip" />
      ))}
    </div>
  );
};

export default SuiTagInput;
