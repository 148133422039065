// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import UserTableData, { Author } from "./data/UserTableData";

import SoftButton from "components/SoftButton";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { makeHttpRequest } from "utils/httpServices";
import { capitalizeFirstChar } from "utils/common";
import DeleteModal from "components/DeleteModal/DeleteModal";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import { Empty } from "antd";
import { WrapText } from "@mui/icons-material";
import { toast } from "react-toastify";

function RatingAndReview() {
  const { columns } = UserTableData;

  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL + "userProfile/";

  const [rows, setRows] = useState([]);

  const params = useParams();
  const { id } = params;

  const [deleteId, setDeleteId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  //pagination states
  const [totalCount, setTotalCount] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState("all");

  useEffect(() => {
    if (id) {
      getRating();
    }
  }, [id, currentPage, rowsPerPage, type]);

  const getRating = async () => {
    const param = {
      branch_id: id,
      limit: rowsPerPage,
      offset: currentPage * rowsPerPage,
      type: type === "all" ? "" : type,
    };

    try {
      const resp = await makeHttpRequest("get", "getRating", null, param);
      setTotalCount(resp?.count);
      const formattedRows = resp.data.map((item) => ({
        author: (
          <Author
            image={imageBaseUrl + item.user?.profile_image}
            name={item.user?.full_name}
            email={item.email}
          />
        ),
        rating: (
          <SoftTypography variant="caption" color="secondary">
            {"★".repeat(item.rating)}
            {"☆".repeat(5 - item.rating)} ({item.rating})
          </SoftTypography>
        ),
        dentist: (
          <SoftTypography
            className="reviewDescription"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {item.dentist_details?.full_name}
          </SoftTypography>
        ),
        reviews: (
          <SoftTypography
            className="reviewDescription"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {item.reviews.length > 30 ? item.reviews.substring(0, 30) + '...' : item.reviews}
          </SoftTypography>
        ),
        "created by": (
          <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {item.rating_by ? capitalizeFirstChar(item.rating_by) : "User"}
          </SoftTypography>
        ),
        action: (
          <SoftBox>
            <SoftTypography
              component={Link}
              to={
                item.rating_by !== "admin"
                  ? null
                  : `../review-detail/${id}/edit/${item?._id}`
              }
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              marginRight="10px"
              className={item.rating_by !== "admin" ? "disabled" : ""}
            >
              Edit
            </SoftTypography>
            <SoftTypography
              component={Link}
              to={`../review-detail/${id}/view/${item?._id}`}
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              marginRight="10px"
            >
              View
            </SoftTypography>
            <SoftTypography
              href="#"
              component={Link}
              variant="caption"
              color="secondary"
              fontWeight="medium"
              marginRight="10px"
              onClick={() => {
                setDeleteId(item?._id);
                handleShowDeleteModal();
              }}
            >
              Delete
            </SoftTypography>
          </SoftBox>
        ),
      }));
      setRows(formattedRows);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const resp = await makeHttpRequest("delete", `deleteRating/${deleteId}`);
      toast.success("Rating deleted successfully");
      getRating();
      handleCloseDeleteModal();
    } catch (error) {
      //error
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">
                All Rating and Reviews
              </SoftTypography>
              <SoftBox display="flex" alignItems="center" gap="10px">
               
               
                <Select
                  className="filter"
                  labelId="demo-simple-select-label"
                   id="demo-simple-select"

                  autoWidth
                  defaultValue="all"
                   
                  onChange={(event) => {
                    setType(event.target.value);
                  }}
                >
                  <MenuItem value="all" >
                    All Ratings
                  </MenuItem>

                  <MenuItem value="admin">Created by Admin</MenuItem>
                  <MenuItem value="user">Left by Users</MenuItem>
                </Select>
                <SoftButton
                  variant="contained"
                  component={Link}
                  style={{ whiteSpace: "nowrap" }}
                  to={`/review-detail/${id}/add`}
                  color="info"
                >
                  Add New
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {rows?.length > 0 ? (
                <>
                  <Table columns={columns} rows={rows} />
                  <SoftBox className="pgaCntner">
                    <TablePagination
                      component="div"
                      count={totalCount}
                      page={currentPage}
                      onPageChange={handlePageChange}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[10, 15, 20, 25]}
                    />
                  </SoftBox>
                </>
              ) : (
                <Empty />
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default RatingAndReview;
