/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import { FormControlLabel, Switch } from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import DialogProviders from "components/DialogBox/DialogProviders";
import React from "react";
import EditDialogBranches from "components/DialogBox/EditDialogBranches";
import { useState } from "react";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from 'react-toastify'


function DefaultProjectCard({ image, label, title, description, action, authors, id, status}) {
  const [checked, setChecked] = useState(status!==null?status:true);
 
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <SoftAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));


  const statusHandler = async(e)=>{
    // console.log(e.target.checked,'------------------------')
    try {
      const resp = await makeHttpRequest('patch', `changeStatus/${id}`)
      console.log(resp)
      if(resp.status == 'active'){
        setChecked(true)
      }else if(resp.status== 'deactive'){
        setChecked(false)
      }
      
    } catch (error) {
      console.log(error)
      toast.error('Network error.')
    }
   

  }
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <SoftBox className="dentist_profile_cntner" position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            maxWidth: "100%",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
          }}

        />
        <SoftBox className="profile_edit">
          {/* {console.log(id)} */}
          <Link to={`/editClinicBranch/${id}`}>
            <MdModeEdit />
          </Link>

        </SoftBox>


      </SoftBox>
      <SoftBox pt={3} px={0.5}>
        <SoftBox mb={1} display="flex" justifyContent="space-between" alignItems="center">
          <SoftTypography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            textGradient
          >
            {label}
          </SoftTypography>
          <SoftBox className="profile_active">
            <FormControlLabel control={<Switch checked={checked} onChange={statusHandler}/>} />
          </SoftBox>
        </SoftBox>
        <SoftBox mb={1}>
          {action.type === "internal" ? (
            <SoftTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </SoftTypography>
          ) : (
            <SoftTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mb={3} lineHeight={0}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox>
        {/* <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          {action.type === "internal" ? (
            <SoftButton
              component={Link}
              to={action.route}
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </SoftButton>
          ) : (
            <SoftButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </SoftButton>
          )}
          <SoftBox display="flex">{renderAuthors}</SoftBox>
        </SoftBox> */}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultProjectCard;
